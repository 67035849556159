import React from "react";

//Utils
import { MESSAGES } from "../../../../utils/message.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// image
import Close from "../../../../assets/images/modal/modal-cross.jsx";
import Submit from "../../../../assets/images/create-orders/order-submit.svg";
import Cancel from "../../../../assets/images/create-orders/order-cancel.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SubmitModal = (props) => {
  const { handleClose, open, onSubmit, modalType, contactType } = props;

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        disableEscapeKeyDown={true}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={
          contactType === "Upload"
            ? "submitModalWrapper upload"
            : "submitModalWrapper"
        }
      >
        <DialogContent className="submitModalContent" dividers>
          <Box className="submitHeader">
            <img
              src={modalType === "submit" ? Submit : Cancel}
              alt="submit-icon"
            />
          </Box>
          <Box className="submitContent">
            <Typography>
              {modalType === "submit"
                ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.TITLE
                : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_TITLE}
            </Typography>
            <Box className="typoContent">
              <Typography
                className={
                  modalType === "submit" ? `submitHeading` : `notSubmitHeading`
                }
              >
                {modalType === "submit"
                  ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.HEADING
                  : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_HEADING}
              </Typography>
              {modalType === "submit" && contactType === "Upload" ? (
                <Typography className="notSubmitSubHeading">
                  {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.DESCRIPTION}
                </Typography>
              ) : (
                ""
              )}
              {modalType === "cancel" ? (
                <Typography className="notSubmitSubHeading">
                  {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_DESCRIPTION}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box className="submitModalBtns">
            {modalType === "cancel" && (
              <Button onClick={handleClose}>
                {MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.BACK_BUTTON}
              </Button>
            )}
            <Button
              onClick={onSubmit}
              className={
                modalType === "submit" ? `okSubmitBtn` : `cancelSubmitBtn`
              }
            >
              {modalType === "submit"
                ? MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.SUBMIT_BUTTON
                : MESSAGES.ORDERS.CREATE.SUBMIT_MODAL.CANCEL_BUTTON}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default SubmitModal;
