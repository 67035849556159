import React from "react";

const CustomField = () => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5078 3.71875H20.3047C21.7218 3.71875 22.875 4.8719 22.875 6.28906V13.4766C22.875 14.8937 21.7218 16.0469 20.3047 16.0469H18.5078C18.0806 16.0469 17.7344 15.7006 17.7344 15.2734C17.7344 14.8463 18.0806 14.5 18.5078 14.5H20.3047C20.8691 14.5 21.3281 14.041 21.3281 13.4766V6.28906C21.3281 5.72463 20.8691 5.26562 20.3047 5.26562H18.5078C18.0806 5.26562 17.7344 4.91936 17.7344 4.49219C17.7344 4.06502 18.0806 3.71875 18.5078 3.71875Z"
        fill="black"
        stroke="white"
        strokeWidth="0.25"
      />
      <path
        d="M5.51562 8.98438V8.85938H5.39062H4.49219C4.06502 8.85938 3.71875 8.51311 3.71875 8.08594C3.71875 7.65877 4.06502 7.3125 4.49219 7.3125H8.08594C8.51311 7.3125 8.85938 7.65877 8.85938 8.08594C8.85938 8.51311 8.51311 8.85938 8.08594 8.85938H7.1875H7.0625V8.98438V11.6797C7.0625 12.1069 6.71623 12.4531 6.28906 12.4531C5.86189 12.4531 5.51562 12.1069 5.51562 11.6797V8.98438Z"
        fill="black"
        stroke="white"
        strokeWidth="0.25"
      />
      <path
        d="M14.1406 16.1719V16.0469H14.0156H2.69531C1.27815 16.0469 0.125 14.8937 0.125 13.4766V6.28906C0.125 4.8719 1.27815 3.71875 2.69531 3.71875H14.0156H14.1406V3.59375V1.79688V1.67188H14.0156H13.1172C12.69 1.67188 12.3438 1.32561 12.3438 0.898438C12.3438 0.471266 12.69 0.125 13.1172 0.125H16.7109C17.1381 0.125 17.4844 0.471266 17.4844 0.898438C17.4844 1.32561 17.1381 1.67188 16.7109 1.67188H15.8125H15.6875V1.79688V17.9688V18.0938H15.8125H16.7109C17.1381 18.0938 17.4844 18.44 17.4844 18.8672C17.4844 19.2944 17.1381 19.6406 16.7109 19.6406H13.1172C12.69 19.6406 12.3438 19.2944 12.3438 18.8672C12.3438 18.44 12.69 18.0938 13.1172 18.0938H14.0156H14.1406V17.9688V16.1719ZM14.1406 5.39062V5.26562H14.0156H2.69531C2.13088 5.26562 1.67188 5.72463 1.67188 6.28906V13.4766C1.67188 14.041 2.13088 14.5 2.69531 14.5H14.0156H14.1406V14.375V5.39062Z"
        fill="black"
        stroke="white"
        strokeWidth="0.25"
      />
    </svg>
  );
};

export default CustomField;
