import React from "react";

const tags = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4652 9.47279C18.4096 8.56992 18.4096 7.10612 17.4654 6.20312C16.521 5.30025 14.9897 5.30039 14.0455 6.20312C13.1011 7.10599 13.1011 8.56979 14.0455 9.47266C14.9904 10.3739 16.5203 10.3741 17.4652 9.47279ZM14.7557 6.88236C15.3079 6.35446 16.2029 6.3546 16.755 6.88223C17.3071 7.41 17.3071 8.26578 16.755 8.79355C16.203 9.32132 15.3079 9.32132 14.7558 8.79355C14.2046 8.26552 14.2046 7.41053 14.7557 6.88236Z"
        fill="#303030"
      />
      <path
        d="M23.5646 6.70818L19.4568 1.57176C18.9157 0.895325 17.8186 0.385742 16.9085 0.385742H1.92846C0.86584 0.385742 0 1.17943 0 2.1535V13.8468C0 14.8209 0.86584 15.6146 1.92846 15.6146H16.9085C17.8235 15.6146 18.9206 15.105 19.4568 14.4286L23.5646 9.29217C24.1451 8.57064 24.1451 7.43422 23.5646 6.70818ZM22.4922 8.57965L18.3843 13.7161C18.099 14.0768 17.3906 14.4015 16.9036 14.4015H1.92846C1.59885 14.4015 1.32828 14.1535 1.32828 13.8514V2.1535C1.32828 1.85136 1.59885 1.60333 1.92846 1.60333H16.9085C17.3955 1.60333 18.099 1.93253 18.3893 2.28879L22.4971 7.4252C22.7332 7.72734 22.7332 8.27751 22.4922 8.57965Z"
        fill="#303030"
      />
    </svg>
  );
};

export default tags;
