import React from "react";

const contact = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1662_13215)">
        <path
          d="M14.5549 5.78968C14.7491 5.78968 14.9065 5.62248 14.9065 5.41622V1.35477C14.9065 0.736986 14.4334 0.234375 13.8518 0.234375H1.05493C0.473377 0.234375 0.000244141 0.736986 0.000244141 1.35477V18.2354C0.000244141 18.8532 0.473377 19.3558 1.05493 19.3558H13.8518C14.4334 19.3558 14.9065 18.8532 14.9065 18.2354V15.484C14.9065 15.2778 14.7491 15.1106 14.5549 15.1106C14.3608 15.1106 14.2034 15.2778 14.2034 15.484V18.2354C14.2034 18.4414 14.0457 18.6089 13.8518 18.6089H1.05493C0.86108 18.6089 0.703369 18.4414 0.703369 18.2354V1.35477C0.703369 1.14884 0.86108 0.981307 1.05493 0.981307H13.8518C14.0457 0.981307 14.2034 1.14884 14.2034 1.35477V5.41622C14.2034 5.62248 14.3608 5.78968 14.5549 5.78968Z"
          fill="#303030"
        />
        <path
          d="M7.45337 2.25195C5.66993 2.25195 4.21899 3.79329 4.21899 5.68784C4.21899 6.67069 4.60958 7.55835 5.23438 8.18517C5.24608 8.19847 5.25853 8.21105 5.27213 8.22252C5.84768 8.78193 6.61363 9.12369 7.45333 9.12369C8.29304 9.12369 9.05899 8.7819 9.63453 8.22252C9.64814 8.21105 9.66058 8.19843 9.67229 8.18517C10.2972 7.55835 10.6877 6.67069 10.6877 5.68784C10.6877 3.79329 9.23681 2.25195 7.45337 2.25195ZM7.45337 8.3768C6.8793 8.3768 6.3495 8.17244 5.92439 7.829C6.23338 7.24695 6.80997 6.88293 7.45337 6.88293C8.09676 6.88293 8.67336 7.24695 8.98235 7.829C8.55721 8.17244 8.02744 8.3768 7.45337 8.3768ZM6.89087 5.53846V5.2957C6.89087 4.96623 7.14322 4.69816 7.45337 4.69816C7.76352 4.69816 8.01587 4.96623 8.01587 5.2957V5.53846C8.01587 5.86793 7.76352 6.136 7.45337 6.136C7.14322 6.136 6.89087 5.86793 6.89087 5.53846ZM9.49032 7.28191C9.23104 6.87341 8.87171 6.5557 8.45451 6.35956C8.6201 6.13223 8.71899 5.84746 8.71899 5.53846V5.2957C8.71899 4.55433 8.15126 3.95122 7.45337 3.95122C6.75548 3.95122 6.18774 4.55433 6.18774 5.2957V5.53846C6.18774 5.84746 6.28664 6.13223 6.45222 6.35956C6.03503 6.5557 5.67569 6.87341 5.41642 7.28191C5.10602 6.8355 4.92212 6.28416 4.92212 5.68784C4.92212 4.20514 6.05763 2.99889 7.45337 2.99889C8.84911 2.99889 9.98462 4.20514 9.98462 5.68784C9.98462 6.28416 9.80072 6.8355 9.49032 7.28191Z"
          fill="#303030"
        />
        <path
          d="M9.35181 16.5918H7.45337C7.25924 16.5918 7.10181 16.759 7.10181 16.9653C7.10181 17.1715 7.25924 17.3387 7.45337 17.3387H9.35181C9.54594 17.3387 9.70337 17.1715 9.70337 16.9653C9.70337 16.759 9.54594 16.5918 9.35181 16.5918Z"
          fill="#303030"
        />
        <path
          d="M9.35181 14.5H4.35962C4.16549 14.5 4.00806 14.6672 4.00806 14.8735C4.00806 15.0797 4.16549 15.2469 4.35962 15.2469H9.35181C9.54594 15.2469 9.70337 15.0797 9.70337 14.8735C9.70337 14.6672 9.54594 14.5 9.35181 14.5Z"
          fill="#303030"
        />
        <path
          d="M9.35181 12.4102H4.35962C4.16549 12.4102 4.00806 12.5774 4.00806 12.7836C4.00806 12.9899 4.16549 13.1571 4.35962 13.1571H9.35181C9.54594 13.1571 9.70337 12.9899 9.70337 12.7836C9.70337 12.5774 9.54594 12.4102 9.35181 12.4102Z"
          fill="#303030"
        />
        <path
          d="M3.10666 10.4278C3.0413 10.3583 2.9506 10.3184 2.85779 10.3184C2.76529 10.3184 2.67459 10.3583 2.60923 10.4278C2.54384 10.4972 2.50623 10.5936 2.50623 10.6918C2.50623 10.79 2.54381 10.8864 2.60923 10.9559C2.67498 11.0253 2.76529 11.0653 2.85779 11.0653C2.9506 11.0653 3.04095 11.0253 3.10666 10.9559C3.17205 10.8864 3.2097 10.79 3.2097 10.6918C3.2097 10.5936 3.17208 10.4972 3.10666 10.4278Z"
          fill="#303030"
        />
        <path
          d="M3.10666 12.5196C3.04095 12.4501 2.9506 12.4102 2.85779 12.4102C2.76529 12.4102 2.67498 12.4501 2.60923 12.5196C2.54384 12.589 2.50623 12.6854 2.50623 12.7836C2.50623 12.8818 2.54381 12.9782 2.60923 13.0477C2.67498 13.1171 2.76529 13.1571 2.85779 13.1571C2.9506 13.1571 3.04095 13.1171 3.10666 13.0477C3.17205 12.9782 3.2097 12.8818 3.2097 12.7836C3.2097 12.6854 3.17208 12.589 3.10666 12.5196Z"
          fill="#303030"
        />
        <path
          d="M3.10666 14.6094C3.04095 14.54 2.9506 14.5 2.85779 14.5C2.76529 14.5 2.67498 14.54 2.60923 14.6094C2.54384 14.6789 2.50623 14.7752 2.50623 14.8735C2.50623 14.9717 2.54381 15.068 2.60923 15.1375C2.67459 15.207 2.76529 15.2469 2.85779 15.2469C2.9506 15.2469 3.0413 15.207 3.10666 15.1375C3.17205 15.068 3.2097 14.9717 3.2097 14.8735C3.2097 14.7752 3.17208 14.6789 3.10666 14.6094Z"
          fill="#303030"
        />
        <path
          d="M9.35181 10.3184H4.35962C4.16549 10.3184 4.00806 10.4856 4.00806 10.6918C4.00806 10.8981 4.16549 11.0653 4.35962 11.0653H9.35181C9.54594 11.0653 9.70337 10.8981 9.70337 10.6918C9.70337 10.4856 9.54594 10.3184 9.35181 10.3184Z"
          fill="#303030"
        />
        <path
          d="M17.2618 5.10144C16.9203 4.89196 16.5224 4.83632 16.1414 4.94473C15.7604 5.05319 15.442 5.31275 15.2448 5.67557L10.8207 13.8158C10.7965 13.8604 10.7811 13.9099 10.7757 13.9611L10.4643 16.9243C10.4489 17.0717 10.517 17.215 10.6379 17.2892C10.6925 17.3226 10.7532 17.3392 10.8137 17.3392C10.8872 17.3392 10.9605 17.3148 11.0222 17.2665L13.2822 15.4983C13.3212 15.4678 13.3539 15.429 13.3781 15.3843L17.8022 7.24416C18.2094 6.49514 17.9669 5.53391 17.2618 5.10144ZM11.2523 16.1588L11.4149 14.6115L12.4324 15.2355L11.2523 16.1588ZM12.945 14.6874L11.6054 13.8658L15.1556 7.3335L16.4953 8.15512L12.945 14.6874ZM17.1933 6.87066L16.8468 7.50828L15.5072 6.68665L15.8537 6.04904C15.957 5.85898 16.1238 5.72304 16.3233 5.66623C16.5229 5.60939 16.7314 5.6386 16.9102 5.74832C17.0891 5.85805 17.2171 6.03522 17.2706 6.2472C17.3241 6.45918 17.2966 6.6806 17.1933 6.87066Z"
          fill="#303030"
        />
      </g>
      <defs>
        <clipPath id="clip0_1662_13215">
          <rect
            width="18"
            height="19.1215"
            fill="white"
            transform="translate(0 0.234375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default contact;
