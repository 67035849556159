import React from "react";

const CustomAddIcon = () => {
  return (
    <>
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="🔍-Product-Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="ic_fluent_apps_add_in_24_filled"
            fill="#212121"
            fillRule="nonzero"
          >
            <path
              d="M10.5,3 C11.8807119,3 13,4.11928813 13,5.5 L13,11 L18.5,11 C19.8807119,11 21,12.1192881 21,13.5 L21,18.5 C21,19.8807119 19.8807119,21 18.5,21 L5.5,21 C4.11928813,21 3,19.8807119 3,18.5 L3,5.5 C3,4.11928813 4.11928813,3 5.5,3 L10.5,3 Z M11,13 L5,13 L5,18.5 C5,18.7761424 5.22385763,19 5.5,19 L11,19 L11,13 Z M18.5,13 L13,13 L13,19 L18.5,19 C18.7761424,19 19,18.7761424 19,18.5 L19,13.5 C19,13.2238576 18.7761424,13 18.5,13 Z M10.5,5 L5.5,5 C5.22385763,5 5,5.22385763 5,5.5 L5,11 L11,11 L11,5.5 C11,5.22385763 10.7761424,5 10.5,5 Z M17.8833789,2.00672773 L18,2 C18.5128358,2 18.9355072,2.38604019 18.9932723,2.88337887 L19,3 L19,5 L21,5 C21.5128358,5 21.9355072,5.38604019 21.9932723,5.88337887 L22,6 C22,6.51283584 21.6139598,6.93550716 21.1166211,6.99327227 L21,7 L19,7 L19,9 C19,9.51283584 18.6139598,9.93550716 18.1166211,9.99327227 L18,10 C17.4871642,10 17.0644928,9.61395981 17.0067277,9.11662113 L17,9 L17,7 L15,7 C14.4871642,7 14.0644928,6.61395981 14.0067277,6.11662113 L14,6 C14,5.48716416 14.3860402,5.06449284 14.8833789,5.00672773 L15,5 L17,5 L17,3 C17,2.48716416 17.3860402,2.06449284 17.8833789,2.00672773 L18,2 L17.8833789,2.00672773 Z"
              id="🎨-Color"
            ></path>
          </g>
        </g>
      </svg>
    </>
  );
};

export default CustomAddIcon;
