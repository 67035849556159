import React from "react";
// MUI Components
import { Box, CircularProgress } from "@mui/material";
// Styles
import "./styles.scss";

const Loader = () => {
  return (
    <Box className="loaderWrapper">
      <CircularProgress />
    </Box>
  );
};

export default Loader;
