import React from "react";

//Utils
import { MESSAGES } from "../../../../utils/message";
import { numberWithComma } from "../../../../utils/helperFunctions.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// image
import Close from "../../../../assets/images/modal/modal-cross.jsx";
import Success from "../../../../assets/images/modal/import-success.svg";
import Cancel from "../../../../assets/images/modal/import-cancel.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ActionModal = (props) => {
  const { handleClose, onSubmit, open, modalType, exportedContacts } = props;

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={`actionModalWrapper ${
          modalType === "cancel"
            ? "cancelModalHeight action-modal"
            : "action-modal"
        }`}
      >
        {/* {modalType !== "success" && ( */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
          }}
        >
          <Close />
        </IconButton>
        {/* )} */}

        <DialogContent className="actionModalContent" dividers>
          <img
            src={modalType === "success" ? Success : Cancel}
            alt="succcess"
          />
          <Box className="content">
            <Typography>
              {modalType === "success"
                ? MESSAGES.CONTACTS.IMPORT.ACTIONS.SUCCESS_TITLE
                : MESSAGES.CONTACTS.IMPORT.ACTIONS.CANCEL_TITLE}
            </Typography>
            <Typography>
              {modalType === "success"
                ? `${numberWithComma(exportedContacts)}  ${
                    MESSAGES.CONTACTS.IMPORT.ACTIONS.SUCCESS_MESSAGE
                  }`
                : MESSAGES.CONTACTS.IMPORT.ACTIONS.CANCEL_MESSAGE}
            </Typography>
            <Typography>
              {modalType === "success"
                ? MESSAGES.CONTACTS.IMPORT.ACTIONS.SUCCESS_DESCRIPTION
                : MESSAGES.CONTACTS.IMPORT.ACTIONS.CANCEL_DESCRIPTION}
            </Typography>
          </Box>
          {modalType === "success" ? (
            <Button onClick={onSubmit}>
              {MESSAGES.CONTACTS.IMPORT.ACTIONS.DONE_TEXT}
            </Button>
          ) : (
            <Box className="actionModalBtnsWrapper">
              <Button onClick={handleClose}>
                {MESSAGES.CONTACTS.IMPORT.ACTIONS.BACK_TEXT}
              </Button>
              <Button onClick={onSubmit}>
                {MESSAGES.CONTACTS.IMPORT.ACTIONS.CANCEL_TEXT}
              </Button>
            </Box>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ActionModal;
