import React from 'react';

import { Box, Typography } from '@mui/material';
/**
 * Renders a message indicating that the Template Builder is only available on tablet and web view.
 *
 * @returns {JSX.Element} The JSX code that renders the message.
 */
export default function DisableTemplateBuilder() {
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Typography>Template Builder is available on tablet and web view only.</Typography>
    </Box>
  );
}