import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { isAdmin } from "../../../../hooks/permissions";

// Actions
import {
  fetchOneWebhook,
  fetchWebhookLogs,
} from "../../../../redux/actions/webhooks-actions";
import { success } from "../../../../redux/actions/snackbar-actions";
import WebhookLogTable from "../WebhookLogTable";

// Utils
import { copyToClipboard } from "../../../../utils/clipboard";
import { dateFormat } from "../../../../utils/date-format";
import { MESSAGES } from "../../../../utils/message";

// Components
import DeleteWebhookModal from "../DeleteWebhookModal";
import ActionModal from "../ActionModal";
import WebhookLog from "../WebhookLog";

// MUI Components
import { Box, Button, Container, Grid, Typography } from "@mui/material";

// MUI Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// images
import Edit from "../../../../assets/images/webhook-details/details-edit.svg";
import CopyIcon from "../../../../assets/images/webhooks/copyIcon.svg";
import Disable from "../../../../assets/images/webhooks/disable.svg";
import Enable from "../../../../assets/images/webhooks/checkmark.svg";
import Trash from "../../../../assets/images/contact-details/trash.svg";
import Hide from "../../../../assets/images/webhooks/hide.svg";
import Show from "../../../../assets/images/webhooks/show.svg";
import Back from "../../../../assets/images/webhooks/back.svg";

// styles
import "./styles.scss";

const WebhookDetails = () => {
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const [webhookLogs, setWebhookLogs] = useState([]);
  const [deleteWebhookModal, setDeleteWebhookModal] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [selectedWebhookLog, setSelectedWebhookLog] = useState(null);
  const [displayLogPage, setDisplayLogPage] = useState(false);
  const [actionWebhookModal, setActionWebhookModal] = useState(false);
  const [showSecrectKey, setShowSecrectKey] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const webhookId = params.id;

  const onEdit = () => {
    navigate(`/settings/webhooks/update/${webhookId}`);
  };

  const copyKey = (key) => {
    copyToClipboard(key);
    dispatch(success(MESSAGES.SETTINGS.API_KEYS.COPY_KEY));
  };

  const getOneWebhook = async () => {
    const webbhook = await dispatch(fetchOneWebhook(webhookId));
    if (webbhook.status === 200) {
      setSelectedWebhook(webbhook.data.data);
    }
  };

  const getWebhookLog = async () => {
    const logs = await dispatch(fetchWebhookLogs(webhookId));
    if (logs.status === 200) {
      setWebhookLogs(logs.data.data.rows);
    }
  };

  useEffect(() => {
    getOneWebhook();
    getWebhookLog();
  }, [actionWebhookModal]);

  return (
    <>
      {!displayLogPage ? (
        <Box className="webhooksDetailsWrapper">
          <Container maxWidth="xxl">
            <Grid container className="webhookDetailsGrid">
              <Grid item lg={1} md={2} sm={2} xs={2}>
                <Button
                  className="webhookBackBtn"
                  onClick={() => navigate("/settings/webhooks")}
                >
                  <img src={Back} alt="icon" />
                  <Typography>
                    {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.BACK_BUTTON}
                  </Typography>
                </Button>
              </Grid>
              <Grid paddingLeft={"30px"} item lg={8} md={7} sm={9} xs={9}>
                <Box className="detailsHeading">
                  <Typography>
                    {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.TITLE}
                  </Typography>

                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.URL_LABEL}
                    </Typography>
                    <Typography className="max-words">
                      {(selectedWebhook?.callbackUrl &&
                        selectedWebhook?.callbackUrl) ||
                        " -"}
                    </Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.DESCRIPTION_LABEL}
                    </Typography>
                    <Typography>{selectedWebhook?.topic || " -"}</Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.STATUS_LABEL}
                    </Typography>
                    <Typography>{selectedWebhook?.status || " -"}</Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.SECRET_KEY_LABEL}
                    </Typography>
                    <Typography className="secretKey">
                      <input
                        type={showSecrectKey ? "text" : "password"}
                        value={selectedWebhook?.secret}
                        readOnly
                      />
                      {showSecrectKey ? (
                        <img
                          height={15}
                          width={20}
                          src={Hide}
                          alt="hide"
                          onClick={() => setShowSecrectKey(false)}
                        />
                      ) : (
                        <img
                          height={15}
                          width={20}
                          src={Show}
                          alt="show"
                          onClick={() => setShowSecrectKey(true)}
                        />
                      )}
                      <img
                        height={17}
                        width={17}
                        onClick={() => copyKey(selectedWebhook?.secret)}
                        src={CopyIcon}
                        alt="icon"
                      />
                    </Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.CUSTOM_HEADERS_LABEL}
                    </Typography>
                    <Typography>
                      {selectedWebhook?.customHeaders || " -"}
                    </Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.CREATED_DATE_LABEL}
                    </Typography>
                    <Typography>
                      {dateFormat(selectedWebhook?.createdAt) || " -"}
                    </Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.LAST_USED_DATE_LABEL}
                    </Typography>
                    <Typography>
                      {dateFormat(selectedWebhook?.lastAccessAt) || " -"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  marginTop: { md: "0px", sm: "20px", xs: "20px" },
                  display: { md: "block", sm: "flex", xs: "flex" },
                  justifyContent: { sm: "flex-end", xs: "flex-end" },
                }}
              >
                <Box className="webhookDetailsActionBtn">
                  <Button
                    className="editBtn"
                    onClick={() => setActionWebhookModal(true)}
                  >
                    <img
                      src={
                        selectedWebhook?.status === "Enabled" ? Disable : Enable
                      }
                      alt="disable"
                    />
                    <Typography>
                      {selectedWebhook?.status === "Enabled"
                        ? MESSAGES.SETTINGS.WEBHOOKS.DETAILS.DISABLE_LABEL
                        : MESSAGES.SETTINGS.WEBHOOKS.DETAILS.ENABLE_LABEL}
                    </Typography>
                  </Button>
                  <Button className="editBtn" onClick={onEdit}>
                    <img src={Edit} alt="trash" />
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.EDIT_BTN_TEXT}
                    </Typography>
                  </Button>
                  <Button
                    className="delBtn"
                    onClick={() => setDeleteWebhookModal(true)}
                  >
                    <img src={Trash} alt="trash" />
                    <Typography>
                      {MESSAGES.SETTINGS.WEBHOOKS.DETAILS.DELETE_BTN_TEXT}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <hr className="divider" />
            <Grid container>
              <Grid item lg={1} md={2} sm={4} xs={2}></Grid>
              <Grid paddingLeft={"30px"} item lg={11} md={10} sm={8} xs={8}>
                <WebhookLogTable
                  rows={webhookLogs}
                  setSelectedWebhookLog={setSelectedWebhookLog}
                  setDisplayLogPage={setDisplayLogPage}
                />
              </Grid>
            </Grid>
            <ActionModal
              openModal={actionWebhookModal}
              handleCloseModal={() => setActionWebhookModal(false)}
              selectedWebhook={selectedWebhook}
            />
            <DeleteWebhookModal
              openModal={deleteWebhookModal}
              handleCloseModal={() => setDeleteWebhookModal(false)}
              selectedWebhook={webhookId}
            />
          </Container>
        </Box>
      ) : (
        <WebhookLog
          selectedWebhookLog={selectedWebhookLog}
          setDisplayLogPage={setDisplayLogPage}
        />
      )}
    </>
  );
};

export default WebhookDetails;
