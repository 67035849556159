import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// actions
import { fetchTemplateCategories } from "../../../redux/actions/admin-templates-actions";
import { getAllTemplates } from "../../../redux/actions/template-builder";

//utils
import { MESSAGES } from "../../../utils/message";
import {
  DEFAULT_PAGINATION,
  DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE,
} from "../../../utils/constants";

// Components
import Table from "./Table";
import CreateModal from "./CreateCategoryModal";
import EditCustomFieldModal from "./EditCategoryModal";
import DeleteModal from "./DeleteCategoryModal";
import AssociateTemplatesModal from "./AssociateTemplatesModal";

// MUI Components
import {
  Box,
  Typography,
  Button,
  Grid,
  Input,
  InputAdornment,
  IconButton,
  Container,
} from "@mui/material";

// icons
import create from "../../../assets/images/user-table/invite.svg";
import Search from "../../../assets/images/contacts/contact-search.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// styles
import "./styles.scss";

const TemplateCategory = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [createModal, setcreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [associateTemplateModal, setAssociateTemplateModal] = useState(false);
  const [selectedTemplateCategory, setSelectedTemplateCategory] =
    useState(null);

  const dispatch = useDispatch();

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const getAdminCategories = () => {
    let payload = {
      pagination,
      search: search?.length ? search : null,
    };
    dispatch(fetchTemplateCategories(payload));
  };

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getAdminCategories();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    setIsMounted(true);
    getAdminCategories();
    dispatch(
      getAllTemplates(
        DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE.page,
        500
      )
    );
  }, []);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getAdminCategories();
    }
  }, [search]);

  useEffect(() => {
    if (isMounted) {
      getAdminCategories();
    }
  }, [pagination.page]);

  useEffect(() => {
    if (isMounted) {
      setPagination(DEFAULT_PAGINATION);
      getAdminCategories();
    }
  }, [currentMode]);

  return (
    <>
      <Container maxWidth="xxl">
        <Box className="tempCategoryWrapper">
          <Grid container rowSpacing={1}>
            <Grid
              item
              lg={6}
              md={9}
              sm={12}
              xs={12}
              className="searchBtnWrapper"
            >
              <Box className="searchWrapper">
                <Input
                  className={`searchInput ${
                    searchApplied ? "searchApplied" : ""
                  }`}
                  type="text"
                  value={search}
                  onChange={(e) => {
                    e.target.value == " "
                      ? setSearch(e.target.value.trim())
                      : setSearch(e.target.value);
                  }}
                  onKeyDown={searchKeyDown}
                  placeholder={`Search by template category`}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      className="searchInputAdornment"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        className="searchIcon"
                        onClick={handleSearch}
                      >
                        <img src={Search} alt="search" />
                      </IconButton>
                      {searchApplied && (
                        <HighlightOffIcon
                          onClick={() => {
                            setSearch("");
                          }}
                          className="clearSerach"
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={3} sm={12} xs={12}>
              <Box className="createBtn">
                <Button
                  sx={{ "&:hover": { backgroundColor: "#ed5c2f" } }}
                  onClick={() => setcreateModal(true)}
                >
                  <img src={create} alt="addSign" />
                  <Typography>
                    {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE_BTN_TEXT}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Table
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
            setAssociateTemplateModal={setAssociateTemplateModal}
            setSelectedTemplateCategory={setSelectedTemplateCategory}
            updatePagination={setPagination}
            pagination={pagination}
          />
        </Box>
      </Container>
      <CreateModal
        openModal={createModal}
        handleCloseModal={() => setcreateModal(false)}
        pagination={pagination}
      />
      <DeleteModal
        openModal={deleteModal}
        handleCloseModal={() => setDeleteModal(false)}
        selectedTemplateCategory={selectedTemplateCategory}
        pagination={pagination}
      />
      <EditCustomFieldModal
        openModal={editModal}
        handleCloseModal={() => setEditModal(false)}
        selectedTemplateCategory={selectedTemplateCategory}
        pagination={pagination}
      />
      <AssociateTemplatesModal
        openModal={associateTemplateModal}
        handleCloseModal={() => setAssociateTemplateModal(false)}
        selectedTemplateCategory={selectedTemplateCategory}
        pagination={pagination}
      />
    </>
  );
};

export default TemplateCategory;
