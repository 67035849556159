import React from "react";

// utils
import { MESSAGES } from "../../../utils/message";

// MUI Components
import { Container, Typography, Box, Grid } from "@mui/material";

// styles
import "./styles.scss";

// Card Components
import BalanceCard from "./BalanceCard";
import PaymentCard from "./PaymentCard";
import TransactionListing from "./TransactionListing";

const Wallet = () => {
  return (
    <Box className="walletMain">
      <Container maxWidth="xxl">
        <Box className="walletWrapper">
          <Typography>{MESSAGES.ACCOUNT.WALLET.TITLE}</Typography>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <BalanceCard />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <div className="paymentCard">
                <PaymentCard />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="tableOuter">
                <TransactionListing />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Wallet;
