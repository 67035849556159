import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../../hooks/permissions";

// Actions
import { fetchApiKeys } from "../../../redux/actions/apiKeys-actions";

//Utils
import { MESSAGES } from "../../../utils/message";
import { DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE } from "../../../utils/constants";

// Components
import Table from "./ApiKeysListing";
import CreateApiKeys from "./CreateApiKey";
import DeleteApiModal from "./DeleteApiModal";
import RotateApiModal from "./RotateApiModal";

// MUI Components
import { Box, Typography, Button, Grid } from "@mui/material";

// images
import Create from "../../../assets/images/templates/add-icon.svg";

// styles
import "./styles.scss";

const index = () => {
  const [pagination, setPagination] = useState(
    DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE
  );
  const [createApiModal, setCreateApiModal] = useState(false);
  const [deleteApiModal, setDeleteApiModal] = useState(false);
  const [rotateApiModal, setRotateApiModal] = useState(false);
  const [selectedApi, setSelectedApi] = useState(null);

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApiKeys(pagination));
  }, [pagination.page, currentMode]);

  return (
    <>
      <Box className="apiKeysWrapper">
        <Box className="apiKeysHeader">
          <Box className="webhookHeading">
            <Typography>{MESSAGES.SETTINGS.API_KEYS.TITLE}</Typography>
          </Box>
          <Box className="actionsBtnWrapper">
            <Button
              className="templateCreateBtn"
              onClick={() => setCreateApiModal(true)}
            >
              <img src={Create} alt="actions" />
              <Typography>
                {MESSAGES.SETTINGS.API_KEYS.CREATE_BUTTON_TEXT}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              setDeleteApiModal={setDeleteApiModal}
              setRotateApiModal={setRotateApiModal}
              setSelectedApi={setSelectedApi}
              updatePagination={setPagination}
            />
          </Grid>
        </Grid>
      </Box>
      <CreateApiKeys
        openModal={createApiModal}
        handleCloseModal={() => setCreateApiModal(false)}
      />
      <DeleteApiModal
        openModal={deleteApiModal}
        handleCloseModal={() => setDeleteApiModal(false)}
        selectedApi={selectedApi}
      />
      <RotateApiModal
        openModal={rotateApiModal}
        handleCloseModal={() => setRotateApiModal(false)}
        selectedApi={selectedApi}
      />
    </>
  );
};

export default index;
