import {
    SET_CUSTOM_FIELDS_DATA, SET_CUSTOM_FIELDS_LOADER, LOGOUT
} from "../actions/action-types"

const initialState = {
    customFields: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalCustomFieldsInDB: null,
    loading: false,
    labels: [],
    applyFilters: false,
    filterSize: null,
    filters: {}
};

const customFieldsReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CUSTOM_FIELDS_DATA:
            return {
                ...state, customFields: payload.rows, rowsCount: payload.count, totalCustomFieldsInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_CUSTOM_FIELDS_LOADER:
            return { ...state, loading: payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { customFieldsReducers };