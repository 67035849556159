import React, { useState, useEffect } from "react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../../hooks/permissions";

// Actions
import { fetchWebhooks } from "../../../redux/actions/webhooks-actions";

//Utils
import { MESSAGES } from "../../../utils/message";
import { DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE } from "../../../utils/constants";

// Components
import WebhookTable from "./WebhookListing";
import DeleteWebhookModal from "./DeleteWebhookModal";
import ActionModal from "./ActionModal";

// MUI Components
import { Box, Typography, Button, Grid } from "@mui/material";

// images
import Create from "../../../assets/images/templates/add-icon.svg";

// styles
import "./styles.scss";

const index = () => {
  const [pagination, setPagination] = useState(
    DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE
  );
  const [deleteWebhookModal, setDeleteWebhookModal] = useState(false);
  const [actionWebhookModal, setActionWebhookModal] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState(null);

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchWebhooks(pagination));
  }, [pagination.page, currentMode]);

  return (
    <>
      <Box className="webhooksWrapper">
        <Box className="webhookHeader">
          <Box className="webhookHeading">
            <Typography>{MESSAGES.SETTINGS.WEBHOOKS.TITLE}</Typography>
          </Box>
          <Box className="actionsBtnWrapper">
            <Button
              className="templateCreateBtn"
              onClick={() => navigate("/settings/webhooks/create")}
            >
              <img src={Create} alt="actions" />
              <Typography>
                {MESSAGES.SETTINGS.WEBHOOKS.CREATE_BUTTON_TEXT}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <WebhookTable
              setDeleteWebhookModal={setDeleteWebhookModal}
              setActionWebhookModal={setActionWebhookModal}
              setSelectedWebhook={setSelectedWebhook}
              updatePagination={setPagination}
            />
          </Grid>
        </Grid>
      </Box>
      <ActionModal
        openModal={actionWebhookModal}
        handleCloseModal={() => setActionWebhookModal(false)}
        selectedWebhook={selectedWebhook}
      />
      <DeleteWebhookModal
        openModal={deleteWebhookModal}
        handleCloseModal={() => setDeleteWebhookModal(false)}
        selectedWebhook={selectedWebhook?.id}
      />
    </>
  );
};

export default index;
