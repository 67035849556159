import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";

//Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import { updateTemplateCategory } from "../../../../redux/actions/admin-templates-actions";

// Utils
import { MESSAGES } from "../../../../utils/message";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// image
import crossicon from "../../../../assets/images/modal/crossIcon.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditCategoryModal = (props) => {
  const { openModal, handleCloseModal, selectedTemplateCategory, pagination } =
    props;

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE.CATEGORY_NAME_REQUIRED
      )
      .min(1, MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE.CATEGORY_NAME_REQUIRED)
      .max(255, MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE.CATEGORY_NAME_LIMIT),
    description: yup
      .string()
      .trim()
      .nullable()
      .max(255, MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE.CATEGORY_DESC_LIMIT),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
  });

  const updateExistingCategory = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const payload = {
        title: formik.values.name,
        description: formik.values.description,
      };
      const response = await dispatch(
        updateTemplateCategory(selectedTemplateCategory.id, payload, pagination)
      );
      dispatch(success(response.data.message));
      onClose();
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTemplateCategory) {
      formik.values.name = selectedTemplateCategory?.title;
      formik.values.description = selectedTemplateCategory?.description;
    }
  }, [selectedTemplateCategory]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="editTemplateCategoryWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={crossicon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="editTemplateCategoryContent" dividers>
          <Box className="editTemplateCategoryHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.EDIT.TITLE}
            </Typography>
          </Box>
          <form className="updateFormWrapper" onSubmit={updateExistingCategory}>
            <Box className="editTemplateCategoryFormFields">
              <InputLabel htmlFor="Custom Field" className="inputLabel">
                {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.EDIT.NAME_LABEL}
              </InputLabel>
              <TextField
                placeholder={
                  MESSAGES.ADMIN_DASHBOARD.CATEGORIES.EDIT.NAME_PLACEHOLDER
                }
                fullWidth
                autoComplete="off"
                name="name"
                className={
                  formik.errors.name && formik.touched.name
                    ? "inputField invalid"
                    : "inputField"
                }
                onChange={formik.handleChange}
                value={formik.values.name?.trimStart()}
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                sx={{
                  "& input::placeholder": {
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  },
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              />
              {formik.errors.name ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.name}
                </Typography>
              ) : null}

              <InputLabel htmlFor="Name" className="inputLabel">
                {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE.DESCRIPTION_LABEL}
              </InputLabel>
              <TextField
                placeholder={
                  MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CREATE
                    .DESCRIPTION_PLACEHOLDER
                }
                fullWidth
                autoComplete="off"
                name="description"
                className={
                  formik.errors.description && formik.touched.description
                    ? "inputField invalid"
                    : "inputField"
                }
                sx={{
                  "& input::placeholder": {
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  },
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
                onChange={formik.handleChange}
                value={formik.values?.description?.trimStart()}
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
              />
              {formik.errors.description ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.description}
                </Typography>
              ) : null}
            </Box>

            <Box className="editNameModalBtns">
              <Button className="btnCancel" onClick={onClose}>
                {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CANCEL_BUTTON}
              </Button>
              <Button className="btnSave" type="submit">
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ADMIN_DASHBOARD.CATEGORIES.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default EditCategoryModal;
