import { useSelector } from "react-redux";

const isAdmin = () => {
    return useSelector(
        (state) => state.userReducers.user.isOrgAdmin
    ) || false;
};

const isSystemAdmin = () => {
    return useSelector(
        (state) => state.userReducers.user.isSystemAdmin
    ) || false;
}

export { isAdmin, isSystemAdmin };