import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import {
  getTeamMembersData,
  updateRole,
} from "../../../../redux/actions/user-actions";

// Utils
import { USER_ROLES } from "../../../../utils/constants";
import { MESSAGES } from "../../../../utils/message";

// Components
import MultiSelect from "../../../Contacts/AdvanceFilters/MultiSelect";

// Mui components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// images
import cross from "../../../../assets/images/modal/crossIcon.svg";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditUserModal = (props) => {
  const { selectedUser, openModal, handleCloseModal } = props;
  const [selectedRole, setSelectedRole] = useState([]);

  const dispatch = useDispatch();

  const onClose = () => {
    setSelectedRole([]);
    handleCloseModal();
  };

  const onUpdateRole = async () => {
    const payload = {
      id: selectedUser?.id,
      role: USER_ROLES.find((roles) => {
        return roles.title === selectedRole.toString();
      }).value,
    };
    const response = await dispatch(updateRole(payload));
    if (response.status === 200) {
      dispatch(success(response.data.message));
      dispatch(getTeamMembersData());
      onClose();
    } else {
      dispatch(failure(response.data.message));
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setSelectedRole(
        USER_ROLES?.find(
          (role) => role?.value === selectedUser?.role?.slug
        )?.title?.split() || []
      );
    }
  }, [openModal]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="editUserModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2, paddingBottom: "0px" }}
        id="customized-dialog-title"
      >
        {MESSAGES.ACCOUNT.USERS.EDIT_USER_ROLE.TITLE}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        className="crossIcon"
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={cross} alt="close" />
      </IconButton>
      <DialogContent className="filterModalContent" sx={{ paddingTop: "0px" }}>
        <Box className="inputWrapper">
          <Typography sx={{ marginBottom: "10px" }}>
            {MESSAGES.ACCOUNT.USERS.EDIT_USER_ROLE.ROLE_LABEL}
          </Typography>
          <MultiSelect
            options={USER_ROLES}
            selectedValue={selectedRole}
            setSelectedValue={setSelectedRole}
            simpleSelect={true}
            placeholder={"Select Role"}
            component="editUser"
          />
        </Box>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button onClick={onClose}>
          {MESSAGES.ACCOUNT.USERS.EDIT_USER_ROLE.CANCEL_BUTTON}
        </Button>
        <Button onClick={onUpdateRole}>
          {MESSAGES.ACCOUNT.USERS.EDIT_USER_ROLE.SUBMIT_BUTTON}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default EditUserModal;
