import request from "../../utils/request";

import { SET_GENERAL_SETTINGS } from "./action-types"

const fetchGeneralSetting = () => async (dispatch) => {
    try {
        const setting = await request.get("/general-settings");
        dispatch({ type: SET_GENERAL_SETTINGS, payload: setting.data.data });
        return setting;
    } catch (error) {
        return error;
    }
}

const updateGeneralSetting = (payload) => async () => {
    try {
        const update = await request.post("/general-settings", payload);
        return update;
    } catch (error) {
        return error;
    }
}

export { fetchGeneralSetting, updateGeneralSetting }