import React, { useState, useEffect } from "react";

// Hooks
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import {
  verifyUserToken,
  ForgotUserPassword,
} from "../../redux/actions/auth-actions";
import { success, failure } from "../../redux/actions/snackbar-actions";

// Default messages
import { MESSAGES } from "../../utils/message";

// Utils
import { getResetToken, getUserEmail } from "../../utils/auth";

// MUI Components
import {
  Box,
  Grid,
  InputLabel,
  Typography,
  Button,
  CircularProgress,
  Container,
} from "@mui/material";

// React Verify Input
import VerificationInput from "react-verification-input";

// Icons from Material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// images
import OpenLetter from "../../assets/images/login/secondary.svg";

// Styles
// import "./styles.scss";
import "../../index.scss";

const Verification = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [resendingCode, setResendingCode] = useState(false);
  let token = getResetToken();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const verifyCode = async (resetCode = code, resetToken = token) => {
    try {
      const payload = {
        code: resetCode,
        token: resetToken,
      };
      setLoading(true);
      const request = await dispatch(verifyUserToken(payload));
      setLoading(false);
      if (request.message === "success") {
        navigate("/update-password");
      } else {
        dispatch(
          failure(request?.response?.data?.message || MESSAGES.GENERAL_ERROR)
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const reSendCode = async () => {
    try {
      setResendingCode(true);
      const payload = {
        email: getUserEmail(),
      };
      const request = await dispatch(ForgotUserPassword(payload));
      setResendingCode(false);
      if (request.message === "success") {
        setResendingCode(false);
        dispatch(
          success(
            request?.response?.data?.message ||
              MESSAGES.AUTH.RESET_CODE + getUserEmail()
          )
        );
      } else {
        dispatch(
          failure(request?.response?.data?.message || MESSAGES.GENERAL_ERROR)
        );
      }
    } catch (error) {
      setResendingCode(false);
    }
  };

  useEffect(() => {
    if (searchParams.size > 0) {
      if (searchParams.get("code")) {
        let paramsCode = searchParams.get("code");
        let paramsToken = searchParams.get("token");
        setCode(searchParams.get("code"));
        verifyCode(paramsCode, paramsToken);
      }
    }
  }, []);

  return (
    <Box className="layoutBg" sx={{ height: "100%", minHeight: "102vh" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box className="imageWrapper">
              <img src={OpenLetter} alt="OpenLetter" />
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className="formBG">
            <Box className="formWrapper updateForm">
              <Box className="forgotFormHeadings">
                <Box
                  className="backArrow"
                  onClick={() => {
                    navigate("/reset-password");
                  }}
                >
                  <ArrowBackIcon />
                </Box>
                <Typography variant="h4">
                  {MESSAGES.VERIFICATION.TITLE}
                </Typography>
                <Typography variant="h6">
                  {MESSAGES.VERIFICATION.HEADING}
                </Typography>
              </Box>
              <form className="updateFormWrapper" onSubmit={verifyCode}>
                <Box className="inputWrapper updateFormInput">
                  <InputLabel className="formLabel">
                    {MESSAGES.VERIFICATION.INPUT_LABEL_CODE}
                  </InputLabel>
                  <VerificationInput
                    classNames={{
                      container: "verfiyContainer",
                      character: "verfiyCharacter",
                      characterInactive: "character--inactive",
                      characterSelected: "character--selected",
                    }}
                    placeholder="0"
                    length={8}
                    value={code}
                    autoFocus={true}
                    onChange={(event) => setCode(event)}
                    onComplete={(event) => verifyCode(event)}
                  />
                </Box>
                <Button
                  type="submit"
                  disabled={code.length < 8}
                  onClick={(event) => {
                    event.preventDefault();
                    verifyCode();
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    MESSAGES.VERIFICATION.SUBMIT_BUTTON
                  )}
                </Button>
              </form>
              <Button
                className="resendCode"
                disabled={resendingCode}
                onClick={reSendCode}
              >
                {MESSAGES.VERIFICATION.RESEND_BUTTON}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Verification;
