import React, { useState, useEffect } from "react";

//utils
import { statesData } from "../../../../../../utils/constants";


//third party
import { ComposableMap, Geographies, Geography, Annotation } from "react-simple-maps";
import { scaleQuantile } from "d3-scale";

//MUI
import { Tooltip } from "@mui/material";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const MapChart = ({ info }) => {
  const [data, setData] = useState([]);

  useEffect(() => {

    const { chartData } = info.geoChart;

    const dataArray = Object.keys(chartData).map(key => ({
      abbreviation: key,
      name: chartData[key].name,
      count: chartData[key].count == null ? 0 : chartData[key].count
    }));

    setData(dataArray);
  }, []);

  const colorScale = scaleQuantile()
  .domain(data.map(d => d.count))
  .range([
    "#ffc294",
    "#fac399",
    "#f4c49f",
    "#efc4a4",
    "#eac5a9",
    "#e4c6af",
    "#dfc7b4",
    "#dac7b9",
    "#ffc294"
  ]);


  return (
    <ComposableMap projection="geoAlbersUsa"  >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => {
            const cur = data.find(s => s.name === geo.properties.name);
            return (
              <Tooltip title={cur?.count ? `${cur.count} Scanned` : 0} key={geo.id}>
                <Geography
                  key={geo.id}
                  geography={geo}
                  fill={cur && cur.count !== 0 ? colorScale(cur.count) : "#f3f3f3"}
                  _hover={{ fill: "#ffc294" }}
                  stroke="#ffffff"
                  storkeWidht={5}
                  style={{
                    hover: {
                      fill: "#ededed",
                    },
                  }}
                />
              </Tooltip>
            );
          })
        }
      </Geographies>
      {statesData.map((state) => (
        <Annotation
          subject={[state.longitude, state.latitude]}
          dx={0}
          dy={0}
        >
          <text x="" textAnchor="end" alignmentBaseline="middle" fill="#f58220" style={{ fontWeight: 600 }}>
            {state.nick}
          </text>
        </Annotation>
      ))}
    </ComposableMap>
  );
};

export default MapChart;
