import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Input } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// Styles
import "./styles.scss";
// images
import CloseIcon from "../../../../assets/images/modal/modal-cross.jsx";
import { dynmicInputChange } from "../../../../redux/actions/template-builder";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/**
 * CustomFieldNameModel Component
 * A modal for duplicating a template.
 *
 * @param {boolean} open - Boolean indicating whether the modal is open.
 * @param {Function} handleClose - Function to handle the closing of the modal.
 * @returns {JSX.Element} The duplicate template modal component.
 *
 */
const CustomFieldNameModel = ({ open, handleClose }) => {
  const [field, setField] = useState("");
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  // Handle the duplication of the template
  const handleEditName = async () => {
    if (!/^[a-zA-Z]+$/.test(field)) {
      setIsError(true);
    } else {
      dispatch(dynmicInputChange(field));
      handleCloseDialog();
    }
  };

  // Close the modal
  const handleCloseDialog = () => handleClose(false);

  return (
    <BootstrapDialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="duplicateModalWrapper"
    >
      <DialogTitle
        className="duplicateModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        Add Custom Field
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="duplicateModalContent">
        <Typography>Field Name</Typography>
        <Input
          className={isError ? "error" : ""}
          type="text"
          value={field}
          onChange={(e) => setField(e.target.value)}
          placeholder=""
        />
        {isError && (
          <Typography className="error-field">
            Field name cannot contain space, special character and numbers
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button autoFocus onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button autoFocus onClick={handleEditName}>
          Save
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

// Prop types
CustomFieldNameModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

CustomFieldNameModel.defaultProps = {
  open: false,
};

export default CustomFieldNameModel;
