import React, { useEffect, useState } from "react";

// Hooks
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { displayTooltip } from '../../../../hooks/displayTooltip';

// Actions
import { failure } from "../../../../redux/actions/snackbar-actions";

//Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "../../../../utils/constants";
import { MESSAGES } from "../../../../utils/message";

// Components
import Loader from "../../../General/Loader";

// MUI Table
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Stack,
} from "@mui/material";

// Icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import "./styles.scss";
import { stringLength } from "../../../../utils/helperFunctions";

const BatchTable = ({ pagination, updatePagination }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const pageSizes = DEFAULT_PAGE_SIZES;

  const rows = useSelector((state) => state.adminBatchesReducers.batches);

  const rowsCount = useSelector(
    (state) => state.adminBatchesReducers.rowsCount
  );
  const to = useSelector((state) => state.adminBatchesReducers.to);
  const from = useSelector((state) => state.adminBatchesReducers.from);
  const lastPage = useSelector(
    (state) => state.adminBatchesReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminBatchesReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.adminBatchesReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const isLoading = useSelector((state) => state.adminBatchesReducers.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

const isTooltip = displayTooltip();

  const columns = [
    {
      field: "batchId",
      headerName: "Batch ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.batchId || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "wooId",
      headerName: "Woo Order ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Link to={params.row.wooUrl} className="link" target="_blank">
            <Typography>{params.row.wooId || " -"}</Typography>
          </Link>
        </Box>
      ),
    },
    {
      field: "ordersCount",
      headerName: "No. of Orders",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Link
            className="link"
            to={`/admin/orders/batch/${params.row.batchId}`}
          >
            <Typography>{params.row.ordersCount || " -"}</Typography>
          </Link>
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.quantity || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 150,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.revenue || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.createdAt || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.productType}`, 20) ? (
          <Tooltip
            title={params.row.productType}
            disableHoverListener={isTooltip}
          >
            <Box className="productValWrapper">
              <Typography>{params.row.productType || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="productValWrapper">
            <Typography>{params.row.productType || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "accuzipStatus",
      headerName: "Accuzip Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.accuzipStatus}`, 20) ? (
          <Tooltip
            title={params.row.accuzipStatus}
            disableHoverListener={isTooltip}
          >
            <Box className="valWrapper">
              <Typography>{params.row.accuzipStatus || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="valWrapper">
            <Typography>{params.row.accuzipStatus || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "batchStatus",
      headerName: "Batch Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.batchStatus}`, 20) ? (
          <Tooltip
            title={params.row.batchStatus}
            disableHoverListener={isTooltip}
          >
            <Box className="valWrapper">
              <Typography>{params.row.batchStatus || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="valWrapper">
            <Typography>{params.row.batchStatus || " -"}</Typography>
          </Box>
        ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="adminUserTable">
        <div
          style={{
            marginTop: "14px",
            width: "100%",
          }}
          className="batchTableWrapper"
        >
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={rowsCount}
            pagination={pagination}
            paginationMode="server"
            hideFooterSelectedRowCount
            hideFooterPagination
            rowSelection={false}
            getRowId={(row) => row.key}
            disableColumnMenu={true}
            className="ordersTableGrid"
            components={{
              NoRowsOverlay: () => (
                <Stack className="noRowsTextContacts">
                  {MESSAGES.ADMIN_DASHBOARD.BATCHES.NO_RESULT_TEXT}
                </Stack>
              ),
              loadingOverlay: Loader,
            }}
          />
          <Box className="paginationWrapper">
            <Pagination
              count={lastPage}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              page={currentPage}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIosNewIcon,
                    next: ArrowForwardIosIcon,
                  }}
                  {...item}
                />
              )}
            />
            <Select
              className={
                perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
              }
              value={perPage}
            >
              {pageSizes.map((pageSize) => {
                return (
                  <MenuItem
                    key={pageSize}
                    value={pageSize}
                    onClick={() => {
                      updatePagination({
                        page: 0,
                        pageSize: pageSize,
                      });
                    }}
                  >
                    {pageSize}
                  </MenuItem>
                );
              })}
            </Select>

            <Typography>
              Showing {rowsCount ? from : 0} to{" "}
              {rowsCount < to ? rowsCount : to} of {rowsCount} results
            </Typography>
          </Box>
        </div>
      </div>
    </>
  );
};

export default BatchTable;
