import * as React from "react";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

// utils
import { MESSAGES } from "../../../utils/message";

// Icons
import crossicon from "../../../assets/images/modal/crossIcon.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ConfirmModal = (props) => {
  const { open, handleClose, modalHead, title, handleAction, loading, status } =
    props;

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="confirmModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <img src={crossicon} alt="Trash" />
      </IconButton>
      <DialogContent dividers className="confirmModalContent">
        <Typography variant="h4">
          {modalHead === "Enable"
            ? ` ${MESSAGES.INTEGRATIONS.MODAL.ENABLE_LABEL}` + ` ${title}`
            : `${MESSAGES.INTEGRATIONS.MODAL.DISABLE_LABEL}` + ` ${title}`}
        </Typography>
        <Typography>
          {modalHead === "Enable"
            ? `${MESSAGES.INTEGRATIONS.MODAL.ENABLE_HEADING}` +
              ` ${title} Integration?`
            : `${MESSAGES.INTEGRATIONS.MODAL.DISABLE_HEADING}` +
              ` ${title} Integration?`}
        </Typography>
      </DialogContent>
      <DialogActions className="confirmModalActions">
        <Button autoFocus onClick={handleClose}>
          No
        </Button>
        <Button autoFocus onClick={()=>handleAction(title, status)}>
          {loading ? <CircularProgress /> : "Yes"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ConfirmModal;
