import React, { useState, useEffect } from "react";

// Hooks
import { useSelector } from "react-redux";
import { isAdmin } from "../../../../hooks/permissions";

// Utils
import { MESSAGES } from "../../../../utils/message";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Tooltip,
  Stack,
} from "@mui/material";

// Icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Images
import Edit from "../../../../assets/images/accounts/editnew.png";
import Delete from "../../../../assets/images/settings/delete.svg";

// Styles
import "./styles.scss";
import Loader from "../../../General/Loader";

const TagsListing = (props) => {
  const {
    setDeleteTagModal,
    setEditTagModal,
    setSelectedTag,
    updatePagination,
  } = props;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const rows = useSelector((state) => state.tagsReducers.tags);
  const rowsCount = useSelector((state) => state.tagsReducers.rowsCount);
  const to = useSelector((state) => state.tagsReducers.to);
  const from = useSelector((state) => state.tagsReducers.from);
  const lastPage = useSelector((state) => state.tagsReducers.lastPage || 1);
  const currentPage = useSelector(
    (state) => state.tagsReducers.currentPage || 1
  );
  const isLoading = useSelector((state) => state.tagsReducers.loading);

  const sortedRows =
    [...rows].sort((a, b) => a?.title?.localeCompare(b?.title)) || [];

  const handleDelete = (row) => {
    setSelectedTag(row);
    setDeleteTagModal(true);
  };
  const handleEdit = (row) => {
    setSelectedTag(row);
    setTimeout(() => {
      setEditTagModal(true);
    }, 10);
  };

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: 10,
    });
  };

  const columns = [
    {
      field: "title",
      headerName: "Tag Name",
      width: 300,
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Typography>{params.row.title}</Typography>
        </Box>
      ),
    },
    {
      field: "totalContacts",
      headerName: "Total Contacts",
      width: 220,
      height: 500,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Typography>{params.row.totalContacts}</Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      sortable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => (
        <Box className="tagsTableActions">
          <Tooltip title="Edit">
            <img
              src={Edit}
              height={20}
              alt="Edit"
              onClick={() => handleEdit(params.row)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <img
              src={Delete}
              alt="delete"
              onClick={() => handleDelete(params.row)}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
      }}
      className="tagsTableWrapper"
    >
      <DataGrid
        rows={sortedRows}
        columns={columns}
        loading={isLoading}
        rowCount={rowsCount}
        paginationMode="server"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="tagsTableGrid"
        components={{
          NoRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              {MESSAGES.SETTINGS.TAGS.NO_TAGS}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="tagsPaginationWrapper">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Typography>
          Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to} of{" "}
          {rowsCount} results
        </Typography>
      </Box>
    </div>
  );
};

export default TagsListing;
