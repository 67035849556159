import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { fetchTags } from "../../../redux/actions/tags-actions";

//Utils
import { MESSAGES } from "../../../utils/message";
import { DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE } from "../../../utils/constants";

// Components
import Table from "./TagsListing";
import CreateTag from "./CreateTag";
import DeleteTagModal from "./DeleteTagModal";
import EditTagModal from "./EditTag";

// MUI Components
import { Box, Typography, Button, Grid } from "@mui/material";

// images
import Create from "../../../assets/images/templates/add-icon.svg";

// styles
import "./styles.scss";

const Tags = () => {
  const [pagination, setPagination] = useState(
    DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE
  );
  const [createTagModal, setCreateTagModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [editTagModal, setEditTagModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState({ title: null, id: null });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTags(pagination));
  }, [pagination.page]);

  return (
    <>
      <Box className="apiKeysWrapper">
        <Box className="apiKeysHeader">
          <Box className="webhookHeading">
            <Typography>{MESSAGES.SETTINGS.TAGS.TITLE}</Typography>
          </Box>
          <Box className="actionsBtnWrapper">
            <Button
              className="templateCreateBtn"
              onClick={() => setCreateTagModal(true)}
            >
              <img src={Create} alt="actions" />
              <Typography>
                {MESSAGES.SETTINGS.TAGS.CREATE_BUTTON_TEXT}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              setDeleteTagModal={setDeleteTagModal}
              setEditTagModal={setEditTagModal}
              setSelectedTag={(value) => {
                setSelectedTag(value);
              }}
              updatePagination={setPagination}
              setSelectedTagTitle={(val) => setSelectedTagTitle(val)}
            />
          </Grid>
        </Grid>
      </Box>
      <CreateTag
        openModal={createTagModal}
        handleCloseModal={() => setCreateTagModal(false)}
      />
      <DeleteTagModal
        openModal={deleteTagModal}
        handleCloseModal={() => setDeleteTagModal(false)}
        selectedTag={selectedTag}
      />
      <EditTagModal
        openModal={editTagModal}
        handleCloseModal={() => setEditTagModal(false)}
        selectedTag={selectedTag}
      />
    </>
  );
};

export default Tags;
