import React, { useEffect, useState } from "react";

//hooks
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//actions
import {
  clearTemplateFields,
  getAllProducts,
  searchAndAdvanceChange,
  selectPostCard,
  selectProduct,
} from "../../../redux/actions/template-builder";
import { CLEAR_TEMPLATE } from "../../../redux/actions/action-types.js";

//utils
import { PRODUCT_LEARN_URL, sortOrderForTemplates } from "../../../utils/constants.js";
import { removeItem } from "../../../utils/local-storage.js";
import { MESSAGES } from "../../../utils/message.js";
import { envelopeTypes } from "../../../utils/template-builder.js";

// Mui Components
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
} from "@mui/material";
// styles
import "./styles.scss";
// Images
import Postcard from "../../../assets/images/templates/postcard.svg";
import PersonalLetter from "../../../assets/images/templates/personal-letter.svg";
import ProfessionalLetter from "../../../assets/images/templates/professional-letter.svg";
import RealPennedLetter from "../../../assets/images/templates/real-penned-letters.svg";
import TriFoldSelfMailers from "../../../assets/images/templates/tri-fold-self-mailers.svg";
import BiFoldSelfMailers from "../../../assets/images/templates/bi-fold-self-mailers.svg";
import SizeImage from "../../../assets/images/templates/size-image.jsx";
import SizeImageMid from "../../../assets/images/templates/size-image-mid.jsx";
import SizeImageLarge from "../../../assets/images/templates/size-image-lg.jsx";
import MultiSelect from "../../General/MultiSelect/index.jsx";

const CreateTemplate = () => {
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [envelopeType, setEnvelopeType] = useState([]);
  const title = useSelector((state) => state.templateReducer.title);
  const product = useSelector((state) => state.templateReducer.product);
  const products = useSelector((state) => state.templateReducer.products);
  const templateType = useSelector(
    (state) => state.templateReducer.templateType
  );

  const sortedProducts = products.sort((a, b) => {
    const indexA = sortOrderForTemplates.indexOf(a.productType);
    const indexB = sortOrderForTemplates.indexOf(b.productType);
    return indexA - indexB;
  });

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(clearTemplateFields());
    removeItem("formData");
    dispatch({ type: CLEAR_TEMPLATE });
  }, []);

  const handleNext = () => {
    const trimedTitle = title.trim();
    if (
      !trimedTitle ||
      trimedTitle.length > 50 ||
      !templateType ||
      !product ||
      (product &&
        product.productType === "Postcards" &&
        !product.selectedSize) ||
      (product &&
        product.productType === "Professional Letters" &&
        !envelopeType.length)
    ) {
      setIsError(true);
    } else {
      let envelope = "";
      if (product.productType === "Professional Letters") {
        envelope = envelopeTypes.find(
          (envelope) => envelope.title === envelopeType[0]
        ).type;
      }
      dispatch(searchAndAdvanceChange("title", trimedTitle));
      dispatch(searchAndAdvanceChange("envelopeType", envelope));
      navigate(
        templateType === "json" ? "/template-builder" : "/template-html"
      );
    }
  };

  const handleTemplateType = (type) => {
    dispatch(searchAndAdvanceChange("templateType", type));
  };
  const Images = {
    Postcards: Postcard,
    "Professional Letters": ProfessionalLetter,
    "Personal Letters": PersonalLetter,
    "Real Penned Letter": RealPennedLetter,
    "Tri-Fold Self-Mailers": TriFoldSelfMailers,
    "Bi-Fold Self-Mailers": BiFoldSelfMailers,
  };

  useEffect(() => {
    if (products.length) {
      dispatch(selectProduct(products[0]));
    }
  }, [products]);

  useEffect(() => {
    if (product && product?.productType === "Professional Letters") {
      if (envelopeType[0] === "Non-Windowed Envelope") {
        dispatch(
          selectProduct(sortedProducts.find((item) => item.windowed === false))
        );
      }
    }
  }, [envelopeType]);

  return (
    <Box className="createTemplateMainBox">
      <Container maxWidth="xxl">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="createTemplateHeader">
              <Typography>Create New Template</Typography>
              <Box className="templateInputWrapper">
                <Typography>Template Name*</Typography>
                <input
                  type="text"
                  className={!title && isError ? "error" : ""}
                  value={title}
                  onChange={(e) =>
                   { 
                    dispatch(searchAndAdvanceChange("title", e.target.value))
                   }
                  }
                  placeholder="Template Name"
                />
                {!title.trim() && isError && (
                  <Typography className="error-field">
                    *{MESSAGES.TEMPLATE.NAME_REQUIRED}
                  </Typography>
                )}
                {title.length > 50 && isError && (
                  <Typography className="error-field">
                    *{MESSAGES.TEMPLATE.NAME_LESS_50}
                  </Typography>
                )}
              </Box>
            </Box>
            {/* <Divider />
            <Box className="tempTypeRadioWrapper">
              <Box className="tempTypeRadioHeading">
                <Typography>Template Type*</Typography>
              </Box>
              <Box className="tempTypeRadioBox">
                <Box className="tempRadio">
                  <input
                    type="radio"
                    checked={templateType === "json"}
                    onChange={() => handleTemplateType("json")}
                    id="age1"
                    name="age"
                    value="json"
                  />
                  <label htmlFor="age1">Use the Template Builder</label>
                </Box>
                <Box className="tempRadio">
                  <input
                    type="radio"
                    id="age2"
                    checked={templateType === "html"}
                    onChange={() => handleTemplateType("html")}
                    name="age"
                    value="html"
                  />
                  <label htmlFor="age2">Upload my HTML</label>
                </Box>
              </Box>
              {isError && !templateType && (
                <Typography className="error-field">
                  *{MESSAGES.TEMPLATE.TYPE_REQUIRED}
                </Typography>
              )}
            </Box> */}
            <Divider />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="productTypeWrapper">
              <Box className="productHeading">
                <Typography>Product Type*</Typography>
                <NavLink to={PRODUCT_LEARN_URL}
                  target="_blank"><Typography>Learn More</Typography></NavLink>
              </Box>
              <Box className="productsWrapper">
                {sortedProducts.filter((prod) => prod.windowed !== false).map((prod, index) => {
                  return (
                    <Box
                      className={`productCard ${
                        prod.productType === (product && product.productType)
                          ? "active"
                          : ""
                      } ${isError && !product ? "error" : ""} `}
                      key={index}
                      onClick={() => dispatch(selectProduct(prod))}
                    >
                      <img src={Images[prod.productType]} alt="Postcard" />
                      <Typography>{prod.productType}</Typography>
                    </Box>
                  );
                })}
              </Box>
              {isError && !product && (
                <Typography className="error-field">
                  *{MESSAGES.TEMPLATE.PRODUCT_TYPE_REQUIRED}
                </Typography>
              )}
            </Box>
            <Divider />
          </Grid>
        </Grid>
        {product && product.productType === "Professional Letters" && (
          <Grid container my={2} className="mb-5">
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className="createTemplateHeader">
                <Box className="templateInputWrapper">
                  <Typography>Envelope Type*</Typography>
                  <MultiSelect
                    className={isError && !envelopeType.length ? "error" : ""}
                    options={envelopeTypes}
                    selectedValue={envelopeType}
                    setSelectedValue={setEnvelopeType}
                    productType={false}
                    multiple={false}
                    placeHolderText="Envelope Type"
                    component="envelopType"
                  />
                  {isError && !envelopeType.length && (
                    <Typography mt={1} className="error-field">
                      *{MESSAGES.TEMPLATE.ENVELOPE_TYPE_REQUIRED}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
        {product && product?.productType === "Postcards" && (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="postCardSizeWrapper">
                <Box className="postCardHeading">
                  <Typography>Postcard Size*</Typography>
                </Box>
                <Box className="postCardWrapper">
                  {product?.size
                    .sort((a, b) => a.id.localeCompare(b.id))
                    .map((type, index) => {
                      const size = type.size.split("x");
                      return (
                        <Box
                          onClick={() =>
                            dispatch(
                              selectPostCard(
                                {
                                  ...type,
                                  size: product.size,
                                  selectedSize: type.size,
                                },
                                "Postcards"
                              )
                            )
                          }
                          className={
                            index === 0
                              ? `postCard postCard-small ${
                                  product.selectedSize === type.size
                                    ? "active"
                                    : ""
                                }`
                              : index === 1
                              ? `postCard postCard-mid ${
                                  product.selectedSize === type.size
                                    ? "active"
                                    : ""
                                }`
                              : index === 2
                              ? `postCard postCard-large ${
                                  product.selectedSize === type.size
                                    ? "active"
                                    : ""
                                }`
                              : `postCard ${
                                  product.selectedSize === type.size
                                    ? "active"
                                    : ""
                                }`
                          }
                          key={index}
                        >
                          <Typography>
                            {size[0]}” x {size[1]}"
                          </Typography>
                          {index === 0 ? (
                            <SizeImage />
                          ) : index === 1 ? (
                            <SizeImageMid />
                          ) : (
                            <SizeImageLarge />
                          )}
                        </Box>
                      );
                    })}
                </Box>
                {isError &&
                  product &&
                  product.productType === "Postcards" &&
                  !product.selectedSize && (
                    <Typography className="error-field">
                      *{MESSAGES.TEMPLATE.POSTCARD_SIZE_REQUIRED}
                    </Typography>
                  )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
      <Box className="footerBtns">
        <Box className="createTemplateBtns">
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button onClick={handleNext}>Next</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateTemplate;
