import React from "react";

// Hooks
import { useNavigate } from "react-router-dom";

//Utils
import { MESSAGES } from "../../../utils/message";

// MUI Components
import { Box, Button, Container, Typography } from "@mui/material";

// Assets
import NoContact from "../../../assets/images/contacts/no-contact.svg";

// Icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ImportIcon from "../../../assets/images/contacts/import-light.svg";

// styles
import "./styles.scss";

const NoContactsScreen = (props) => {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noContactWrapper">
        <img src={NoContact} alt="no-contacts" />
        <Typography>{MESSAGES.CONTACTS.NO_CONTACTS_SCREEN.TITLE}</Typography>
        <Box className="noContactBtns">
          <Button
            onClick={() => {
              navigate("/contacts/create");
            }}
          >
            <AddCircleOutlineIcon />
            {MESSAGES.CONTACTS.NO_CONTACTS_SCREEN.CREATE_CONTACT_BUTTON}
          </Button>
          <Button
            onClick={() => {
              navigate("/contacts/import");
            }}
            className="import"
          >
            <img src={ImportIcon} alt="actions" />
            {MESSAGES.CONTACTS.NO_CONTACTS_SCREEN.IMPORT_BTN_TEXT}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NoContactsScreen;
