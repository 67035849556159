import React, { useEffect, useState } from "react";
import { keyframes } from "@emotion/react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getAllPlans,
  fetchInvoices,
  fetchActiveSubscription,
} from "../../redux/actions/plans-actions";
import { failure } from "../../redux/actions/snackbar-actions";
import { getUserData } from "../../redux/actions/auth-actions";

// Utils
import { parseJson, transformData } from "../../utils/helperFunctions";
import { MESSAGES } from "../../utils/message";

// Components
import StarterPlan from "../Plan/StarterPlan";
import PlanCard from "../Plan/PlanCard";
import ProductTable from "../Plan/ProductTable";
import UpgradeModal from "./UpgradeModal";
import DowngradeModal from "./DowngradeModal";

// MUI Components
import {
  Box,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";

// Icons
import Starter from "../../assets/images/plans/starter.svg";
import Growth from "../../assets/images/plans/growth.svg";
import Professional from "../../assets/images/plans/professional.svg";
import Enterprise from "../../assets/images/plans/enterprise.svg";

// styles
import "./styles.scss";

const planImages = {
  0: Starter,
  1: Growth,
  2: Professional,
  3: Enterprise,
};
const index = () => {
  const [plans, setPlans] = useState({ year: [], month: [] });
  const [activePlan, setActivePlan] = useState("monthly");
  const [pricing, setPricing] = useState({ year: [], month: [] });
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [downgradeModal, setDowngradeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [planRenewsAt, setPlansRenewsAt] = useState("");
  const [planLoader, setPlanLoader] = useState(false);

  const dispatch = useDispatch();

  const invoice = useSelector((state) => state.stripeReducers.invoices) || [];
  const [selectedPlan, setSelectedPlan] = useState({ type: null });

  const currentPlan = useSelector(
    (state) => state.userReducers.user?.subscribedPlan
  );

  const currentPlanStripId = useSelector(
    (state) => state.userReducers.user?.subscribedPlan?.stripeId
  );

  const currentPlanType = Object.keys(plans).find((key) =>
    plans[key].some((plan) => plan?.stripeId === currentPlanStripId)
  );

  const currentPlanDetails = Object.keys(plans)
    .map((el) =>
      plans[el].find((plan) => plan?.stripeId === currentPlanStripId)
    )
    .filter((plan) => plan !== undefined);

  useEffect(() => {
    if (selectedPlan && selectedPlan.type == "UPGRADE") {
      setUpgradeModal(true);
    } else if (selectedPlan && selectedPlan.type == "DOWNGRADE") {
      setDowngradeModal(true);
    }
  }, [selectedPlan]);

  const sortPlans = (array) => {
    const orderArr = ["stater", "growth", "professional", "enterprise"];

    return array.sort((a, b) => {
      const indexA = orderArr.indexOf(a.slug);
      const indexB = orderArr.indexOf(b.slug);

      return indexA - indexB;
    });
  };

  const getInvoices = async (payload = null) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchInvoices(payload));
    } catch (error) {
      dispatch(failure(error?.response?.data?.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  const getSubscribtionDetails = async () => {
    try {
      const response = await fetchActiveSubscription();
      if (response.status === 200) {
        setPlansRenewsAt(response.data.data.planRenewsAt);
      }
    } catch (error) {
      return error;
    }
  };

  const getAllPlansFromServer = async () => {
    try {
      setPlanLoader(true);
      const response = await getAllPlans();
      if (response.status === 200) {
        const plansByInterval = response.data.data.rows.reduce(
          (accumulator, item) => {
            const interval = item.meta.stripeMeta.interval;

            if (!accumulator[interval]) {
              accumulator[interval] = [];
            }
            const isYearly = interval === "year" ? true : false;
            const amount = (+item.amount).toFixed();
            const perMonth = isYearly ? (amount / 12).toFixed(2) : amount;
            accumulator[interval].push({
              ...item,
              pricing: [
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_1) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_2) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_3) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_4) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_5) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_6) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_7) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_8) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_9) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_10) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_11) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_12) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_13) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_14) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_15) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_16) },
                { ...parseJson(item.meta.stripeMeta.metadata.pricing_17) },
              ],
              features: {
                users: {
                  ...parseJson(item.meta.stripeMeta.metadata.features_users),
                },
                contacts: {
                  ...parseJson(item.meta.stripeMeta.metadata.features_contacts),
                },
                customFields: {
                  ...parseJson(
                    item.meta.stripeMeta.metadata.features_custom_fields
                  ),
                },
                templates: {
                  ...parseJson(
                    item.meta.stripeMeta.metadata
                      .features_stored_custom_templates
                  ),
                },
                webhooks: {
                  ...parseJson(item.meta.stripeMeta.metadata.features_webhooks),
                },
                tags: {
                  ...parseJson(item.meta.stripeMeta.metadata.features_tags),
                },
                mailingLimit: {
                  ...parseJson(
                    item.meta.stripeMeta.metadata
                      .features_monthly_mailings_limit
                  ),
                },
                mailTracking: { limit: 1, show: true },
                userRolesPermissions: {
                  ...parseJson(
                    item.meta.stripeMeta.metadata.general_user_roles_permissions
                  ),
                },
                ayalyticsDashboard: { limit: 1, show: true },
                scheduleMailing: {
                  ...parseJson(
                    item.meta.stripeMeta.metadata.general_scheduled_mail
                  ),
                },
              },
              plan: item.amount === "0.00" ? "Free" : `$${perMonth}/mo`,
              billAnnual: isYearly ? `$${amount} billed annually` : "",
              billMonthly: "",
              isActive: false,
              isYearly: isYearly,
            });

            return accumulator;
          },
          {}
        );

        const _plans = {
          year: sortPlans(plansByInterval["year"]),
          month: sortPlans(plansByInterval["month"]),
        };

        _plans.year = _plans.year.map((item, index) => {
            return {
              ...item,
              billMonthly: _plans?.month[index]?.plan || '',
            };
        });
        const pricing = {
          year: createProductData(_plans.year),
          month: createProductData(_plans.month),
        };
        setPricing(pricing);
        _plans.month.push(enterprisePlan(false));
        _plans.year.push(enterprisePlan(true));
        setPlans(_plans);
      }
    } catch (error) {
      return error;
    } finally {
      setPlanLoader(false);
    }
  };

  const enterprisePlan = (isYearly) => {
    const interval = {
      month: false,
      year: true,
    };
    const data = {
      image: planImages[3],
      name: `Enterprise ${isYearly ? "(Annually)" : "(Monthly)"}`,
      features: {
        users: { limit: -1, show: true },
        contacts: { limit: -1, show: true },
        templates: { limit: -1, show: true },
        customFields: { limit: -1, show: true },
        tags: { limit: -1, show: true },
        webhooks: { limit: -1, show: true },
        mailingLimit: { limit: -1, show: true },
        mailTracking: { limit: 1, show: true },
        userRolesPermissions: { limit: 1, show: true },
        ayalyticsDashboard: { limit: 1, show: true },
        scheduleMailing: { limit: 1, show: true },
      },
      plan: "Talk to Sales",
      billAnnual: "",
      billMonthly: "",
      isActive: false,
      isYearly: isYearly,
      stripeId: null,
    };
    if (
      currentPlan.slug === "enterprise" &&
      interval[currentPlan.meta.stripeMeta.interval] === isYearly
    ) {
      const planData = transformData(currentPlan.meta.stripeMeta.metadata);
      data.name = currentPlan?.name;
      data.features = {
        ...data.features,
        users: { ...planData.features_users },
        contacts: { ...planData.features_contacts },
        templates: { ...planData.features_stored_custom_templates },
        customFields: { ...planData.features_custom_fields },
        tags: { ...planData.features_tags },
        webhooks: { ...planData.features_webhooks },
        mailingLimit: { ...planData.features_monthly_mailings_limit },
        userRolesPermissions: { ...planData.general_user_roles_permissions },
        scheduleMailing: { ...planData.general_scheduled_mail },
      };
      data.stripeId = currentPlan.stripeId;
      data.isActive = true;
    }
    return data;
  };

  const createProductData = (data) => {
    const output = {};
    data.forEach((plan) => {
      plan.pricing.forEach((item) => {
        const title = item.title;
        const limit = item.limit;

        if (!output[title]) {
          output[title] = {};
        }

        output[title][plan.slug] = limit;
      });
    });
    // Convert output object to the desired format
    const finalOutput = Object.keys(output).map((title) => ({
      name: title,
      starter: output[title]["starter"] || 0,
      growth: output[title]["growth"] || 0,
      professional: output[title]["professional"] || 0,
      enterprise: output[title]["enterprise"] || "Contact Sales",
    }));
    return finalOutput;
  };

  const handleOpen = (stripeId) => {
    let selected = (activePlan === "yearly" ? plans.year : plans.month).find(
      (el) => el.stripeId === stripeId
    );
    setUpgradeModal(true);
    setSelectedPlan({ ...selected, type: "UPGRADE" });
  };

  const handleClose = () => {
    setUpgradeModal(false);
    setSelectedPlan({});
  };

  const handleDowngradeOpen = (stripeId) => {
    let selected = (activePlan === "yearly" ? plans.year : plans.month).find(
      (el) => el.stripeId === stripeId
    );
    setSelectedPlan({ ...selected, type: "DOWNGRADE" });
  };

  const handleDowngradeClose = () => {
    setDowngradeModal(false);
    setSelectedPlan({});
  };

  useEffect(() => {
    if (currentPlan) {
      getAllPlansFromServer();
    }
  }, [JSON.stringify(currentPlan)]);

  useEffect(() => {
    dispatch(getUserData());
    getSubscribtionDetails();
    getInvoices();
  }, []);

  const skeletonAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

  return (
    <>
      <Box className="planWrapper">
        <Grid container spacing={0}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Container maxWidth="xxl">
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <StarterPlan
                    activePlan={activePlan}
                    setActivePlan={setActivePlan}
                    currentPlan={currentPlan}
                    planRenewsAt={planRenewsAt}
                  />
                </Grid>
              </Grid>
            </Container>
            <Divider className="divider" />
            <Container maxWidth="xxl">
              <Box className="availableHeading">
                <Typography variant="h6">
                  {MESSAGES.ACCOUNT.PLANS.AVAILABLE_PLANS_HEADING}
                </Typography>
              </Box>
              <Box className="cardGridWrapper">
                {planLoader
                  ? ["1", "2", "3", "4"].map((val) => {
                      return (
                        <Box
                          key={val}
                          className="plansCardSkeleton"
                          sx={{
                            animation: `${skeletonAnimation} 3.5s linear infinite`,
                          }}
                        />
                      );
                    })
                  : (activePlan === "yearly" ? plans.year : plans.month).map(
                      (card, index) => {
                        return (
                          <PlanCard
                            plansOrder={
                              activePlan === "yearly" ? plans.year : plans.month
                            }
                            image={planImages[index]}
                            title={card.name}
                            features={card.features}
                            plan={card.plan}
                            billAnnual={card.billAnnual}
                            billMonthly={card.billMonthly}
                            key={index}
                            stripeId={card.stripeId}
                            annual={card.isYearly}
                            selectedType={card.slug}
                            isActive={card.isActive}
                            currentPlanStripId={currentPlanStripId}
                            currentPlanType={currentPlanType}
                            selectedPlanType={card.isYearly ? "year" : "month"}
                            handleOpen={handleOpen}
                            handleDowngradeOpen={handleDowngradeOpen}
                            currentPlan={currentPlan}
                            setPlanDetails={setPlanDetails}
                          />
                        );
                      }
                    )}
              </Box>
            </Container>
            <Divider className="divider" />
            <Container maxWidth="xxl">
              <Box className="availableHeading">
                <Typography variant="h6">
                  {MESSAGES.ACCOUNT.PLANS.PRODUCT_PRICING_HEADING}
                </Typography>
              </Box>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ProductTable
                    data={
                      activePlan === "yearly" ? pricing.year : pricing.month
                    }
                    tableType="product"
                  />
                </Grid>
              </Grid>
            </Container>
            <Divider className="divider" />
            <Container maxWidth="xxl">
              <Box className="availableHeading">
                <Typography variant="h6">
                  {MESSAGES.ACCOUNT.PLANS.INVOICE.HEADING}
                </Typography>
              </Box>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ProductTable
                    tableType="invoice"
                    data={invoice}
                    callback={getInvoices}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      {upgradeModal && (
        <UpgradeModal
          open={upgradeModal}
          handleClose={handleClose}
          selectedPlan={selectedPlan}
          planDetails={planDetails}
        />
      )}
      {downgradeModal && (
        <DowngradeModal
          open={downgradeModal}
          handleClose={handleDowngradeClose}
          selectedPlan={selectedPlan}
          currentPlanDetails={currentPlanDetails[0]}
          planDetails={planDetails}
        />
      )}
    </>
  );
};

export default index;
