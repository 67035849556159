import React, { useState, useEffect } from "react";

// MUI Components
import { Grid, Typography, Box, Skeleton } from "@mui/material";

// Styles
import "./styles.scss";

const StatsCard = (props) => {
  // State for Skeleton
  const [skeleton, setSkeleton] = useState(false);
  useEffect(() => {
    setSkeleton(true);
    const timeout = setTimeout(() => {
      setSkeleton(false);
    }, 1000);

    // Clear the timeout when the component unmounts or when the effect re-runs
    return () => clearTimeout(timeout);
  }, []);

  const { image, title, value, percentage } = props;
  return (
    <Grid
      item
      lg={12}
      md={6}
      sm={12}
      xs={12}
      className="statsGrid"
      justifyContent="left"
    >
      <Box className="statsWrapper">
        {skeleton ? (
          <Skeleton width={63} height={63} variant="circular" />
        ) : (
          <img src={image} alt={title} />
        )}
        <Box className="statsContent">
          {skeleton ? (
            <Skeleton width={200} height={35} variant="text" />
          ) : (
            <Typography className="statsTitle">{title}</Typography>
          )}
          {skeleton ? (
            <Skeleton width={120} height={35} variant="text" />
          ) : (
            <Typography className="statsValue">{value}</Typography>
          )}
          {skeleton ? (
            <Skeleton width={120} height={35} variant="text" />
          ) : (
            <Typography className="statsPercentage">
              {percentage?.toFixed(1) + "%"}
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default StatsCard;
