import React, { useEffect, useState } from "react";

//Hooks
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure, success } from "../../redux/actions/snackbar-actions";

// Component
import DeleteModal from "./DeleteModal";
import ExportModal from "../Contacts/ExportModal";
import UpdateContact from "../Contacts/UpdateContact";
import CustomFields from "../Contacts/UpdateContact/CustomFields";
import EventLog from "../Contacts/UpdateContact/EventLog";

// Utils
import request from "../../utils/request";
import { convertContactData } from "../../utils/contact-detail-coulmns";
import { MESSAGES } from "../../utils/message";

// MUI Components
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";

// MUI Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// images
import Export from "../../assets/images/contact-details/details-export.svg";
import Edit from "../../assets/images/contact-details/edit.svg";
import Trash from "../../assets/images/contact-details/trash.svg";

// Styles
import "./styles.scss";

const ContactDetails = () => {
  const [contact, setContact] = useState([]);
  const [logs, setLogs] = useState([]);
  const [eventType, setEventType] = useState(["All"]);
  const [orignalContactData, setOrignalContactData] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logLoading, setlogLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState({
    address: "",
    state: "",
    city: "",
    zip: "",
  });

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allCustomFields = useSelector(
    (state) => state?.customFieldsReducers?.customFields
  );

  const isPropertyAddressEnabled = useSelector(
    (state) =>
      state?.generalSettingsReducers?.generalSettings
        ?.propertyAddressStrictness === "Include" || false
  );

  const handleEditClick = () => {
    setIsEditing(true); // Set isEditing to true when edit button is clicked
  };

  const contactId = params.id;

  const modifiedContactsFields =
    orignalContactData?.meta?.custom_fields?.reduce((result, field) => {
      const [key] = Object.keys(field);
      result[key] = field[key];
      return result;
    }, {});

  const handleOpenDelete = () => {
    setshowModal(true);
  };

  const handleCloseDelete = () => {
    setshowModal(false);
  };

  const openExportModal = () => {
    setExportModal(true);
  };
  const closeExportModal = () => {
    setExportModal(false);
  };

  const exportContacts = async () => {
    try {
      const response = await request.get(
        `contacts/export?contactIds=[${contactId}]`
      );
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
    } finally {
      setExportModal(false);
    }
  };

  const fetchContactData = async () => {
    try {
      setIsLoading(true);
      const response = await request.get(`contacts/${contactId}`, {});
      if (response.status === 200) {
        setContact(convertContactData(response.data.data));
        setOrignalContactData(response.data.data);
        response?.data?.data?.properties?.length
          ? setPropertyAddress({
              address: response?.data?.data?.properties[0]?.address1,
              state: response?.data?.data?.properties[0]?.state,
              city: response?.data?.data?.properties[0]?.city,
              zip: response?.data?.data?.properties[0]?.zip,
            })
          : undefined;
      }
    } catch (error) {
      goBackToPreviousRoute();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContactLogs = async () => {
    try {
      setlogLoading(true);
      const response = await request.get(`contacts/logs/${contactId}`, {
        filter: eventType[0].toLowerCase(),
      });
      if (response.status === 200) {
        setLogs(response.data.data);
      }
    } catch (error) {
      return error;
    } finally {
      setlogLoading(false);
    }
  };

  const goBackToPreviousRoute = () => navigate("/contacts");

  const handleDeleteContact = async () => {
    try {
      const response = await request.delete(`contacts/${contactId}`);
      dispatch(success(response.data.message));
      navigate("/contacts");
    } catch (error) {
      dispatch(failure(error.response.data.message));
    }
  };

  useEffect(() => {
    fetchContactLogs();
  }, [eventType]);

  useEffect(() => {
    fetchContactData();
  }, [isEditing]);

  return (
    <Container maxWidth="xxl">
      {!isEditing ? (
        <Grid container className="detailsGrid">
          <Grid item lg={2} md={2} sm={3} xs={3}>
            <Button className="contactsBackBtn" onClick={goBackToPreviousRoute}>
              <ArrowBackIcon />
              {MESSAGES.CONTACTS.CONTACT_BACK_ARROW}
            </Button>
          </Grid>
          <Grid item lg={7} md={7} sm={9} xs={9}>
            <Box className="detailsHeadingtxt">
              <Typography>{MESSAGES.CONTACTS.CONTACT_DETAIL_TITLE}</Typography>
              {isLoading ? (
                <Box
                  sx={{
                    height: "100%",
                    minHeight: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    sx={{
                      color: "#ED5C2F",
                      width: "50px !important",
                      height: "50px !important",
                    }}
                  />
                </Box>
              ) : (
                contact.map((details) => {
                  return (
                    <Box className="detailsWrapper" key={details?.id}>
                      {details?.attribute === "Tags" &&
                      details?.value.length > 0 ? (
                        <>
                          <Typography>{details?.attribute}:</Typography>
                          <Box className="detailsTags">
                            {details?.value.map((tags) => {
                              return (
                                <Box className="tagContent" key={tags}>
                                  <Chip className="tagChip" label={tags} />
                                </Box>
                              );
                            })}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography>{details?.attribute}:</Typography>
                          <Typography>{details?.value || "-"}</Typography>
                        </>
                      )}
                    </Box>
                  );
                })
              )}
              {isPropertyAddressEnabled && (
                <>
                  <Box className="detailsWrapper">
                    <Typography> {MESSAGES.CONTACTS.EDIT.PROPERTY_ADDRESS}:</Typography>
                    <Typography>{propertyAddress?.address || " -"}</Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>{MESSAGES.CONTACTS.EDIT.PROPERTY_CITY}:</Typography>
                    <Typography>{propertyAddress?.city || " -"}</Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>{MESSAGES.CONTACTS.EDIT.PROPERTY_STATE}:</Typography>
                    <Typography>{propertyAddress?.state || " -"}</Typography>
                  </Box>
                  <Box className="detailsWrapper">
                    <Typography>{MESSAGES.CONTACTS.EDIT.PROPERTY_ZIP}:</Typography>
                    <Typography>{propertyAddress?.zip || " -"}</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            sx={{
              marginTop: { md: "0px", sm: "20px", xs: "20px" },
              display: { md: "block", sm: "flex", xs: "flex" },
              justifyContent: { sm: "flex-end", xs: "flex-end" },
            }}
          >
            <Box className="detailsActionBtn">
              <Button className="editBtn" onClick={handleEditClick}>
                <img src={Edit} alt="edit" />
                <Typography>{MESSAGES.CONTACTS.EDIT_BTN_TEXT}</Typography>
              </Button>
              <Button className="editBtn" onClick={openExportModal}>
                <img src={Export} alt="trash" />
                <Typography>{MESSAGES.CONTACTS.EXPORT_BTN_TEXT}</Typography>
              </Button>
              <Button className="delBtn" onClick={handleOpenDelete}>
                <img src={Trash} alt="trash" />
                <Typography>{MESSAGES.CONTACTS.DELETE_BTN_TEXT}</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <UpdateContact
          propertyAddress={propertyAddress}
          setPropertyAddress={setPropertyAddress}
          isPropertyAddressEnabled={isPropertyAddressEnabled}
          setIsEditing={setIsEditing}
        />
      )}

      {allCustomFields.length > 0 ? (
        <CustomFields contactCustomFields={modifiedContactsFields} />
      ) : (
        <Box className="noCustomField"></Box>
      )}

      <EventLog
        data={logs}
        eventType={eventType}
        setEventType={setEventType}
        loading={logLoading}
        mainLoader={isLoading}
      />
      <DeleteModal
        openModal={showModal}
        handleCloseModal={handleCloseDelete}
        deleteContact={handleDeleteContact}
      />
      <ExportModal
        open={exportModal}
        handleExport={exportContacts}
        handleClose={closeExportModal}
        header={MESSAGES.CONTACTS.SINGLE_EXPORT_HEADER}
        message={MESSAGES.CONTACTS.EXPORT_SINGLE_CONTACTS}
      />
    </Container>
  );
};

export default ContactDetails;
