import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { failure } from "../../../../redux/actions/snackbar-actions";

// Utils
import {
  createViewProofForOrder,
  multiPageTemplates,
} from "../../../../utils/template-builder";
import { CONTACTS_OPTION_TYPE } from "../../../../utils/constants";
import { numberWithComma } from "../../../../utils/helperFunctions";
import { MESSAGES } from "../../../../utils/message";

// MUI Components
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

// icons
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HTML from "../../../../assets/images/create-orders/html-icon.svg";

// styles
import "./styles.scss";

const ReviewOrder = (props) => {
  const {
    forntImage,
    backImage,
    template,
    deliverable,
    checkedIds,
    setCheckedIds,
    selectedReturnedAddress,
    contactType,
    selectedContact,
    name,
    errors,
    updateErrors,
    htmlBox,
    isTemplateAvailable,
  } = props;
  const [isFlipped, setFlipped] = useState(false);
  const [downloadingProof, setDownloadingProof] = useState(false);

  const dispatch = useDispatch();

  const handleCheckboxChange = (contentId) => {
    updateErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
    if (checkedIds.includes(contentId)) {
      setCheckedIds(checkedIds.filter((id) => id !== contentId));
    } else {
      setCheckedIds([...checkedIds, contentId]);
    }
  };

  const checkList = [
    {
      id: 1,
      label: MESSAGES.ORDERS.CREATE.REVIEW.PROOF_TITLE,
    },
    {
      id: 2,
      label: `Mailing to ${numberWithComma(deliverable)} ${
        deliverable <= 1
          ? MESSAGES.ORDERS.CREATE.REVIEW.DELIVERABLE_TITLE
          : MESSAGES.ORDERS.CREATE.REVIEW.DELIVERABLES_TITLE
      }`,
    },
    {
      id: 3,
      label: MESSAGES.ORDERS.CREATE.REVIEW.SPELLING_TITLE,
    },
    {
      id: 4,
      label: MESSAGES.ORDERS.CREATE.REVIEW.VARIABLE_TITLE,
    },
  ];

  const downloadViewProof = async () => {
    try {
      let key = CONTACTS_OPTION_TYPE[contactType];
      key = key === "tag" ? "tagId" : key;
      let payload = {
        templateId: template.id,
        returnContactId:
          selectedReturnedAddress.id === "-1"
            ? undefined
            : selectedReturnedAddress.id,
        [key]: key === "reqId" ? selectedContact : selectedContact.id,
      };
      setDownloadingProof(true);
      await dispatch(createViewProofForOrder(template.label, payload));
      setDownloadingProof(false);
    } catch (error) {
      setDownloadingProof(false);
      dispatch(failure(MESSAGES.VIEW_PROOF_ERROR));
    }
  };

  useEffect(() => {
    const body = document.getElementById("dashboard");
    if (body) {
      body.style.margin = "105px 0px 115px";
    }

    return () => {
      if (body) {
        body.style.margin = "105px 0px 75px";
      }
    };
  }, []);

  return (
    <>
      <Grid item lg={9} md={12} sm={12} xs={12}>
        <Box className="reviewOrderCheckWrapper">
          <Typography>{MESSAGES.ORDERS.CREATE.REVIEW.HEADING}</Typography>
          <FormGroup>
            {checkList.map((content, id) => {
              return (
                <FormControlLabel
                  key={id}
                  className="reviewOrderChecks"
                  control={
                    <Checkbox
                      className={`customCheckbox ${
                        checkedIds.includes(content.id) ? "checked" : ""
                      }`}
                      checked={checkedIds.includes(content.id)}
                      onClick={() => handleCheckboxChange(content.id)}
                      icon={<Box className="notChecked"></Box>}
                      checkedIcon={<DoneIcon />}
                    />
                  }
                  label={<Typography>{content.label}</Typography>}
                />
              );
            })}
            {errors && (
              <Typography className="errorMessage">
                <sup>*</sup>
                {MESSAGES.ORDERS.CREATE.REVIEW.REQUIRED}
              </Typography>
            )}
          </FormGroup>
        </Box>
      </Grid>
      <Grid
        item
        lg={3}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: isTemplateAvailable ? "end" : "flex-start",
        }}
      >
        {template?.id && template?.templateType === "json" && (
          <Box className="viewProofWrapper">
            <Box className="proofBtn">
              <Box className="Wrapper">
                <Box className={`proofWrapper ${isFlipped ? "flip" : ""}`}>
                  <Box className="flipper">
                    {forntImage ? (
                      <img
                        src={!isFlipped ? forntImage : backImage}
                        alt={template?.productType}
                      />
                    ) : (
                      MESSAGES.ORDERS.CREATE.REVIEW.HTML_LABEL
                    )}
                  </Box>
                </Box>

                <Box className="reviewProofBtnsWrapper">
                  <Box className="proofBtns">
                    {multiPageTemplates.includes(template?.productType) && (
                      <>
                        <ArrowBackIosNewIcon
                          onClick={() => setFlipped((prev) => !prev)}
                        />
                        <ArrowForwardIosIcon
                          onClick={() => setFlipped((prev) => !prev)}
                        />
                      </>
                    )}
                  </Box>
                  <Button className="viewProofBtn" onClick={downloadViewProof}>
                    {downloadingProof ? (
                      <CircularProgress
                        sx={{
                          color: "#ed5c2f",
                          width: "25px !important",
                          height: "25px !important",
                        }}
                      />
                    ) : (
                      MESSAGES.ORDERS.CREATE.REVIEW.PROOF_LABEL
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {template?.id && template.templateType === "html" && (
          <Box
            className={
              htmlBox ? "orderHTMLBox orderHTMLBoxWidth" : "orderHTMLBox"
            }
          >
            <img src={HTML} alt="html-icon" />
            <Button onClick={downloadViewProof}>
              {downloadingProof ? (
                <CircularProgress
                  sx={{
                    color: "#ed5c2f",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.ORDERS.CREATE.VIEW_PROOF_LABEL
              )}
            </Button>
            <Typography>
              {MESSAGES.ORDERS.CREATE.HTML_TEMPLATE_LABEL}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default ReviewOrder;
