//Actions
import { SET_WEBHOOKS_DATA, SET_WEBHOOKS_EVENTS_DATA, SET_WEBHOOKS_LOADER, LOGOUT } from "../actions/action-types"

const initialState = {
    webhooks: [],
    events: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 10,
    totalWebhooksInDB: null,
    loading: false,
};

const webhooksReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_WEBHOOKS_DATA:
            return {
                ...state, webhooks: payload.rows, rowsCount: payload.count, totalWebhooksInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_WEBHOOKS_EVENTS_DATA:
            return { ...state, events: payload };
        case SET_WEBHOOKS_LOADER:
            return { ...state, loading: payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { webhooksReducers };