import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
// import BackIcon from "../../../../assets/images/templates/back-dialog-icon.svg";
// Styles
import "./styles.scss";

// images
import CloseIcon from "../../../../assets/images/modal/modal-cross.jsx";
import crossIcon from "../../../../assets/images/templates/cancelIcon.png";
import { MESSAGES } from "../../../../utils/message.js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/**
 * ConfirmOwnDesignDialog Component
 * A modal for duplicating a template.
 *
 * @param {boolean} open - Boolean indicating whether the modal is open.
 * @param {Function} handleClose - Function to handle the closing of the modal.
 * @returns {JSX.Element} The duplicate template modal component.
 *
 */
const ConfirmOwnDesignDialog = ({
  open,
  handleClose,
  handleOk,
  title,
  heading,
  paragraph,
}) => {
  // Close the modal
  const handleCloseDialog = () => handleClose(false);

  const templateLoading = useSelector(
    (state) => state.templateReducer.templateLoading
  );

  return (
    <BootstrapDialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="confirmTemplateLeaveModal"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="confirmTemplateLeaveModalContent">
        <Box className="trashIconWrapper">
          <img src={crossIcon} alt="trash" />
          <Typography>{title}</Typography>
        </Box>
        <Box className="confirmTemplateLeaveText">
          <Typography>{heading}</Typography>
          <Typography>{paragraph}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button autoFocus onClick={handleCloseDialog}>
          {MESSAGES.TEMPLATE.SELECT_TEMPLATE.CANCEL_BUTTON}
        </Button>
        <Button autoFocus onClick={handleOk} disabled={templateLoading}>
          {templateLoading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "25px !important",
                height: "25px !important",
              }}
            />
          ) : 
            MESSAGES.TEMPLATE.SELECT_TEMPLATE.SUBMIT_BUTTON
          }
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

// Prop types
ConfirmOwnDesignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

ConfirmOwnDesignDialog.defaultProps = {
  open: false,
};

export default ConfirmOwnDesignDialog;
