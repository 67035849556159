import React from "react";

//utls
import { MESSAGES } from "../../../../utils/message.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Box,
  CircularProgress,
} from "@mui/material";

// image
import Close from "../../../../assets/images/modal/modal-cross.jsx";
import Trash from "../../../../assets/images/wallet/trash.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteDialog = ({ handleClose, open, handleOk, loading, name }) => {

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="fundsModalWrapper1"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent className="fundsModalContent">
          <Box className="fundsHeader">
            <img className="trashDelIcon" src={Trash} alt="Trash" />
            <Typography gutterBottom variant="h4">
              Delete {name ? name : "item"}
            </Typography>
            <h5 className="delMessage">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.DELETE_CONFIRMATION} {name}?
            </h5>
            <Box className="fundsModalBtns">
              <Button
                onClick={handleClose}
              >
                {MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}
              </Button>
              <Button disabled={loading}
                onClick={handleOk}
              >
                {
                  loading ? <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  /> : 'Yes'
                }

              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default DeleteDialog;
