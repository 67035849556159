import React from "react";

// Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";

// Utils
import { MESSAGES } from "../../../../utils/message";

// Components
import GenericAutocomplete from "../../../General/Autocomplete";

// MUI Components
import { Box, InputLabel, Grid, Typography, Tooltip } from "@mui/material";

// images
import Schedule from "../../../../assets/images/create-orders/schedule-icon.svg";
import { dateProtector } from "../../../../utils/date-format";

const OrderDetails = (props) => {
  const {
    setSelectedReturnedAddress,
    selectedReturnedAddress,
    scheduleOptions,
    selectedDate,
    expectedDate,
    setSelectedDate,
    isDatepickerOpen,
    returnOptions,
    datepickerRef,
    setStartDate,
    startDate,
    setIsDatepickerOpen,
    setSearch,
    setErrors,
    loading,
    errors,
  } = props;
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} padding={0}>
        <Box className="templateSelectWrapper">
          <InputLabel className="selectLabel">
            {MESSAGES.ORDERS.CREATE.RETURN_ADDRESS_TITLE}
          </InputLabel>
          <GenericAutocomplete
            placeholder="Return Address"
            options={returnOptions}
            setSelectedValue={setSelectedReturnedAddress}
            selectedValue={selectedReturnedAddress}
            search={setSearch}
            updateErrors={setErrors}
            name={"returnAddress"}
            loading={loading}
          />
          {errors.returnAddress && (
            <Typography className="errorMessage">
              <sup>*</sup>
              {MESSAGES.ORDERS.CREATE.RETURN_ADDRESS_REQUIRED}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} padding={0}>
        <Box className="templateSelectWrapper">
          <InputLabel className="selectLabel">
            {MESSAGES.ORDERS.CREATE.DATE_LABEL}
            <Tooltip title="The date mailers will be sent to our mailhouse.">
              <span>
                <img src={Schedule} alt="schedule" />
              </span>
            </Tooltip>
          </InputLabel>
          <GenericAutocomplete
            placeholder="Scheduled Date"
            options={scheduleOptions}
            selectedValue={selectedDate}
            setSelectedValue={setSelectedDate}
            search={() => {}}
            updateErrors={setErrors}
            name={"date"}
          />
          {errors.date && (
            <Typography className="errorMessage">
              <sup>*</sup>
              {MESSAGES.ORDERS.CREATE.DATE_REQUIRED}
            </Typography>
          )}
          {isDatepickerOpen && (
            <Box ref={datepickerRef} className="mailedDate">
              <Box className="selectDate orderDetailsDatePicker">
                <DatePicker
                  selected={startDate}
                  minDate={addDays(new Date(), 1)}
                  onChange={(update) => {
                    dateProtector(update);
                    setStartDate(update);
                    setSelectedDate(
                      update.toLocaleDateString("en-US").slice(0, 10)
                    );
                    setIsDatepickerOpen(false);
                  }}
                  onFocus={() => setIsDatepickerOpen(true)}
                  inline
                />
              </Box>
            </Box>
          )}
          {expectedDate && (
            <Box className="expectedDate">
              <Typography>
                <strong>{MESSAGES.ORDERS.CREATE.DELIVERY_DATE}</strong>
                {expectedDate}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default OrderDetails;
