import React, { useState } from "react";

// MUI Components
import { Box, Button, Typography } from "@mui/material";

// React router Link
import { Link } from "react-router-dom";

// utils
import { MESSAGES } from "../../../utils/message";

// modals
import ConfirmModal from "../ConfirmModal";

// placeholder icon
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

// styles
import "./styles.scss";

const IntegrationCard = (props) => {
  const { title, image, description, status, id, link, handleAction, loading} = props;
  const [open, setOpen] = useState(false);
  const [sourceID, setSourceID] = useState("");
  const [modalHead, setModalHead] = useState(
    MESSAGES.INTEGRATIONS.CARD.DISABLE_BUTTON_LABEL
  );

  const handleClickOpen = (type, id) => {
    setOpen(true);
    setModalHead(type);
    setSourceID(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="integrationCardWrapper">
      <Box className="integratedImage">
        {image ? (
          <img src={image} alt="image" />
        ) : (
          <IntegrationInstructionsIcon />
        )}
      </Box>
      <Box className="cardInfo">
        <Typography variant="h6">{title}</Typography>
        <Typography>
          {description ? description : "No description found"}
        </Typography>
      </Box>
      <Box className="cardFooter">
        <Link to={link} target="_blank">
        <Typography variant="h6">
          {MESSAGES.INTEGRATIONS.CARD.READ_LABEL}
        </Typography>
        </Link>
        <Button
          onClick={() =>
            handleClickOpen(
              status !== "Disabled"
                ? MESSAGES.INTEGRATIONS.CARD.DISABLE_BUTTON_LABEL
                : MESSAGES.INTEGRATIONS.CARD.ENABLE_BUTTON_LABEL,
              id,
              title
            )
          }
        >
          {status !== "Disabled"
            ? MESSAGES.INTEGRATIONS.CARD.DISABLE_BUTTON_LABEL
            : MESSAGES.INTEGRATIONS.CARD.ENABLE_BUTTON_LABEL}
        </Button>
      </Box>
      <ConfirmModal
        open={open}
        handleClose={handleClose}
        handleAction={handleAction}
        modalHead={modalHead}
        title={title}
        loading={loading}
        sourceID={sourceID}
        status={status}
      />
    </Box>
  );
};

export default IntegrationCard;
