//Actions
import { SET_TAGS_DATA, SET_TAGS_LOADER,  LOGOUT } from "../actions/action-types"

const initialState = {
    tags: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 10,
    totalTagsInDB: null,
    loading: false,
};

const tagsReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_TAGS_DATA:
            return {
                ...state, tags: payload.rows, rowsCount: payload.count, totalTagsInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_TAGS_LOADER:
            return { ...state, loading: payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { tagsReducers };