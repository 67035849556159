// Utils
import { chartColors } from "../../utils/constants";
//Actions
import { SET_ADMIN_DASHBOARD_CHART_DATA } from "../actions/action-types"

const initialState = {
    chart: {
        datasets: [],
        labels: [],
    },
    numberOfOrders: 0,
    revenue: 0,

};

const adminDashboardReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ADMIN_DASHBOARD_CHART_DATA:
            return {
                ...state,
                numberOfOrders: payload?.numberOfOrders,
                revenue: payload?.revenue,
                chart: {
                    labels: payload?.chartData?.labels,
                    datasets: [
                        {
                            label: 'No. of Orders',
                            data: payload?.chartData?.orders,
                            borderColor: chartColors['Mailed'],
                            backgroundColor: chartColors['Mailed'],
                        },
                        {
                            label: 'Revenue',
                            data: payload?.chartData?.revenues,
                            borderColor: chartColors['Delivered'],
                            backgroundColor: chartColors['Delivered'],
                        }
                    ]
                },
            };
        default:
            return state;
    }
};

export { adminDashboardReducers };