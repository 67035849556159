import React from "react";

// Utils
import { revertImpersonatedUser } from "../../../../../utils/auth.js";
import { MESSAGES } from "../../../../../utils/message.js";

// Mui components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// images
import Close from "../../../../../assets/images/modal/modal-cross.jsx";
import UserIcon from "../../../../../assets/images/admin/usericon.svg";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SwitchBackModal = (props) => {
  const { open, handleClose } = props;

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="switchModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        <img height={77} src={UserIcon} alt="icon" />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent className="filterModalContent">
        <Box className="wrapper">
          <Typography>
            {
              MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.SWITCH_BACK_MODAL
                .HEADING
            }
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button onClick={handleClose}>
          {
            MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.SWITCH_BACK_MODAL
              .CANCEL_BUTTON
          }
        </Button>
        <Button onClick={revertImpersonatedUser}>
          {
            MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.SWITCH_BACK_MODAL
              .SUBMIT_BUTTON
          }
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default SwitchBackModal;
