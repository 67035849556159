import React, { useEffect, useState } from 'react'

//utils
import { MESSAGES } from '../../../../../utils/message';

//charrtjs
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";

// images
import analytics from '../../../../../assets/images/orders/analytics-nodata.jpg'
import ChoroplethUsStates from './choroplethUsStates';

//MUI
import { Box, Typography } from '@mui/material';

//components
import PieChart from './pieChart';


//style
import "./styles.scss";

Chart.register(CategoryScale);
// styles

function Analytics({ info, stepper }) {
  const [isData, setIsData] = useState(false);

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px',
  };

  const gridItemStyle = {
    padding: '0px 40px 70px',
  };

  useEffect(() => {
    if (stepper.status == "Mailed" || stepper.status == "Completed") {
      setIsData(true)
    }
  }, [stepper.status])

  return (
    <Box className='analytics_wrapper'>
      <p className='title'>Analytics</p>
      <div style={gridContainerStyle}>
        {isData && <div style={{ ...gridItemStyle }}> <PieChart info={info} deliverableQuantity={stepper?.deliverableQuantity || 0} /> </div>}
        {isData && <div style={gridItemStyle}> <ChoroplethUsStates info={info} /> </div>}
      </div>

      {!isData && <div className="analytics_imp_parent">
        <Typography className="analytics_text_heading">{MESSAGES.ORDERS.DETAIL.OVERVIEW.NO_DATA}</Typography>
        <Typography className="analytics_text">{MESSAGES.ORDERS.DETAIL.OVERVIEW.NO_DATA_ANALYTICS}</Typography>
        <img src={analytics} className='analytic_img' alt='img' />
      </div>}
    </Box>
  )
}

export default Analytics
