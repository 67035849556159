import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

// Utils
import { DASHBOARD_DATE_OPTIONS } from "../../../utils/constants";
import { fetchAdminDashboardChart } from "../../../redux/actions/admin-dashboard-actions";

// Components
import StatsCard from "./StatsCard";
import Chart from "./Chart";
import MultiSelect from "../../General/MultiSelect";

// Mui components
import { Typography, Box, Container } from "@mui/material";

// Styles
import "./styles.scss";

function Dashboard() {
  const [selectedDate, setSelectedDate] = useState([
    DASHBOARD_DATE_OPTIONS[4].title,
  ]);

  const dispatch = useDispatch();

  const totalOrders =
    useSelector((state) => state.adminDashboardReducers.numberOfOrders) || 0;

  const revenue =
    useSelector((state) => state.adminDashboardReducers.revenue) || 0;

  const dashboardChart = useSelector(
    (state) => state.adminDashboardReducers.chart
  );

  useEffect(() => {
    dispatch(
      fetchAdminDashboardChart({ duration: selectedDate[0]?.toLowerCase() })
    );
  }, [selectedDate]);

  return (
    <>
      <Container maxWidth="xxl">
        <StatsCard totalOrders={totalOrders} revenue={revenue} />
        <div className="graph">
          <Box
            className={
              dashboardChart?.labels?.length
                ? "dateDropDown mgRight"
                : "dateDropDown"
            }
          >
            <div>
              <MultiSelect
                options={DASHBOARD_DATE_OPTIONS}
                selectedValue={selectedDate}
                setSelectedValue={setSelectedDate}
                simpleSelect={true}
                multiple={false}
                placeholder="Select Date"
                component="overviewdateDropDown"
              />
            </div>
          </Box>
          <Chart data={dashboardChart} />
        </div>
      </Container>
    </>
  );
}

export default Dashboard;
