import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { deleteTemplateCategory } from "../../../../redux/actions/admin-templates-actions";
import { success, failure } from "../../../../redux/actions/snackbar-actions";

// utils
import { MESSAGES } from "../../../../utils/message";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import Trash from "../../../../assets/images/contact-details/delete.svg";
import crossicon from "../../../../assets/images/modal/crossIcon.svg";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteCategoryModal = (props) => {
  const { openModal, handleCloseModal, selectedTemplateCategory, pagination } =
    props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const deleteExistingCategory = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        deleteTemplateCategory(selectedTemplateCategory.id, pagination)
      );
      dispatch(success(response.data.message));
      handleCloseModal();
    } catch (error) {
      dispatch(failure(response.data.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="customDeleteModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        className="crossIcon"
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={crossicon} alt="crossIcon" />
      </IconButton>
      <DialogContent dividers className="deleteModalContent">
        <img src={Trash} alt="delete" />
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.DELETE.TITLE}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.DELETE.HEADING}
          </Typography>
        </Box>
        <Box className="deleteModalBtns">
          <Button onClick={handleCloseModal}>
            {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.DELETE.CANCEL_BUTTON}
          </Button>
          <Button onClick={deleteExistingCategory}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.ADMIN_DASHBOARD.CATEGORIES.DELETE.SUBMIT_BUTTON
            )}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DeleteCategoryModal;
