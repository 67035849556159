import React, { useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { Grid , Typography } from "@mui/material";
import { clearFilter, createTemplate, getAllCustomFields, getOneTemplate, searchAndAdvanceChange, uploadTemplate } from "../../redux/actions/template-builder";
import { failure, success } from "../../redux/actions/snackbar-actions";
import { useDispatch, useSelector } from "react-redux";
import TopNavigation from "../TemplateBuilder/TopNavigation/TopNavigation";

import './styles.scss';
import { useParams } from "react-router-dom";
import { extractVariablesFromHtml, getFileAsBlob } from "../../utils/template-builder";

const HtmlEditor = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [timeoutId, setTimeoutId] = useState(null);
  const [backTimeoutId, setBackTimeoutId] = useState(null);
  const [htmlDynamicFields, setHtmlDynamicFields] = useState([]);
  const [backHtmlDynamicFields, setBackHtmlDynamicFields] = useState([]);
  const title = useSelector(state => state.templateReducer.title);
  const html = useSelector(state => state.templateReducer.html);
  const backHtml = useSelector(state => state.templateReducer.backHtml);
  const product = useSelector(state => state.templateReducer.product);
  const template = useSelector(state => state.templateReducer.template);
  const defaultFieldsHashMap = useSelector(state => state.templateReducer.defaultDynamicFieldsHashMap);
  const dynamicFields = useSelector(state => state.templateReducer.dynamicFields);

  let isPostCards = (product && product.productType==='Postcards')? true : false;
  const editorRef = useRef(null);

  useEffect(()=> {
    if(id){
      dispatch(getOneTemplate(id));
    }
    dispatch(searchAndAdvanceChange('templateType', "html"));
    dispatch(getAllCustomFields());
    return ()=> {
      dispatch(clearFilter());
    }
  },[]);

  useEffect(()=> {
      handleExtractDynamicFields(html, "html");
      if(isPostCards) handleExtractDynamicFields(backHtml, 'backHtml');
  },[html, backHtml, dynamicFields]);

  useEffect(()=> {
    if(template && template.templateType==='html'){
      getContent();
    }
  },[template]);

  const getContent = async () => {
    const html = await getFileAsBlob(template.templateUrl, 'html');
    dispatch(searchAndAdvanceChange('html', html));
    if(isPostCards){
      const html = await getFileAsBlob(template.backTemplateUrl, 'html');
      dispatch(searchAndAdvanceChange('backHtml', html));
    }
  } 
  const handleChangeTemplate = (value, html='html') => {
    dispatch(searchAndAdvanceChange(html, value));
  }

  const handleExtractDynamicFields = (value, html='html') => {
    if(timeoutId && html==='html'){
      clearTimeout(timeoutId);
    }else if(backTimeoutId && html!=='html') {
      clearTimeout(backTimeoutId);
    }
    let _timeoutId = setTimeout(()=> {
      let variables = extractVariablesFromHtml(value);
      variables = variables.map(item=> {
        const _value = item.key.replace(/{{|}}/g, '')
        if(defaultFieldsHashMap[item.key] || dynamicFields[_value]){
          return {
            ...item,
            error: false
          }
        }
        return {
          ...item,
          error: true
        }
      })
      if(html==='html'){
        setHtmlDynamicFields(variables);
      } else {
        setBackHtmlDynamicFields(variables);
      }
    },1000);
    if(html==='html'){
      setTimeoutId(_timeoutId);
    }else {
      setBackTimeoutId(_timeoutId);
    }
  }
  
  return (
    <Grid className="html-editor">
       <TopNavigation showBackButton={false} />
       <div className="hr-line" />
       <Grid container className="html-editor-middle">
          <Grid item xs={9}>
            <Typography className="title">{isPostCards? 'Front HTML': 'HTML'}</Typography>
                <AceEditor
                className={`editor ${isPostCards? 'dy-height':''}`}
                ref={editorRef}
              mode="html"
              theme="xcode" 
              onChange={(value)=>handleChangeTemplate(value, 'html')}
              placeholder="Paste your Html content here"
              name="html-editor"
              value={html}
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
              }}
              
            />
          </Grid>
          <Grid item xs={3} >
              <div className={`html-editor-right ${isPostCards? 'dy-height':''}`}>
                <Typography className="html-editor-right__title">Variable Fields:</Typography>
                <Typography className="html-editor-right__learn">Learn More</Typography>
                <div className={`html-editor-right__variable ${isPostCards? 'dy-height':''}`}>
                   {htmlDynamicFields.length===0 && <Typography>No variable fields found</Typography>}
                   {htmlDynamicFields.map((item, i)=> <Typography key={i} className={item.error? 'error':''}>{item.value} {item.error? <span>error field not found in database</span>: null} </Typography>)}
                </div>
              </div>
          </Grid>
       </Grid>
      {isPostCards && <Grid container className="html-editor-middle">
            <Grid item xs={9}>
                <Typography className="title">{isPostCards? 'Back HTML': ''}</Typography>
                  <AceEditor
                  className={`editor ${isPostCards? 'dy-height':''}`}
                mode="html"
                theme="xcode" 
                onChange={(value)=>handleChangeTemplate(value, 'backHtml')}
                placeholder="Paste your Html content here"
                name="html-editor"
                value={backHtml}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
            <div className="html-editor-right">
                <Typography className="html-editor-right__title">Variable Fields:</Typography>
                <Typography className="html-editor-right__learn">Learn More</Typography>
                <div className={`html-editor-right__variable ${isPostCards? 'dy-height':''}`}>
                   {backHtmlDynamicFields.length===0 && <Typography>No variable fields found</Typography>}
                   {backHtmlDynamicFields.map((item, i)=> <Typography key={i} className={item.error? 'error':''}>{item.value} {item.error? <span>error field not found in database</span>: null} </Typography>)}
                </div>
              </div>
            </Grid>
       </Grid>}
    </Grid>
  );
}

export default HtmlEditor;