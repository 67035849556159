import React from "react";

// MUI Components
import { Box, Button, Container, Typography } from "@mui/material";

// Assets
import NoContact from "../../../assets/images/contacts/no-contact.svg";

// Icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// styles
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const NoTemplateScreen = () => {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noTemplateWrapper">
        <img src={NoContact} alt="no-contacts" />
        <Typography>You have no Templates in your database</Typography>
        <Button onClick={() => navigate("/create-template")}>
          <AddCircleOutlineIcon />
          Create New Template
        </Button>
      </Box>
    </Container>
  );
};

export default NoTemplateScreen;
