import React, { useEffect, useState } from "react";

// Mui components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, Input } from "@mui/material";
// MultiSelect
// import MultiSelect from "../../Contacts/AdvanceFilters/MultiSelect";
import MultiSelect from "../../General/MultiSelect";
// Styles
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTemplates,
  searchAndAdvanceChange,
} from "../../../redux/actions/template-builder";
import { failure } from "../../../redux/actions/snackbar-actions";
// images
import Close from "../../../assets/images/modal/modal-cross.jsx";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const productType = [
  {
    id: 1,
    title: "In Transit",
  },
  {
    id: 2,
    title: "Mailed",
  },
  {
    id: 3,
    title: "Scheduled",
  },
  {
    id: 4,
    title: "Delivered",
  },
  {
    id: 5,
    title: "Returned to Sender",
  },
];

const FilterModal = ({ handleClose, open, updatedProducts }) => {
  const dispatch = useDispatch();
  const [selectedFields, setSelectedFields] = useState([]);

  const templates = useSelector((state) => state.templateReducer.templates);
  const search = useSelector((state) => state.templateReducer.search);
  const searchProductIds = useSelector(
    (state) => state.templateReducer.searchProductIds
  );
  const searchCreator = useSelector(
    (state) => state.templateReducer.searchCreator
  );

  const [createdText, setCreatedText] = useState(searchCreator);

  const handleSearch = () => {
  
    const productsIds = updatedProducts
      .filter((product) => selectedFields.includes(product.title))
      .map((product) => product.title)
      .join(",");

      const selectedIds = JSON.stringify(updatedProducts
        .filter((product) => selectedFields.includes(product.title))
        .map((product) => Number(product.id)));

    if (!createdText.trim() && !productsIds) {
      dispatch(failure("Please select any filter"));
      return;
    }
    dispatch(searchAndAdvanceChange("searchCreator", createdText));
    dispatch(searchAndAdvanceChange("searchProductIds", productsIds));
    dispatch(
      getAllTemplates(1, templates.perPage, search, productsIds, createdText, "", selectedIds)
    );
    handleClose();
  };

  const handleClear = () => {
    dispatch(searchAndAdvanceChange("searchProductIds", ""));
    dispatch(searchAndAdvanceChange("searchCreator", ""));
    dispatch(getAllTemplates(1, templates.perPage, search));
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchProductIds) {
      let products = searchProductIds.split(",");
      products = updatedProducts
        .filter((product) => products.includes(product.title))
        .map((product) => product.title);

      setSelectedFields([...products]);
    }
  }, []);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="filterModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        Filters
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent className="filterModalContent">
        <Box className="inputWrapper">
          <Typography>Product Type</Typography>
          <MultiSelect
            options={updatedProducts}
            selectedValue={selectedFields}
            setSelectedValue={setSelectedFields}
            productType={true}
            placeHolderText="Select Product Type"
            noOptionText="No Product Type to show"
          />
        </Box>
        <Box className="inputWrapper">
          <Typography>Created by</Typography>
          <Input
            className="createdByInput"
            value={createdText}
            name="searchCreator"
            onChange={(e) => {
              setCreatedText(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            type="text"
            placeholder="Created by"
          />
        </Box>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button onClick={handleClear}>Clear</Button>
        <Button onClick={handleSearch}>Apply</Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default FilterModal;
