import React from "react";

//utils
import {MESSAGES} from "../../../../utils/message.js"

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// image
import Close from "../../../../assets/images/modal/modal-cross.jsx";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ConfirmDialog = ({
  handleClose,
  open,
  message,
  handleOk,
  loading,
  successButtonName,
}) => {
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="autoFundModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent className="autoFundModalContent">
          <Box className="autoFundHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.CONFIRM}
            </Typography>
          </Box>
          <Box className="amountInputWrapper">
            <Typography>{message}</Typography>
          </Box>
          <Box className="autoFundModalBtns">
            <Button onClick={handleClose}>{MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}</Button>
            <Button disabled={loading} onClick={handleOk}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : successButtonName ? (
                successButtonName
              ) : (
                "Ok"
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ConfirmDialog;
