import { useState, useEffect } from "react";

export const displayTooltip = () => {
  const [disableTooltip, setDisableTooltip] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setDisableTooltip(screenWidth >= 2100);
    };

    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [disableTooltip]);
  return disableTooltip;
};
