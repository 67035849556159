import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

// Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  CLEAR_ADMIN_ORDERS_FILTERS,
  SET_ADMIN_ORDERS_FILTERS,
} from "../../../../redux/actions/action-types.js";
import { failure } from "../../../../redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "../../../../utils/message";

// Components
import MultiSelect from "../../../General/MultiSelect";

// Mui components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Grid, InputLabel } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// images
import Close from "../../../../assets/images/modal/modal-cross.jsx";

// Styles
import "./styles.scss";
import {
  dateProtector,
  handleRawChangeForDatePicker,
} from "../../../../utils/date-format.js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AdvanceFilters = forwardRef((props, ref) => {
  const { handleCloseFilter, open, updatePagination } = props;
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState({
    created: false,
    scheduled: false,
  });
  const [createdDate, setCreatedDate] = useState([null, null]);
  const [scheduledDate, setScheduleDate] = useState([null, null]);
  const [startDateCreated, endDateCreated] = createdDate;
  const [startDateScheduled, endDateScheduled] = scheduledDate;

  const dispatch = useDispatch();

  const orderStatus = useSelector(
    (state) => state.adminOrdersReducers.filterslabels.orderStatus
  );

  const paymentStatus = useSelector(
    (state) => state.adminOrdersReducers.filterslabels.paymentStatus
  );

  const productType = useSelector(
    (state) => state.adminOrdersReducers.filterslabels.productType
  );

  const source = useSelector(
    (state) => state.adminOrdersReducers.filterslabels.source
  );

  const perPage = useSelector(
    (state) => state.adminOrdersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const filters = useSelector((state) => state.adminOrdersReducers.filters);

  const onClose = () => {
    clearFilters(true);
  };

  const clearFilters = (persist = false) => {
    setSelectedProduct([]);
    setSelectedSource([]);
    setSelectedOrder([]);
    setSelectedPayment([]);
    setScheduleDate([null, null]);
    setCreatedDate([null, null]);
    !persist ? dispatch({ type: CLEAR_ADMIN_ORDERS_FILTERS }) : undefined;
    handleCloseFilter();
  };

  const applyFilters = () => {
    const dateRangeCreate =
      startDateCreated && endDateCreated
        ? {
            startDate: startDateCreated.toISOString().slice(0, 10),
            endDate: endDateCreated.toISOString().slice(0, 10),
          }
        : null;

    if (startDateCreated && endDateCreated === null) {
      dispatch(failure(MESSAGES.ORDERS.END_DATE_REQUIRED));
      return;
    }

    const dateRangescheduled =
      startDateScheduled && endDateScheduled
        ? {
            startDate: startDateScheduled.toISOString().slice(0, 10),
            endDate: endDateScheduled.toISOString().slice(0, 10),
          }
        : null;

    if (startDateScheduled && endDateScheduled === null) {
      dispatch(failure(MESSAGES.ORDERS.END_DATE_REQUIRED));
      return;
    }

    let payload = {
      applyFilters: true,
      filters: {
        productType: selectedProduct,
        source: selectedSource,
        orderStatus: selectedOrder,
        paymentStatus: selectedPayment,
        createdAt: dateRangeCreate,
        scheduledDate: dateRangescheduled,
      },
    };

    const filtersWithValues = Object.keys(payload.filters).filter((key) => {
      const value = payload.filters[key];
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });

    payload.filterSize = filtersWithValues.length;

    updatePagination({
      pageSize: perPage,
      page: 1,
    });

    if (filtersWithValues.length <= 0) {
      dispatch(failure(MESSAGES.ORDERS.FILTERS_REQUIRED));
      return;
    }

    dispatch({ type: SET_ADMIN_ORDERS_FILTERS, payload: payload });
    onClose();
  };

  useImperativeHandle(ref, () => ({
    clearFilters() {
      clearFilters();
    },
  }));

  useEffect(() => {
    if (Object.values(filters).length) {
      setSelectedProduct(filters.productType);
      setSelectedSource(filters.source);
      setSelectedOrder(filters.orderStatus);
      setSelectedPayment(filters.paymentStatus);
      filters.createdAt
        ? setCreatedDate([
            new Date(filters?.createdAt?.startDate),
            new Date(filters?.createdAt?.endDate),
          ])
        : undefined;
      filters.scheduledDate
        ? setScheduleDate([
            new Date(filters?.scheduledDate?.startDate),
            new Date(filters?.scheduledDate?.endDate),
          ])
        : undefined;
    }
  }, [open]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="orderfilterModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        {MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS.HEADING}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent className="filterModalContent">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .PRODUCT_TYPE_LABEL
                }
              </Typography>
              <MultiSelect
                options={productType}
                selectedValue={selectedProduct}
                setSelectedValue={setSelectedProduct}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .PRODUCT_TYPE_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .NO_PRODUCT_TYPE
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS.SOURCE_LABEL}
              </Typography>
              <MultiSelect
                options={source}
                selectedValue={selectedSource}
                setSelectedValue={setSelectedSource}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .SOURCE_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS.NO_SOURCE
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .ORDER_STATUS_LABEL
                }
              </Typography>
              <MultiSelect
                options={orderStatus}
                selectedValue={selectedOrder}
                setSelectedValue={setSelectedOrder}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .ORDER_STATUS_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .NO_ORDER_STATUS
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .PAYMENT_STATUS_LABEL
                }
              </Typography>
              <MultiSelect
                options={paymentStatus}
                selectedValue={selectedPayment}
                setSelectedValue={setSelectedPayment}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .PAYMENT_STATUS_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                    .NO_PAYMENT_STATUS
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="mailedDate">
              <Box className="selectDate">
                <InputLabel className="dateLabel">
                  {
                    MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                      .CREATED_DATE_LABEL
                  }
                </InputLabel>
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDatepickerOpen.created ? "#ED5C2F" : "inherit",
                    cursor: "pointer",
                    position: "absolute",
                    top: "43.5px",
                    left: "378px",
                    zIndex: "1",
                  }}
                />
                <DatePicker
                  onCalendarClose={() =>
                    setIsDatepickerOpen((prevState) => ({
                      ...prevState,
                      created: false,
                    }))
                  }
                  selectsRange={true}
                  startDate={startDateCreated}
                  endDate={endDateCreated}
                  minDate={null}
                  onChange={(update) => {
                    dateProtector(update);
                    setCreatedDate(update);
                  }}
                  onChangeRaw={(e) =>
                    handleRawChangeForDatePicker(e, setCreatedDate)
                  }
                  onFocus={() =>
                    setIsDatepickerOpen((prevState) => ({
                      ...prevState,
                      created: true,
                    }))
                  }
                  onBlur={(e) => {
                    if (e.target.value == "") {
                      setCreatedDate([null, null]);
                    }
                    setIsDatepickerOpen((prevState) => ({
                      ...prevState,
                      created: false,
                    }));
                  }}
                  placeholderText={
                    MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                      .DATE_PLACEHOLDER
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="mailedDate">
              <Box className="selectDate">
                <InputLabel className="dateLabel">
                  {
                    MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                      .SCHEDULED_DATE_LABEL
                  }
                </InputLabel>
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDatepickerOpen.scheduled ? "#ED5C2F" : "inherit",
                  }}
                />
                <DatePicker
                  onCalendarClose={() =>
                    setIsDatepickerOpen((prevState) => ({
                      ...prevState,
                      scheduled: false,
                    }))
                  }
                  selectsRange={true}
                  startDate={startDateScheduled}
                  endDate={endDateScheduled}
                  onChange={(update) => {
                    dateProtector(update);
                    setScheduleDate(update);
                  }}
                  minDate={null}
                  onChangeRaw={(e) =>
                    handleRawChangeForDatePicker(e, setScheduleDate)
                  }
                  onFocus={() =>
                    setIsDatepickerOpen((prevState) => ({
                      ...prevState,
                      scheduled: true,
                    }))
                  }
                  onBlur={(e) => {
                    if (e.target.value == "") {
                      setScheduleDate([null, null]);
                    }
                    setIsDatepickerOpen((prevState) => ({
                      ...prevState,
                      scheduled: false,
                    }));
                  }}
                  placeholderText={
                    MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS
                      .DATE_PLACEHOLDER
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="actionBtnsUser">
        <Button onClick={() => clearFilters()}>
          {MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS.CANCEL_BUTTON}
        </Button>
        <Button onClick={applyFilters}>
          {MESSAGES.ADMIN_DASHBOARD.ORDERS.ADVANCE_FILTERS.SUBMIT_BUTTON}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
});

export default AdvanceFilters;
