import React from "react";

// Third Party Libraries
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Utils
import { MESSAGES } from "../../../../utils/message";
import { balanceFormatter } from "../../../../utils/helperFunctions";

// MUI Components
import { Typography, Box } from "@mui/material";

// styles
import "./styles.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = (props) => {
  const { data } = props;

  const options = {
    responsive: true,
    pointRadius: 4,
    plugins: {
      legend: {
        display: true,
        onClick: !data?.labels?.length ? () => {} : undefined,
        position: "bottom",
        align: "end",
        labels: {
          padding: 30,
          usePointStyle: true,
          color: "#303030 !important",
          fontfamily: '"Inter", sans-serif',
        },
      },
      tooltip: {
        backgroundColor: "#FFF",
        titleColor: "#303030",
        usePointStyle: true,
        footerColor: "rgba(48, 48, 48, 0.50)",
        callbacks: {
          title: function (context) {
            let title =
              context[0].dataset.label === "Revenue"
                ? balanceFormatter(context[0].raw)
                : context[0].formattedValue || "";
            return title;
          },
          labelPointStyle: function (context) {
            return {
              // pointStyle: false,
            };
          },
          labelTextColor: function (context) {
            return context.dataset.backgroundColor;
          },
          label: function (context) {
            let label = context.dataset.label || "";
            return label;
          },
          footer: function (context) {
            let footer = context[0].label || "";
            return footer;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: !data?.labels?.length ? 100 : undefined,
        stepSize: 10,
        grid: {
          display: true, // Display horizontal grid lines
        },
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
  };

  return (
    <>
      <Box className="chartWrapper">
        <Line options={options} data={data} />
        {!data?.labels?.length && (
          <Box className="chartPlaceholder">
            <Typography>{MESSAGES.DASHBOARD.NO_CHART_DATA}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Chart;
