// Request util for api call
import request from "../../utils/request";

// Actions
import { SET_TAGS_DATA, SET_TAGS_LOADER } from "./action-types";
import { fetchContactLabels } from "./contacts-actions";

const fetchTags = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_TAGS_LOADER, payload: true });
        const tags = await request.get('contacts/labels', payload);
        dispatch({ type: SET_TAGS_DATA, payload: tags.data.data });
        return tags;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_TAGS_LOADER, payload: false });
    }
}

const createTag = (payload) => async (dispatch) => {
    try {
        const tag = await request.post('contacts/labels', payload);
        await dispatch(fetchTags());
        await dispatch(fetchContactLabels());
        return tag;
    } catch (error) {
        return error.response;
    }
}


const updateTag = (id, payload) => async (dispatch) => {
    try {
        const delTag = await request.put(`contacts/labels/${id}`, payload);
        await dispatch(fetchTags());
        return delTag;
    } catch (error) {
        return error.response;
    }
}

const deleteTag = (payload) => async (dispatch) => {
    try {
        const delTag = await request.delete(`contacts/labels/${payload}`,);
        await dispatch(fetchTags());
        return delTag;
    } catch (error) {
        return error.response;
    }
}


export { fetchTags, createTag, deleteTag, updateTag };
