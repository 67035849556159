import axios from 'axios';
import { clearStorage } from './local-storage';
import { getUserToken, getRefreshToken, setApiKeys } from './auth';
import Store from '../redux/store'
import { SET_LIMIT_MODAL, SET_DYNAMIC_LIMIT_MODAL } from '../redux/actions/action-types';
import { failure } from '../redux/actions/snackbar-actions';
import { MESSAGES } from "../utils/message"

const apiURL = `${import.meta.env.VITE_APP_BASE_URL}/api/v1/`;

const token = () => `Bearer ${getUserToken() || ''}`;
const refreshToken = () => `${getRefreshToken() || ''}`;

const request = {
  post: (endpoint, payload) => {
    const instance = axios.create({
      apiURL,
      headers: {
        Authorization: token(),
        refreshtoken: refreshToken(),
      },
    });
    requestInterceptors(instance);
    return instance.post(apiURL + endpoint, payload);
  },
  get: (endpoint, payload) => {
    const instance = axios.create({
      apiURL,
      headers: {
        Authorization: token(),
        refreshtoken: refreshToken(),
      },
      params: payload,
    });
    requestInterceptors(instance);
    return instance.get(apiURL + endpoint);
  },
  getOne: (endpoint, id) => {
    return this.get(endpoint, { id });
  },
  put: (endpoint, payload) => {
    const instance = axios.create({
      apiURL,
      headers: {
        Authorization: token(),
        refreshtoken: refreshToken(),
      },
    });
    requestInterceptors(instance);
    return instance.put(apiURL + endpoint, payload);
  },
  patch: (endpoint, payload) => {
    const instance = axios.create({
      apiURL,
      headers: {
        Authorization: token(),
        refreshtoken: refreshToken(),
      },
    });
    requestInterceptors(instance);
    return instance.patch(apiURL + endpoint, payload);
  },
  delete: (endpoint, payload) => {
    const instance = axios.create({
      apiURL,
      headers: {
        Authorization: token(),
        refreshtoken: refreshToken(),
      },
      params: payload,
    });
    requestInterceptors(instance);
    return instance.delete(apiURL + endpoint);
  },
};

// For handling all types of http requests/response
const requestInterceptors = (instance) => {
  instance.interceptors.response.use(
    function (response) {
      // Update token in localStorage
      if (response?.headers?.token && response?.headers?.refreshtoken) {
        setApiKeys(response.headers.token, response.headers.refreshtoken);
      }
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        // handle error: inform user, go to login, etc
        clearStorage();
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
        Store.dispatch(
          failure(error?.response?.data?.message || MESSAGES.GENERAL_ERROR)
        );
      } else if (error?.response?.config?.url?.includes('/contacts/bulk-actions') && error?.response?.status === 418) {
        setTimeout(() => Store.dispatch({
          type: SET_DYNAMIC_LIMIT_MODAL, payload: {
            status: true,
            title: MESSAGES.REQUEST.SUBSCRIPTION_PLAN_DENIED.TITLE,
            subHeading: MESSAGES.REQUEST.SUBSCRIPTION_PLAN_DENIED.SUBHEADING,
            message: MESSAGES.REQUEST.SUBSCRIPTION_PLAN_DENIED.MESSAGE,
          }
        }), 500);
        error.response.data.message = null;
        return Promise.reject(error);
      }
      else if (error?.response?.status === 418) {
        setTimeout(() => Store.dispatch({ type: SET_LIMIT_MODAL, payload: true }), 500);
        error.response.data.message = null;
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export default request;
