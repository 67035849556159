import React from "react";

// MUI Components
import { Box, CircularProgress } from "@mui/material";

// styles
import "./styles.scss";

const index = () => {
  return (
    <Box className="skeletonWrapper">
      <CircularProgress />
    </Box>
  );
};

export default index;
