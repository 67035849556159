import React, { useState, useEffect } from "react";

// Hooks
import { useSelector } from "react-redux";

// Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "../../../../utils/constants";
import { MESSAGES } from "../../../../utils/message";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Tooltip,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";

// Icons
import Edit from "../../../../assets/images/accounts/editnew.png";
import Assign from "../../../../assets/images/templates/template-copy.svg";
import Delete from "../../../../assets/images/accounts/delete.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LinkIcon from "@mui/icons-material/Link";

// Styles
import "./styles.scss";
import Loader from "../../../General/Loader";

const Table = (props) => {
  const {
    setEditModal,
    setDeleteModal,
    setAssociateTemplateModal,
    setSelectedTemplateCategory,
    pagination,
    updatePagination,
  } = props;
  const pageSizes = DEFAULT_PAGE_SIZES;
  const rows =
    useSelector((state) => state.adminTemplatesReducers.templateCategories) ||
    [];
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const to = useSelector((state) => state.adminTemplatesReducers.to);
  const from = useSelector((state) => state.adminTemplatesReducers.from);
  const lastPage = useSelector(
    (state) => state.adminTemplatesReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminTemplatesReducers.currentPage || 1
  );
  const rowsCount =
    useSelector((state) => state.adminTemplatesReducers.rowsCount) || 0;

  const perPage = useSelector(
    (state) =>
      state.adminTemplatesReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const isLoading = useSelector(
    (state) => state.adminTemplatesReducers.loading
  );

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const handleDelete = (row) => {
    setSelectedTemplateCategory(row);
    setDeleteModal(true);
  };

  const handleEdit = (row) => {
    setSelectedTemplateCategory(row);
    setTimeout(() => {
      setEditModal(true);
    }, 100);
  };

  const handleAssociation = (row) => {
    setSelectedTemplateCategory(row);
    setAssociateTemplateModal(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <>
          <Tooltip title={params.row.title}>
            <Box className="addressWrapper">
              <Typography>{params.row.title}</Typography>
            </Box>
          </Tooltip>
        </>
      ),
    },
    {
      field: "TemplatesCount",
      headerName: "No. of Templates",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <>
          <Tooltip title={params.row.totalTemplates}>
            <Box className="addressWrapper">
              <Typography>{params.row.totalTemplates}</Typography>
            </Box>
          </Tooltip>
        </>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 600,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <>
          <Tooltip title={params.row.description}>
            <Box className="addressWrapper">
              <Typography>{params.row.description || " -"}</Typography>
            </Box>
          </Tooltip>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <>
          <Box className="webhookTableActions">
            <Tooltip title="Associate Templates">
              <LinkIcon
                sx={{
                  color: "#545454",
                  transform: "rotate(30deg)"
                }}
                onClick={() => handleAssociation(params.row)}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <img
                src={Edit}
                height={20}
                alt="Edit"
                onClick={() => handleEdit(params.row)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <img
                src={Delete}
                alt="delete"
                onClick={() => handleDelete(params.row)}
              />
            </Tooltip>
          </Box>
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="adminCategoryWrapper"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSizeOptions={[20, 50, 100]}
        rowCount={rowsCount || 0}
        pagination={pagination}
        paginationMode="server"
        className="adminCategoryGrid"
        hideFooterSelectedRowCount
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        components={{
          NoRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.NO_TEMPLATE_CATEGORY}
            </Stack>
          ),
          LoadingOverlay: Loader
        }}
      />
      <Box className="paginationWrapper">
        <Pagination
          count={lastPage}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />
        <Select
          className={
            perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
          }
          value={perPage}
        >
          {pageSizes.map((pageSize) => {
            return (
              <MenuItem
                key={pageSize}
                value={pageSize}
                onClick={() => {
                  updatePagination({
                    page: 0,
                    pageSize: pageSize,
                  });
                }}
              >
                {pageSize}
              </MenuItem>
            );
          })}
        </Select>
        <Typography>
          Showing {rowsCount ? from : 0} to{" "}
          {rowsCount < to ? rows?.length + from - 1 : to} of {rowsCount} results
        </Typography>
      </Box>
    </div>
  );
};

export default Table;
