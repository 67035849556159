// Request util for api call
import request from "../../utils/request";

// Actions
import { SET_WEBHOOKS_DATA, SET_WEBHOOKS_EVENTS_DATA, SET_WEBHOOKS_LOADER } from "./action-types";

const fetchWebhooks = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_WEBHOOKS_LOADER, payload: true });
        const webhooks = await request.get('/webhooks', payload);
        dispatch({ type: SET_WEBHOOKS_DATA, payload: webhooks.data.data });
        return apiKeys;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_WEBHOOKS_LOADER, payload: false });
    }
}

const fetchOneWebhook = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_WEBHOOKS_LOADER, payload: true });
        const webhook = await request.get(`/webhooks/${payload}`);
        return webhook;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_WEBHOOKS_LOADER, payload: false });
    }
}

const fetchWebhookEvents = () => async (dispatch) => {
    try {
        const events = await request.get('/webhooks/events');
        dispatch({ type: SET_WEBHOOKS_EVENTS_DATA, payload: events.data.data });
        return events;
    } catch (error) {
        return error.response;
    }
}

const deleteWebhook = (payload) => async (dispatch) => {
    try {
        const deleteWebhook = await request.delete(`/webhooks/${payload}`);
        await dispatch(fetchWebhooks());
        return deleteWebhook;
    } catch (error) {
        return error.response;
    }
}

const createWebhook = (payload) => async (dispatch) => {
    try {
        const create = await request.post('webhooks', payload);
        await dispatch(fetchWebhooks());
        return create;
    } catch (error) {
        return error.response;
    }
}


const updateWebhook = (payload) => async (dispatch) => {
    try {
        const update = await request.put(`webhooks/${payload.id}`, payload);
        await dispatch(fetchWebhooks());
        return update;
    } catch (error) {
        return error.response;
    }
}

const updateWebhookStatus = (payload) => async (dispatch) => {
    try {
        const update = await request.post(`/webhooks/status/${payload.id}`, payload);
        await dispatch(fetchWebhooks());
        return update;
    } catch (error) {
        return error.response;
    }
}

const fetchWebhookLogs = (payload) => async () => {
    try {
        const logs = await request.get(`/webhooks/logs/${payload}`);
        return logs;
    } catch (error) {
        return error.response;
    }
}

export { fetchWebhooks, deleteWebhook, createWebhook, updateWebhook, updateWebhookStatus, fetchWebhookEvents, fetchOneWebhook, fetchWebhookLogs };
