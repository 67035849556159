import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

// Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  CLEAR_ADMIN_USERS_FILTERS,
  SET_ADMIN_USERS_FILTERS,
} from "../../../../redux/actions/action-types.js";
import { failure } from "../../../../redux/actions/snackbar-actions";

// Utils
import { USER_ROLES } from "../../../../utils/constants.js";
import { MESSAGES } from "../../../../utils/message";
import {
  dateProtector,
  handleRawChangeForDatePicker,
} from "../../../../utils/date-format.js";

// Components
import MultiSelect from "../../../General/MultiSelect";

// Mui components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Grid, InputLabel } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// images
import Close from "../../../../assets/images/modal/modal-cross.jsx";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AdvanceFilters = forwardRef((props, ref) => {
  const { handleCloseFilter, open, updatePagination } = props;
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [signUpDate, setSignUpDate] = useState([null, null]);
  const [startDate, endDate] = signUpDate;

  const dispatch = useDispatch();

  const companies = useSelector(
    (state) => state.adminUsersReducers.filterslabels.companies
  );

  const plans = useSelector(
    (state) => state.adminUsersReducers.filterslabels.plans
  );

  const perPage = useSelector(
    (state) => state.adminUsersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const filters = useSelector((state) => state.adminUsersReducers.filters);

  const onClose = () => {
    clearFilters(true);
  };

  const clearFilters = (persist = false) => {
    setSelectedPlan([]);
    setSelectedRole([]);
    setSelectedCompany([]);
    setSignUpDate([null, null]);
    !persist ? dispatch({ type: CLEAR_ADMIN_USERS_FILTERS }) : undefined;
    handleCloseFilter();
  };

  const applyFilters = () => {
    const dateRange =
      startDate && endDate
        ? {
            startDate: startDate.toISOString().slice(0, 10),
            endDate: endDate.toISOString().slice(0, 10),
          }
        : null;

    if (startDate && endDate === null) {
      dispatch(failure(MESSAGES.CONTACTS.END_DATE_REQUIRED));
      return;
    }

    let payload = {
      applyFilters: true,
      filters: {
        plan: plans
          .filter((plan) => selectedPlan.includes(plan.title))
          .map((plan) => plan.value),
        role: USER_ROLES.filter((role) =>
          selectedRole.includes(role.title)
        ).map((role) => role.value),
        companyName: selectedCompany,
        signUpDate: dateRange,
      },
    };

    const filtersWithValues = Object.keys(payload.filters).filter((key) => {
      const value = payload.filters[key];
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });

    payload.filterSize = filtersWithValues.length;

    updatePagination({
      pageSize: perPage,
      page: 1,
    });

    if (filtersWithValues.length <= 0) {
      dispatch(failure(MESSAGES.ORDERS.FILTERS_REQUIRED));
      return;
    }

    dispatch({ type: SET_ADMIN_USERS_FILTERS, payload: payload });
    onClose();
  };

  useImperativeHandle(ref, () => ({
    clearFilters() {
      clearFilters();
    },
  }));

  useEffect(() => {
    if (Object.values(filters).length) {
      setSelectedPlan(
        plans
          .filter((plan) => filters.plan.includes(plan.value))
          .map((plan) => plan.title)
      );
      setSelectedRole(
        USER_ROLES.filter((role) => filters.role.includes(role.value)).map(
          (role) => role.title
        )
      );
      setSelectedCompany(filters.companyName);
      filters.signUpDate
        ? setSignUpDate([
            new Date(filters?.signUpDate?.startDate),
            new Date(filters?.signUpDate?.endDate),
          ])
        : undefined;
    }
  }, [open]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="userfilterModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        {MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.HEADING}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent className="filterModalContent">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.PLAN_LABEL}
              </Typography>
              <MultiSelect
                options={plans}
                selectedValue={selectedPlan}
                setSelectedValue={setSelectedPlan}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS
                    .PLAN_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.NO_PLAN
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="mailedDate">
              <Box className="selectDate">
                <InputLabel className="dateLabel">
                  {
                    MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS
                      .SIGN_UP_DATE_LABEL
                  }
                </InputLabel>
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                    cursor: "pointer",
                    position: "absolute",
                    top: "43.5px",
                    left: "378px",
                    zIndex: "1",
                  }}
                />
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    dateProtector(update);
                    setSignUpDate(update);
                  }}
                  minDate={null}
                  onChangeRaw={(e) =>
                    handleRawChangeForDatePicker(e, setSignUpDate)
                  }
                  onFocus={() => setIsDatepickerOpen(true)}
                  onBlur={(e) => {
                    if (e.target.value == "") {
                      setSignUpDate([null, null]);
                    }
                    setIsDatepickerOpen(false);
                  }}
                  placeholderText={
                    MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS
                      .DATE_PLACEHOLDER
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.ROLE_LABEL}
              </Typography>
              <MultiSelect
                options={USER_ROLES}
                selectedValue={selectedRole}
                setSelectedValue={setSelectedRole}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS
                    .ROLE_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.NO_ROLE
                }
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.COMPANY_LABEL}
              </Typography>
              <MultiSelect
                options={companies}
                selectedValue={selectedCompany}
                setSelectedValue={setSelectedCompany}
                placeHolderText={
                  MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS
                    .COMPANY_PLACEHOLDER
                }
                noOptionText={
                  MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.NO_COMPANY
                }
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button onClick={() => clearFilters()}>
          {MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.CANCEL_BUTTON}
        </Button>
        <Button onClick={applyFilters}>
          {MESSAGES.ADMIN_DASHBOARD.USERS.ADVANCE_FILTERS.SUBMIT_BUTTON}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
});

export default AdvanceFilters;
