import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Utils
import { MESSAGES } from "../../../../utils/message";
import request from "../../../../utils/request";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";
import { PASSWORD_REGEX } from "../../../../utils/constants";

// Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
  Box,
  Input,
  InputAdornment,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

// icons
import crossicon from "../../../../assets/images/modal/crossIcon.svg";

// Icons from Material icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ChangePasswordModal = (props) => {
  const { openModal, handleCloseModal } = props;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required(MESSAGES.AUTH.PASSWORD_REQUIRED),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, MESSAGES.AUTH.INVALID_PASSWORD)
      .required(MESSAGES.AUTH.INVALID_PASSWORD),
    confrimPassword: yup
      .string()
      .required(MESSAGES.AUTH.PASSWORD_MATCH_ERROR)
      .oneOf([yup.ref("password")], MESSAGES.AUTH.PASSWORD_MATCH_ERROR),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confrimPassword: "",
    },
    validationSchema: validationSchema,
  });

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    formik.resetForm();
    resetValidation();
  };

  const changePassword = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const payload = {
        current: formik.values.oldPassword,
        password: formik.values.password.trim(),
      };
      setLoading(true);
      const response = await request.post("users/update-password", payload);
      dispatch(success(response.data.message));
      formik.resetForm();
      handleCloseModal();
    } catch (error) {
      dispatch(
        failure(
          error.response?.data?.message ||
            error?.data?.message ||
            MESSAGES.GENERAL_ERROR
        )
      );
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.oldPassword = false;
    formik.touched.password = false;
    formik.touched.confrimPassword = false;
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  }, [openModal]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="changePasswordModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={crossicon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <form className="updateFormWrapper" onSubmit={changePassword}>
            <Box className="exportHeader">
              <Typography gutterBottom variant="h4">
                {MESSAGES.ACCOUNT.PROFILE.CHANGE_PASSWORD.TITLE}
              </Typography>
            </Box>
            <Box className="inputsWrapper">
              <InputLabel htmlFor="Old Password*" className="inputLabel">
                {MESSAGES.ACCOUNT.PROFILE.CHANGE_PASSWORD.OLD_PASSWORD_LABEL}
              </InputLabel>
              <Input
                placeholder="Your Old Password"
                name="oldPassword"
                type={showOldPassword ? "text" : "password"}
                fullWidth
                onChange={formik.handleChange}
                className={
                  formik.errors.oldPassword && formik.touched.oldPassword
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.oldPassword.trim()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowOldPassword(!showOldPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className="passwordIconBtn"
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.oldPassword && formik.errors.oldPassword ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.oldPassword}
                </Typography>
              ) : null}

              <InputLabel
                htmlFor="New Password*"
                className="inputLabel"
                sx={{ marginTop: "22px" }}
              >
                {MESSAGES.ACCOUNT.PROFILE.CHANGE_PASSWORD.NEW_PASSWORD_LABEL}
              </InputLabel>
              <Input
                placeholder="Your New Password"
                fullWidth
                name="password"
                type={showNewPassword ? "text" : "password"}
                onChange={formik.handleChange}
                className={
                  formik.errors.password && formik.touched.password
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.password.trim()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowNewPassword(!showNewPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className="passwordIconBtn"
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.password}
                </Typography>
              ) : (
                <Typography className="passwordMessage">
                  {MESSAGES.AUTH.INVALID_PASSWORD}
                </Typography>
              )}
              <InputLabel
                htmlFor="Confirm Password*"
                className="inputLabel"
                sx={{ marginTop: "22px" }}
              >
                {
                  MESSAGES.ACCOUNT.PROFILE.CHANGE_PASSWORD
                    .CONFIRM_PASSWORD_LABEL
                }
              </InputLabel>
              <Input
                placeholder="Your New Password"
                fullWidth
                name="confrimPassword"
                type={showConfirmPassword ? "text" : "password"}
                onChange={formik.handleChange}
                className={
                  formik.errors.confrimPassword &&
                  formik.touched.confrimPassword
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.confrimPassword.trim()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className="passwordIconBtn"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.confrimPassword &&
                formik.errors.confrimPassword && (
                  <Typography className="errorMessage">
                    <sup>*</sup>
                    {formik.errors.confrimPassword}
                  </Typography>
                )}
            </Box>
            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.ACCOUNT.CANCEL_BUTTON}
              </Button>
              <Button
                type="submit"
                className="btnSave"
                onClick={() => validateForm(formik)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ChangePasswordModal;
