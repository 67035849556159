import React from "react";
// MuiComponents
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// styles
import "./styles.scss";

const ProgressBar = (props) => {
  return (
    <Box className="progressBarWrapper">
      <Box sx={{ width: "100%", minWidth: "565px", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box className="progressValue" sx={{ minWidth: 35 }}>
        <Typography>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
