// Request util for api call
import request from "../../utils/request";

// Actions
import { SET_API_KEYS_DATA, SET_API_KEYS_LOADER } from "./action-types";

const fetchApiKeys = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_API_KEYS_LOADER, payload: true });
        const apiKeys = await request.get('api-keys', payload);
        dispatch({ type: SET_API_KEYS_DATA, payload: apiKeys.data.data });
        return apiKeys;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_API_KEYS_LOADER, payload: false });
    }
}

const createApiKey = (payload) => async (dispatch) => {
    try {
        const apiKey = await request.post('api-keys', payload);
        await dispatch(fetchApiKeys());
        return apiKey;
    } catch (error) {
        return error.response;
    }
}

const deleteApiKey = (payload) => async (dispatch) => {
    try {
        const deleteKey = await request.delete(`api-keys/${payload}`,);
        await dispatch(fetchApiKeys());
        return deleteKey;
    } catch (error) {
        return error.response;
    }
}

const rotateApiKey = (payload) => async (dispatch) => {
    try {
        const rotateKeys = await request.patch(`api-keys/${payload.selectedApi}`, payload);
        await dispatch(fetchApiKeys());
        return rotateKeys;
    } catch (error) {
        return error.response;
    }
}

export { fetchApiKeys, createApiKey, deleteApiKey, rotateApiKey };
