import React from "react";

//Utils
import { MESSAGES } from "../../../utils/message";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// image
import Delete from "../../../assets/images/contacts/delete.svg";
import Close from "../../../assets/images/modal/modal-cross.jsx";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteModal = (props) => {
  const { handleClose, open, handleExport, message, header, subject, loading } =
    props;
  const blurredStyle = {
    filter: "blur(2px)", // Adjust the blur value as needed
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="exportModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <img src={Delete} alt="delete" height={70} width={70} />
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {header}
            </Typography>
            <Typography gutterBottom>{message}</Typography>
            <Typography className="confirm" gutterBottom variant="p">
              {subject}
            </Typography>
          </Box>
          <Box className="exportModalBtns">
            <Button onClick={handleClose}>
              {MESSAGES.CONTACTS.DELETE.CANCEL_BUTTON}
            </Button>
            <Button onClick={handleExport}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.CONTACTS.DELETE.SUBMIT_BUTTON
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default DeleteModal;
