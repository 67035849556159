import React from "react";

const overview = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1182 18.6716C12.9545 18.6716 12.8455 18.7875 12.8455 18.9613V19.28H0.545455V4.35945H3.3C3.16364 4.823 3.19091 5.14169 3.19091 5.46038C3.19091 5.63421 3.3 5.7501 3.46364 5.7501H9.95455C10.1182 5.7501 10.2273 5.63421 10.2273 5.46038C10.2273 5.14169 10.2545 4.823 10.1182 4.35945H12.8727V6.35851C12.8727 6.53234 12.9818 6.64823 13.1455 6.64823C13.3091 6.64823 13.4182 6.53234 13.4182 6.35851V4.06973C13.4182 3.8959 13.3091 3.78001 13.1455 3.78001H9.87273C9.6 3.25851 9.16364 2.85291 8.61818 2.56319C8.56364 1.54917 7.71818 0.708984 6.70909 0.708984C5.7 0.708984 4.85455 1.5202 4.8 2.56319C4.25455 2.82393 3.84545 3.25851 3.54545 3.78001H0.272727C0.109091 3.78001 0 3.8959 0 4.06973V19.5697C0 19.7435 0.109091 19.8594 0.272727 19.8594H13.1182C13.2818 19.8594 13.3909 19.7435 13.3909 19.5697V18.9613C13.3909 18.8164 13.2545 18.6716 13.1182 18.6716ZM5.15455 3.02674C5.26364 2.96879 5.34545 2.85291 5.31818 2.67908C5.31818 1.92581 5.91818 1.31739 6.68182 1.31739C7.44545 1.31739 8.04545 1.92581 8.04545 2.67908C8.01818 2.85291 8.1 2.96879 8.20909 3.02674C9.08182 3.3744 9.62727 4.21459 9.65455 5.17066H3.73636C3.73636 4.24356 4.30909 3.40337 5.15455 3.02674Z"
        fill=""
      />
      <path
        d="M6.6818 4.12895C7.25453 4.12895 7.74544 3.63643 7.74544 2.99905C7.74544 2.36166 7.2818 1.86914 6.6818 1.86914C6.10907 1.86914 5.61816 2.36166 5.61816 2.99905C5.61816 3.63643 6.10907 4.12895 6.6818 4.12895ZM6.6818 2.44858C6.95453 2.44858 7.19998 2.68035 7.19998 2.99905C7.19998 3.31774 6.9818 3.54951 6.6818 3.54951C6.40907 3.54951 6.16362 3.31774 6.16362 2.99905C6.16362 2.68035 6.40907 2.44858 6.6818 2.44858Z"
        fill=""
      />
      <path
        d="M4.00913 7.48894C4.00913 7.31511 3.90004 7.19922 3.7364 7.19922H1.88186C1.71822 7.19922 1.60913 7.31511 1.60913 7.48894V9.60389C1.60913 9.77772 1.71822 9.89361 1.88186 9.89361H3.7364C3.90004 9.89361 4.00913 9.77772 4.00913 9.60389V7.48894ZM3.46368 9.2852H2.15459V7.74969H3.46368V9.2852Z"
        fill=""
      />
      <path
        d="M4.00913 11.3698C4.00913 11.196 3.90004 11.0801 3.7364 11.0801H1.88186C1.71822 11.0801 1.60913 11.196 1.60913 11.3698V13.4847C1.60913 13.6586 1.71822 13.7745 1.88186 13.7745H3.7364C3.90004 13.7745 4.00913 13.6586 4.00913 13.4847V11.3698ZM3.46368 13.195H2.15459V11.6595H3.46368V13.195Z"
        fill=""
      />
      <path
        d="M3.7364 14.9922H1.88186C1.71822 14.9922 1.60913 15.1081 1.60913 15.2819V17.3969C1.60913 17.5707 1.71822 17.6866 1.88186 17.6866H3.7364C3.90004 17.6866 4.00913 17.5707 4.00913 17.3969V15.2819C4.00913 15.1081 3.87277 14.9922 3.7364 14.9922ZM3.46368 17.1071H2.15459V15.5716H3.46368V17.1071Z"
        fill=""
      />
      <path
        d="M17.8364 10.0093L12.8727 7.14111C12.7909 7.08317 12.6818 7.08317 12.6273 7.14111C12.3 7.31495 8.34547 9.57476 7.69093 9.95139C7.60911 10.0093 7.55457 10.1252 7.55457 10.2121V14.8476C7.55457 14.9346 7.60911 15.0504 7.69093 15.0794L12.6273 18.3822C12.7091 18.4402 12.8182 18.4402 12.9 18.3822L17.8637 15.5719C17.9455 15.514 18 15.4271 18 15.3112V10.2701C18 10.1832 17.9455 10.0673 17.8364 10.0093ZM12.7637 7.72055L17.1818 10.2701L16.0364 10.9364L11.6182 8.35793L12.7637 7.72055ZM14.9455 11.7187L10.3637 9.08223L11.0182 8.7056L15.7364 11.4579V12.8776L15.0818 13.2542V11.9794C15.0818 11.8635 15.0273 11.7477 14.9455 11.7187ZM9.79093 9.40092L14.2364 11.9794L12.7637 12.8486L8.37275 10.2121L9.79093 9.40092ZM8.07275 14.6448V10.6757L12.4637 13.3121V17.6L8.07275 14.6448ZM13.0364 17.6289V13.3411L14.5364 12.4719V13.7467C14.5364 13.8626 14.5909 13.9495 14.6727 14.0075C14.7546 14.0654 14.8637 14.0654 14.9455 14.0075L16.1455 13.3121C16.2273 13.2542 16.2818 13.1673 16.2818 13.0514V11.4579L17.4546 10.7916V15.1663L13.0364 17.6289Z"
        fill=""
      />
      <path
        d="M8.94545 8.53191C8.94545 8.35808 8.83636 8.24219 8.67273 8.24219H4.77273C4.60909 8.24219 4.5 8.35808 4.5 8.53191C4.5 8.70574 4.60909 8.82163 4.77273 8.82163H8.67273C8.80909 8.82163 8.94545 8.70574 8.94545 8.53191Z"
        fill=""
      />
      <path
        d="M7.00902 12.1543H4.74538C4.58175 12.1543 4.47266 12.2702 4.47266 12.444C4.47266 12.6178 4.58175 12.7337 4.74538 12.7337H7.00902C7.17266 12.7337 7.28175 12.6178 7.28175 12.444C7.28175 12.2702 7.14538 12.1543 7.00902 12.1543Z"
        fill=""
      />
      <path
        d="M4.30908 16.3249C4.30908 16.4987 4.41817 16.6146 4.58181 16.6146H8.15454C8.31817 16.6146 8.42726 16.4987 8.42726 16.3249C8.42726 16.151 8.31817 16.0352 8.15454 16.0352H4.58181C4.44545 16.0352 4.30908 16.18 4.30908 16.3249Z"
        fill=""
      />
    </svg>
  );
};

export default overview;
