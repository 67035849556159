import { SET_SUCCESS_SNACKBAR, SET_ERROR_SNACKBAR, CLEAR_SNACKBAR } from "./action-types"

const success = (message, heading = '') => (dispatch) => {
    dispatch({ type: SET_SUCCESS_SNACKBAR, payload: { message, heading } });
}

const failure = (message, heading = '') => (dispatch) => {
    dispatch({ type: SET_ERROR_SNACKBAR, payload: { message, heading } });
}

const closeSnackbar = () => (dispatch) => {
    dispatch({ type: CLEAR_SNACKBAR });
}

export { success, failure, closeSnackbar }
