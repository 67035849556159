import React from "react";

// MUI Components
import { Box, Grid, Typography } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";

//icons
import Profile from "../../../assets/images/sidebar/profile.jsx";
import Wallet from "../../../assets/images/sidebar/wallet.jsx";
import Plan from "../../../assets/images/sidebar/plan.jsx";
import General from "../../../assets/images/sidebar/general.jsx";
import Tags from "../../../assets/images/sidebar/tags.jsx";
import Fields from "../../../assets/images/sidebar/custom-fields.jsx";
import Keys from "../../../assets/images/sidebar/api-keys.jsx";
import Logs from "../../../assets/images/sidebar/api-logs.jsx";
import Hooks from "../../../assets/images/sidebar/webhook.jsx";
import CustomField from "../../../assets/images/sidebar/customField.jsx";
import Overview from "../../../assets/images/sidebar/overview.jsx";
import Contact from "../../../assets/images/sidebar/contact.jsx";

// styles
import "./styles.scss";

const ACCOUNT_SIDE_BAR_LIST = {
  title: "Account",
  list: [
    {
      id: 1,
      name: "Profile",
      icon: <Profile />,
      link: "account/profile",
    },
    {
      id: 2,
      name: "Wallet",
      icon: <Wallet />,
      link: "account/wallet",
    },
    {
      id: 3,
      name: "Users",
      icon: <Profile />,
      link: "account/users",
    },
    {
      id: 4,
      name: "Plan",
      icon: <Plan />,
      link: "account/plan",
    },
  ].filter((item) => item !== false),
};

const SETTINGS_SIDE_BAR_LIST = {
  title: "Settings",
  list: [
    {
      id: 1,
      name: "General",
      icon: <General />,
      link: "settings/general",
    },
    {
      id: 2,
      name: "Tags",
      icon: <Tags />,
      link: "settings/tags",
    },
    {
      id: 3,
      name: "Custom Fields",
      icon: <CustomField />,
      link: "settings/custom-fields",
    },
    {
      id: 4,
      name: "API Keys",
      icon: <Keys />,
      link: "settings/api-keys",
    },
    {
      id: 5,
      name: "API Logs",
      icon: <Logs />,
      link: "/",
    },
    {
      id: 6,
      name: "Webhooks",
      icon: <Hooks />,
      link: "settings/webhooks",
    },
  ].filter(
    (item) =>
      item.id === 4 ||
      item.id === 1 ||
      item.id === 2 ||
      item.id === 3 ||
      item.id === 6
  ),
};
const ORDER_DETAIL_SIDE_BAR_LIST = {
  title: "Order ID",
  list: [
    {
      id: 1,
      name: "Overview",
      icon: <Overview />,
      link: "orders/detail/overview",
    },
    {
      id: 2,
      name: "Contacts",
      icon: <Contact />,
      link: "orders/detail/contacts",
    },
  ].filter((item) => item !== false),
};

const SideBar = ({ isSidebarOpen, name, isImpersonatedMode }) => {
  const sideBarTransition = isSidebarOpen ? "sideBar opened" : "sideBar";
  let currentSideBar;
  switch (name) {
    case "account":
      currentSideBar = ACCOUNT_SIDE_BAR_LIST;
      break;
    case "order":
      currentSideBar = ORDER_DETAIL_SIDE_BAR_LIST;
      break;
    default:
      currentSideBar = SETTINGS_SIDE_BAR_LIST;
  }

  const { id } = useParams();

  return (
    <>
      <Box
        className={
          isImpersonatedMode
            ? `sideBar ${sideBarTransition} impersonate-sidebar`
            : `sideBar ${sideBarTransition}`
        }
      >
        <Typography>{currentSideBar.title}</Typography>
        {currentSideBar.title !== "Settings" && id && (
          <Typography className="order_id">{id}</Typography>
        )}
        <Box className="sideBarLinks">
          {currentSideBar.list.map((item) => {
            return (
              <NavLink
                key={item.id}
                className={({ isActive }) => (isActive ? "activeItem" : "")}
                to={id ? `${item.link}/${id}` : item.link}
              >
                <Box className="linkWrapper">
                  <Box className="linkIcon">{item.icon}</Box>
                  <Box>
                    <Typography className="linkText">{item.name}</Typography>
                  </Box>
                </Box>
              </NavLink>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default SideBar;
