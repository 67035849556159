import React from "react";

// MUI Components
import { Box, Skeleton } from "@mui/material";

// styles
import "./styles.scss";

const index = () => {
  return (
    <Box className="deliverySkeleton">
      <Box className="skeletonBoxes">
        {["1", "2", "3", "4", "5"].map(() => {
          return <Skeleton width={104} height={130} variant="rectangular" />;
        })}
      </Box>
      <Box className="skeletonBoxes">
        {["1", "2", "3", "4"].map(() => {
          return <Skeleton width={104} height={130} variant="rectangular" />;
        })}
      </Box>
    </Box>
  );
};

export default index;
