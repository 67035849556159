import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const useDynamicPageTitle = () => {
    const location = useLocation();

    useEffect(() => {
        // Set the title based on the current route
        const pathName = location.pathname;
        let routeName = pathName.split("/");
        // Take the last part after the last '/'
        routeName = routeName.length > 2 ? routeName[1] : routeName.pop();
        routeName = routeName
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        document.title = routeName;
    }, [location]);
};

export { useDynamicPageTitle };
