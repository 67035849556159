
export let realTimeValidation = false;

export const validateForm = (formik) => {
    formik.validateForm();
    if (Object.keys(formik.errors).length > 0) {
        let fields = Object.keys(formik.errors);
        fields.forEach((fields) => {
            formik.touched[fields] = true;
        });
        realTimeValidation = true;
        return false;
    }
    return true;
};

export const resetValidation = () => {
    realTimeValidation = false;
};