import React from "react";

const apilogs = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.12507 8.82324C3.42996 8.82324 2.86465 9.38855 2.86465 10.0837V12.3753C2.86465 12.8164 3.55215 12.8228 3.55215 12.3753V11.8024H4.69799V12.3753C4.69799 12.8215 5.38549 12.8201 5.38549 12.3753V10.0837C5.38549 9.38855 4.82018 8.82324 4.12507 8.82324ZM3.55215 11.1149V10.0837C3.55215 9.76766 3.80907 9.51074 4.12507 9.51074C4.44107 9.51074 4.69799 9.76766 4.69799 10.0837V11.1149H3.55215Z" />
      <path d="M8.59374 10.0837C8.59374 9.38855 8.02843 8.82324 7.33332 8.82324H6.41666C6.22688 8.82324 6.07291 8.97721 6.07291 9.16699V12.3753C6.07291 12.8211 6.7604 12.8203 6.76041 12.3753V11.3441H7.33332C8.02843 11.3441 8.59374 10.7788 8.59374 10.0837ZM7.33332 10.6566H6.76041V9.51074H7.33332C7.64932 9.51074 7.90624 9.76766 7.90624 10.0837C7.90624 10.3997 7.64932 10.6566 7.33332 10.6566Z" />
      <path d="M9.625 8.83412C9.45313 8.83276 9.28125 8.94227 9.28125 9.16667V12.3749C9.28125 12.8166 9.96875 12.8206 9.96875 12.375C9.96875 12.375 9.96875 9.1875 9.96875 9.16677C9.96875 8.94771 9.79688 8.83548 9.625 8.83412Z" />
      <path d="M20.1667 2.40625H1.83332C1.13821 2.40625 0.572906 2.97156 0.572906 3.66667V18.3333C0.572906 19.0284 1.13821 19.5938 1.83332 19.5938H20.1667C20.8618 19.5938 21.4271 19.0284 21.4271 18.3333V3.66667C21.4271 2.97156 20.8618 2.40625 20.1667 2.40625ZM1.83332 3.09375H20.1667C20.4827 3.09375 20.7396 3.35067 20.7396 3.66667V6.07292H1.26041V3.66667C1.26041 3.35067 1.51733 3.09375 1.83332 3.09375ZM20.1667 18.9063H1.83332C1.51733 18.9063 1.26041 18.6493 1.26041 18.3333V6.76042H20.7396V18.3333C20.7396 18.6493 20.4827 18.9063 20.1667 18.9063Z" />
      <path d="M18.5992 12.4344C18.9824 12.2131 19.114 11.7216 18.8929 11.3387C18.8929 11.3387 18.3046 10.3202 18.3034 10.3182C18.0873 9.94698 17.5796 9.80993 17.2077 10.0246C16.9066 10.1984 16.5166 9.97126 16.5166 9.62533C16.5166 9.18311 16.1567 8.82324 15.7145 8.82324H14.5356C14.0933 8.82324 13.7335 9.18308 13.7335 9.62527C13.7335 9.97372 13.3438 10.1983 13.0424 10.0246C12.6642 9.80657 12.1641 9.94113 11.9467 10.3182L11.3572 11.3387C11.1361 11.7216 11.2677 12.213 11.6508 12.4344C11.9539 12.6095 11.9523 13.0586 11.6508 13.2329C11.2677 13.4542 11.1361 13.9457 11.3572 14.3286C11.3572 14.3286 11.9459 15.3478 11.9467 15.3491C12.1644 15.726 12.6659 15.861 13.0424 15.6428C13.3447 15.4675 13.7335 15.6955 13.7335 16.042C13.7335 16.4842 14.0933 16.8441 14.5356 16.8441H15.7145C16.1567 16.8441 16.5166 16.4842 16.5166 16.042C16.5166 15.6927 16.9049 15.4682 17.2077 15.6427C17.5857 15.8606 18.0869 15.728 18.3034 15.3491L18.8929 14.3286C19.114 13.9457 18.9824 13.4543 18.5993 13.2329C18.2959 13.0577 18.297 12.6091 18.5992 12.4344ZM18.2555 13.8282C18.3101 13.86 18.3293 13.93 18.2976 13.9849L17.7081 15.0054C17.6763 15.0595 17.606 15.079 17.5514 15.0474C16.7985 14.6125 15.8291 15.1782 15.8291 16.042C15.8291 16.1051 15.7776 16.1566 15.7145 16.1566H14.5356C14.4725 16.1566 14.421 16.1051 14.421 16.042C14.4209 15.1758 13.4512 14.6121 12.6987 15.0474C12.6432 15.079 12.5738 15.0595 12.542 15.0054L11.9525 13.9849C11.9207 13.93 11.94 13.86 11.9946 13.8282C12.7442 13.3918 12.7467 12.2732 11.9946 11.8391C11.94 11.8073 11.9207 11.7373 11.9525 11.6825L12.542 10.6619C12.5738 10.6076 12.6431 10.5881 12.6987 10.6199C13.4523 11.056 14.421 10.4914 14.421 9.62533C14.421 9.56221 14.4725 9.51074 14.5356 9.51074H15.7145C15.7776 9.51074 15.8291 9.56221 15.8291 9.6253C15.8292 10.493 16.7986 11.055 17.5514 10.6199C17.606 10.5881 17.6763 10.6076 17.7081 10.6619L18.2976 11.6825C18.3293 11.7373 18.3101 11.8073 18.2555 11.8391C17.5091 12.2737 17.5095 13.3976 18.2555 13.8282Z" />
      <path d="M15.1249 11.1143C14.1774 11.1143 13.4062 11.8852 13.4062 12.833C13.4062 13.7808 14.1774 14.5518 15.1249 14.5518C16.0725 14.5518 16.8437 13.7808 16.8437 12.833C16.8437 11.8852 16.0725 11.1143 15.1249 11.1143ZM15.1249 13.8643C14.5565 13.8643 14.0937 13.4017 14.0937 12.833C14.0937 12.2643 14.5565 11.8018 15.1249 11.8018C15.6933 11.8018 16.1562 12.2643 16.1562 12.833C16.1562 13.4017 15.6933 13.8643 15.1249 13.8643Z" />
      <path d="M14.6667 4.92676H19.2499C19.6896 4.92676 19.6985 4.23926 19.25 4.23926C19.25 4.23926 14.701 4.23926 14.6669 4.23926C14.2285 4.23926 14.22 4.92675 14.6667 4.92676Z" />
      <path d="M2.74991 4.92676C2.93976 4.92676 3.09366 4.77286 3.09366 4.58301C3.09366 4.39316 2.93976 4.23926 2.74991 4.23926C2.56006 4.23926 2.40616 4.39316 2.40616 4.58301C2.40616 4.77286 2.56006 4.92676 2.74991 4.92676Z" />
      <path d="M4.12491 4.92676C4.31476 4.92676 4.46866 4.77286 4.46866 4.58301C4.46866 4.39316 4.31476 4.23926 4.12491 4.23926C3.93506 4.23926 3.78116 4.39316 3.78116 4.58301C3.78116 4.77286 3.93506 4.92676 4.12491 4.92676Z" />
      <path d="M5.49991 4.92676C5.68976 4.92676 5.84366 4.77286 5.84366 4.58301C5.84366 4.39316 5.68976 4.23926 5.49991 4.23926C5.31006 4.23926 5.15616 4.39316 5.15616 4.58301C5.15616 4.77286 5.31006 4.92676 5.49991 4.92676Z" />
      <path d="M4.82635 16.257L3.6944 15.125C3.6944 15.125 4.82054 13.9988 4.82644 13.993C5.12894 13.6902 4.65082 13.1964 4.34027 13.507L2.96527 14.882C2.83099 15.0162 2.83099 15.2338 2.96527 15.368C2.96527 15.368 4.33258 16.7353 4.34024 16.743C4.64446 17.0472 5.15063 16.5812 4.82635 16.257Z" />
      <path d="M8.49302 13.5069C8.18842 13.2024 7.69276 13.6788 8.00694 13.993L9.1389 15.125C9.1389 15.125 8.00694 16.2569 8.00685 16.257C7.70101 16.5631 8.18288 17.0531 8.49302 16.743L9.86802 15.368C10.0023 15.2337 10.0023 15.0162 9.86802 14.8819C9.86802 14.8819 8.50147 13.5154 8.49302 13.5069Z" />
      <path d="M6.9762 13.4453C6.81421 13.3875 6.61846 13.4313 6.54891 13.6413C6.54891 13.6413 5.64067 16.366 5.6323 16.3911C5.49282 16.8095 6.14334 17.0332 6.28439 16.6088C6.28439 16.6088 7.18957 13.8932 7.20094 13.8591C7.26643 13.6625 7.1382 13.503 6.9762 13.4453Z" />
    </svg>
  );
};

export default apilogs;
