import { setItem, getItem, removeItem } from "./local-storage";

export const setApiKeys = (apiKey, refreshToken) => {
    setItem('apiKey', apiKey);
    setItem('refreshToken', refreshToken);
};

export const setAdminApiKeys = (apiKey, refreshToken) => {
    setItem('adminApiKey', apiKey);
    setItem('AdminRefreshToken', refreshToken);
};

export const removeAdminApiKeys = () => {
    removeItem('adminApiKey');
    removeItem('AdminRefreshToken');
};

export const setResetTokenAndEmail = (token, email) => {
    setItem('reset-token', token);
    setItem('email', email);
};

export const setResetCodeAndToken = (code, token) => {
    setItem('reset-code', code);
    setItem('reset-token', token);
};

export const setDashboardMode = (mode) => {
    setItem('isAdminMode', mode);
};

export const setImpersonationMode = (mode) => {
    setItem('isImpersonated', mode);
};

export const removeImpersonationMode = () => {
    removeItem('isImpersonated');
}

export const getImpersonationMode = () => {
    return getItem('isImpersonated');
}

export const getUserToken = () => {
    return getItem("apiKey");
};

export const getAdminToken = () => {
    return getItem("adminApiKey");
};

export const getAdminRefreshToken = () => {
    return getItem("AdminRefreshToken");
};

export const getRefreshToken = () => {
    return getItem("refreshToken");
};

export const getResetToken = () => {
    return getItem("reset-token");
};

export const getResetCode = () => {
    return getItem("reset-code");
};

export const getUserEmail = () => {
    return getItem("email");
};

export const isAuthenticated = () => {
    return !!getUserToken();
};

export const getDashboardMode = () => {
    return getItem('isAdminMode');
};

export const setImpersonatedUser = (impersonateUser) => {
    try {
        setAdminApiKeys(getUserToken(), getRefreshToken());
        setApiKeys(impersonateUser.token, impersonateUser.refreshToken);
        setDashboardMode(false);
        setImpersonationMode(true);
        window.location.href = '/';
    } catch (error) {
        return error;
    }
}

export const revertImpersonatedUser = () => {
    try {
        setApiKeys(getAdminToken(), getAdminRefreshToken());
        removeAdminApiKeys();
        removeImpersonationMode();
        setDashboardMode(true);
        window.location.href = '/';
    } catch (error) {
        return error;
    }
}