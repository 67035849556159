import React from "react";

// MUI Components
import { Box, Grid, Typography } from "@mui/material";

// styles
import "./styles.scss";

const CurrentCard = (props) => {
  const { name, subTitle, value, image } = props;
  return (
    <Grid item lg={3} md={6} sm={6} xs={12}>
      <Box className="currentCardWrapper">
        <img src={image} alt="icon" />
        <Box className="currentCardContent">
          <Typography>{name}</Typography>
          <Typography>{subTitle}</Typography>
          <Typography>{value}</Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default CurrentCard;
