import request from '../../utils/request';
import {
  SET_DYNAMIC_FIELDS,
  SET_DYNAMIC_FIELD_VALUE,
  CLEAR_DYNAMIC_FIELDS,
  REMOVE_FROM_DYNAMIC_FIELDS,
  GET_PRODUCTS,
  GET_ALL_TEMPLATES,
  GET_ONE_TEMPLATE,
  TEMPLATE_LOADING,
  CLEAR_FIELDS,
  TEMPLATE_PAGINATION_CHANGE,
  TEMPLATE_SEARCH,
  CLEAR_ALL_TEMPLATE,
  SELECT_PRODUCT,
  SELECT_POSTCARD,
  CLEAR_TEMPLATE_FIELDS,
  GET_DYNAMIC_FIELDS_FROM_SERVER,
  LOAD_DATA_FROM_LOCAL_STORAGE,
  SET_PRODUCT_DETAILS,
} from './action-types';

/**
 * Retrieves a list of templates from a server using an HTTP GET request.
 *
 * @param {number} [page=1] - The page number of the templates to retrieve.
 * @param {number} [pageSize=10] - The number of templates per page.
 * @returns {Promise<void>} - A promise that resolves when the action is dispatched.
 */
const getAllTemplates =
  (
    page = 1,
    pageSize = 10,
    search = '',
    productTypes = '',
    creator = '',
    templateType = '',
    productIds=[],
    refresh = true,
    isShared = false,
  ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: TEMPLATE_PAGINATION_CHANGE,
          payload: { data: { page, pageSize, loading: true } },
        });
        const { data } = await request.get('templates', {
          page,
          pageSize,
          search,
          creator,
          templateType,
          productIds,
          isShared,
        });
        dispatch({
          type: GET_ALL_TEMPLATES,
          payload: { data: data.data, refresh },
        });
        dispatch({
          type: TEMPLATE_PAGINATION_CHANGE,
          payload: { data: { page, pageSize, loading: false } },
        });
      } catch (error) { }
    };

/**
 * Makes an HTTP GET request to the 'templates' endpoint and dispatches an action with the retrieved data.
 * @param {number} id - The ID of the template to retrieve.
 * @param {function} dispatch - A function used to dispatch actions to the Redux store.
 * @returns {void}
 */
const getOneTemplate =
  (id, type = 'edit') =>
    async (dispatch) => {
      try {
        const { data } = await request.get(`templates/${id}`);
        dispatch({ type: GET_ONE_TEMPLATE, payload: { data: data.data, type } });
        dispatch({ type: TEMPLATE_LOADING, payload: true });
      } catch (error) { }
    };

/**
 * Uploads a template using the provided template form data.
 *
 * @param {FormData} templateFormData - The form data containing the template files to upload.
 * @returns {Promise} - A promise that resolves with the response from the server.
 * @throws {Error} - If an error occurs during the upload process.
 */
const uploadTemplate = async (templateFormData) => {
  try {
    // Upload template files
    const response = await request.post('templates/upload', templateFormData);

    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Creates a template using the provided data.
 *
 * @param {object} data - The data needed to create the template.
 * @returns {Promise<object>} - A promise that resolves to the response from the successful request or the error response if an error occurs.
 */
const createTemplate = async (data) => {
  try {
    const response = await request.post('templates', data);

    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Update a template using the provided data.
 *
 * @param {object} data - The data needed to create the template.
 * @returns {Promise<object>} - A promise that resolves to the response from the successful request or the error response if an error occurs.
 */
const updateTemplate = async (id, data) => {
  try {
    const response = await request.patch(`templates/${id}`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Deletes a template by its ID.
 *
 * @param {string} id - The unique identifier of the template to delete.
 * @returns {Promise} A Promise that resolves to the response indicating
 *                    the success of the template deletion, or rejects
 *                    with an error response in case of failure.
 *
 * @throws {Error} If the request to delete the template fails.
 */
const deleteTemplate = async (id) => {
  try {
    const response = await request.delete(`templates/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Retrieves the view proof for a template with the given ID.
 *
 * @param {string} id - The ID of the template for which the view proof is requested.
 * @returns {Promise<object>} - The response object from the GET request, containing the view proof for the template with the given ID.
 * @throws {object} - The error response if there is an error.
 */
const getViewProof = async (id) => {
  try {
    const response = await request.get(`templates/${id}/view-proof`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getViewProofForOrder = async (payload) => {
  try {
    const response = await request.post('orders/view-proof', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getViewProofForAdminOrder = async (payload) => {
  try {
    const response = await request.post('admin/orders/view-proof', payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Duplicates a template by its ID.
 *
 * @param {string} id - The unique identifier of the template to duplicate.
 * @param {Object} data - Additional data to be sent with the duplicate request.
 * @returns {Promise} A Promise that resolves to the response containing information
 *                    about the duplicated template, or rejects with an error response
 *                    in case of failure.
 *
 * @throws {Error} If the request to duplicate the template fails.
 */

const doublicateTemplate = async (id, data) => {
  try {
    const response = await request.post(`templates/${id}/duplicate`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Fetches products and dispatches an action with the product data to the Redux store.
 *
 * @param {Function} dispatch - The dispatch function from the Redux store.
 * @returns {Promise} A Promise that resolves after dispatching the action,
 *                    or rejects if there is an error fetching the products.
 *
 * @throws {Error} If there is an error fetching the products.
 */
const getAllProducts = () => async (dispatch) => {
  try {
    const response = await request.get('/products/types');
    dispatch({
      type: GET_PRODUCTS,
      payload: { products: response.data.data },
    });
  } catch (error) { }
};

const getAllCustomFields = () => async (dispatch) => {
  try {
    const response = await request.get('custom-fields');
    if (response.status === 200) {
      const data = response.data.data.reduce((acc, curr) => {
        acc[curr.key.replace(/{{|}}/g, '')] = curr;
        return acc;
      }, {});

      dispatch({ type: GET_DYNAMIC_FIELDS_FROM_SERVER, payload: { data } });
    }
  } catch (error) {
    return error.response;
  }
};

const getProductDetails = (payload) => async (dispatch) => {
  try {
    const response = await request.post('/products/template/details', payload);
    if (response.status === 200) {
      dispatch({ type: SET_PRODUCT_DETAILS, payload: response.data.data });
    }
  } catch (error) {
    return error.response;
  }
}

const loadFormDataToStore = (data) => (dispatch) => {
  try {
    const parsedData = JSON.parse(data);
    dispatch({
      type: LOAD_DATA_FROM_LOCAL_STORAGE,
      payload: { data: parsedData },
    });
  } catch (error) { }
};

const htmlViewProof = async (data) => {
  try {
    const response = await request.post('templates/html/view-proof', data);
    return response;
  } catch (error) {
    return error.response;
  }
};

const downloadProof = async (data) => {
  try {
    const response = await request.post('orders/view-proof-cloud', data);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getAllTemplateCategories = async () => {
  try {
    const response = await request.get('templates/categories');
    return response;
  } catch (error) {
    return error.response;
  }
};


const getAllTemplatesByTab = async (payload) => {
  try {
    const response = await request.post('templates/by-tab', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);
    const response = await request.post('templates/uploadFile', formData);

    return response.data.data.filePath;
  } catch (error) {
    return error.response;
  }
};
const clearTemplateFields = () => (dispatch) => {
  dispatch({ type: CLEAR_TEMPLATE_FIELDS });
};
const selectProduct =
  (product, productType = '') =>
    (dispatch) => {
      dispatch({ type: SELECT_PRODUCT, payload: { product, productType } });
    };

const selectPostCard = (product, productType) => (dispatch) => {
  dispatch({ type: SELECT_POSTCARD, payload: { product, productType } });
};
const clearAllTemplates = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_TEMPLATE });
};
const searchAndAdvanceChange = (name, value) => (dispatch) => {
  dispatch({ type: TEMPLATE_SEARCH, payload: { name, value } });
};
const dynmicInputChange = (value) => (dispatch) => {
  dispatch({ type: SET_DYNAMIC_FIELD_VALUE, payload: { value } });
};

const setDynamicFields = () => (dispatch) => {
  dispatch({ type: SET_DYNAMIC_FIELDS, payload: {} });
};

const removeItemFromDynamicField = (value) => (dispatch) => {
  dispatch({ type: REMOVE_FROM_DYNAMIC_FIELDS, payload: { value } });
};

const clearDynaicFields = () => (dispatch) => {
  dispatch({ type: CLEAR_DYNAMIC_FIELDS });
};

const clearFilter = () => (dispatch) => dispatch({ type: CLEAR_FIELDS });

export {
  dynmicInputChange,
  setDynamicFields,
  clearDynaicFields,
  removeItemFromDynamicField,
  getAllProducts,
  uploadTemplate,
  getAllTemplates,
  getOneTemplate,
  createTemplate,
  updateTemplate,
  clearFilter,
  deleteTemplate,
  getViewProof,
  doublicateTemplate,
  searchAndAdvanceChange,
  clearAllTemplates,
  selectProduct,
  selectPostCard,
  clearTemplateFields,
  getAllCustomFields,
  loadFormDataToStore,
  htmlViewProof,
  uploadFile,
  getProductDetails,
  getViewProofForOrder,
  getViewProofForAdminOrder,
  downloadProof,
  getAllTemplateCategories,
  getAllTemplatesByTab,
};
