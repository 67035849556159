import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import { getUserData } from "../../../../redux/actions/auth-actions";

// Utils
import { MESSAGES } from "../../../../utils/message";
import request from "../../../../utils/request";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// icons
import crossicon from "../../../../assets/images/modal/crossIcon.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditNameModal = (props) => {
  const { first, last, openModal, handleCloseModal } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.FIRST_NAME_REQUIRED)
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.FIRST_NAME_LIMIT),
    lastName: yup
      .string()
      .required(MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.LAST_NAME_REQUIRED)
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.LAST_NAME_LIMIT),
  });

  const formik = useFormik({
    initialValues: {
      firstName: first,
      lastName: last,
    },
    validationSchema: validationSchema,
  });

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const updateName = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const payload = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
      };
      setLoading(true);
      const response = await request.post("users/update-info", payload);
      dispatch(success(response.data.message));
      dispatch(getUserData());
      onClose();
    } catch (error) {
      dispatch(
        failure(
          error.response?.data?.message ||
            error?.data?.message ||
            MESSAGES.GENERAL_ERROR
        )
      );
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.firstName = false;
    formik.touched.lastName = false;
    formik.initialValues.firstName = first;
    formik.initialValues.lastName = last;
  }, [openModal]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="editNameModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={crossicon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.TITLE}
            </Typography>
          </Box>
          <form className="updateFormWrapper" onSubmit={updateName}>
            <Box className="editNameInputsWrapper">
              <InputLabel htmlFor="first-name" className="inputLabel">
                {MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.FIRST_NAME_LABEL}
              </InputLabel>
              <TextField
                placeholder="John"
                fullWidth
                name="firstName"
                onChange={formik.handleChange}
                className={
                  formik.errors.firstName && formik.touched.firstName
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.firstName}
                </Typography>
              ) : null}

              <InputLabel
                htmlFor="last-name"
                className="inputLabel"
                sx={{ marginTop: "22px" }}
              >
                {MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.LAST_NAME_LABEL}
              </InputLabel>
              <TextField
                placeholder="Doe"
                fullWidth
                name="lastName"
                onChange={formik.handleChange}
                className={
                  formik.errors.lastName && formik.touched.lastName
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.lastName}
                </Typography>
              ) : null}
            </Box>
            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.CANCEL_BUTTON}
              </Button>
              <Button
                className="btnSave"
                type="submit"
                onClick={() => validateForm(formik)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default EditNameModal;
