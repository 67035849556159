import React from "react";

const profile = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1485_4533)">
        <path d="M7.1687 9.33594C7.1687 11.4683 8.90352 13.2031 11.0359 13.2031C13.1683 13.2031 14.9031 11.4683 14.9031 9.33594C14.9031 7.20357 13.1683 5.46875 11.0359 5.46875C8.90352 5.46875 7.1687 7.20357 7.1687 9.33594ZM14.0437 9.33594C14.0437 10.9944 12.6944 12.3438 11.0359 12.3438C9.37738 12.3438 8.02808 10.9944 8.02808 9.33594C8.02808 7.67743 9.37738 6.32812 11.0359 6.32812C12.6944 6.32812 14.0437 7.67743 14.0437 9.33594Z" />
        <path d="M18.9421 5.29688C19.1794 5.29688 19.3718 5.1045 19.3718 4.86719C19.3718 4.62988 19.1794 4.4375 18.9421 4.4375C18.7048 4.4375 18.5125 4.62988 18.5125 4.86719C18.5125 5.1045 18.7048 5.29688 18.9421 5.29688Z" />
        <path d="M11.0359 22.9141C17.1333 22.9141 22.0359 17.9758 22.0359 11.9141C22.0359 9.89586 21.4856 7.92295 20.4447 6.20859C20.3216 6.00577 20.0573 5.94119 19.8544 6.0643C19.6516 6.18749 19.587 6.45175 19.7102 6.6546C20.6694 8.23452 21.1765 10.0532 21.1765 11.9141C21.1765 14.8543 19.9403 17.5815 17.7615 19.5059C17.0983 16.3774 14.3242 14.0625 11.0359 14.0625C7.74758 14.0625 4.97321 16.3777 4.31063 19.5063C2.13165 17.5816 0.895264 14.8544 0.895264 11.9141C0.895264 6.3225 5.44432 1.77344 11.0359 1.77344C13.3128 1.77344 15.463 2.50885 17.2542 3.90022C17.4415 4.0458 17.7115 4.01185 17.8571 3.82446C18.0027 3.63708 17.9688 3.36711 17.7813 3.22153C15.838 1.71195 13.5055 0.914062 11.0359 0.914062C8.09528 0.914062 5.3323 2.05772 3.25584 4.13431C1.17942 6.21082 0.0358887 8.97371 0.0358887 11.9141C0.0358887 17.9911 4.95405 22.9141 11.0359 22.9141ZM11.0359 14.9219C14.0737 14.9219 16.5952 17.1408 16.9966 20.1214C15.2583 21.387 13.2028 22.0547 11.0359 22.0547C8.86923 22.0547 6.81395 21.3869 5.07565 20.1213C5.47646 17.1407 7.99778 14.9219 11.0359 14.9219Z" />
      </g>
      <defs>
        <clipPath id="clip0_1485_4533">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.0358887 0.914062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default profile;
