// Request util for api call
import request from "../../utils/request";

// Actions Types
import { SET_PRODUCT_TYPES, SET_PRODUCTS_DATA } from "./action-types"

const fetchProductTypes = () => async (dispatch) => {
    try {
        const products = await request.get("products/types");
        dispatch({ type: SET_PRODUCT_TYPES, payload: products.data.data });
    } catch (error) {
        return error;
    }
};

const fetchAllProducts = () => async (dispatch) => {
    try {
        const products = await request.get("products");
        dispatch({ type: SET_PRODUCTS_DATA, payload: products.data.data.rows });
    } catch (error) {
        return error;
    }
};

export { fetchProductTypes, fetchAllProducts }