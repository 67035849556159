import React from "react";

const plan = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1485_4556)">
        <path d="M16.0354 12.4679H10.9351V11.7403H16.0354C17.8088 11.7403 19.2513 10.2976 19.2513 8.5243C19.2513 7.66441 18.917 6.8565 18.3102 6.24956C17.7032 5.64275 16.8953 5.30847 16.0354 5.30847H13.5725V4.58081H16.0354C17.0897 4.58081 18.0804 4.9908 18.8246 5.73506C19.5689 6.47932 19.9789 7.47003 19.9789 8.52424C19.9789 10.6989 18.2098 12.4679 16.0354 12.4679Z" />
        <path d="M8.38867 18.8965H17.498V19.6241H8.38867V18.8965Z" />
        <path d="M5.78008 19.6236H4.73245C2.55788 19.6236 0.788818 17.8546 0.788818 15.68C0.788818 14.6299 1.1986 13.6396 1.94253 12.8915C2.68503 12.1488 3.67566 11.7402 4.73245 11.7402H8.32668V12.4678H4.73245C3.87005 12.4678 3.06214 12.8007 2.4577 13.4052C1.85089 14.0154 1.51641 14.8234 1.51641 15.6799C1.51641 17.4533 2.95912 18.896 4.73245 18.896H5.78015L5.78008 19.6236Z" />
        <path d="M3.02271 4.58081H10.9643V5.3084H3.02271V4.58081Z" />
        <path d="M1.71839 6.43825C0.790658 6.43825 0.0358887 5.68348 0.0358887 4.75575C0.0358887 3.82801 0.790658 3.07324 1.71839 3.07324C2.64613 3.07324 3.4009 3.82801 3.4009 4.75575C3.4009 5.68348 2.6462 6.43825 1.71839 6.43825ZM1.71839 3.80076C1.1919 3.80076 0.763411 4.22919 0.763411 4.75575C0.763411 5.28231 1.19183 5.71073 1.71839 5.71073C2.24496 5.71073 2.67338 5.28231 2.67338 4.75575C2.67338 4.22919 2.24496 3.80076 1.71839 3.80076Z" />
        <path d="M12.2664 6.43668C11.436 6.43668 10.7212 5.8176 10.604 4.99662C10.5912 4.92626 10.5857 4.84364 10.5857 4.75594C10.5857 3.82922 11.3397 3.0752 12.2664 3.0752C13.1951 3.0752 13.9508 3.82922 13.9508 4.75594C13.9508 4.84371 13.9453 4.92626 13.9305 5.00888C13.8152 5.8176 13.0989 6.43668 12.2664 6.43668ZM12.2664 3.80272C11.7408 3.80272 11.3133 4.23026 11.3133 4.75587C11.3133 4.79918 11.3149 4.84025 11.3222 4.88126C11.3905 5.35841 11.7957 5.70902 12.2664 5.70902C12.7393 5.70902 13.1459 5.35834 13.2123 4.89353C13.2216 4.84025 13.2232 4.79918 13.2232 4.75587C13.2231 4.23033 12.7939 3.80272 12.2664 3.80272Z" />
        <path d="M9.62899 13.5956C8.79837 13.5956 8.08373 12.9765 7.96654 12.1557C7.95373 12.085 7.94824 12.0024 7.94824 11.9151C7.94824 10.9862 8.70227 10.2306 9.62899 10.2306C10.5577 10.2306 11.3133 10.9862 11.3133 11.9151C11.3133 12.0024 11.3078 12.085 11.2931 12.1678C11.1778 12.9765 10.4616 13.5956 9.62899 13.5956ZM9.62899 10.9581C9.10337 10.9581 8.67583 11.3875 8.67583 11.915C8.67583 11.9583 8.67746 11.9994 8.68471 12.0406C8.7531 12.5175 9.15827 12.868 9.62899 12.868C10.1019 12.868 10.5085 12.5175 10.5749 12.0527C10.5841 11.9994 10.5857 11.9583 10.5857 11.915C10.5857 11.3874 10.1566 10.9581 9.62899 10.9581Z" />
        <path d="M7.08644 20.7549C6.24871 20.7549 5.5323 20.1331 5.42006 19.3086C5.40745 19.2404 5.4021 19.1572 5.4021 19.0741C5.4021 18.1454 6.15775 17.3896 7.08644 17.3896C8.01316 17.3896 8.76718 18.1455 8.76718 19.0741C8.76718 19.1573 8.76183 19.2404 8.74691 19.3235C8.63698 20.1331 7.922 20.7549 7.08644 20.7549ZM7.08644 18.117C6.55886 18.117 6.12969 18.5465 6.12969 19.0741C6.12969 19.1146 6.13145 19.1551 6.13857 19.1956C6.20431 19.6763 6.61091 20.0273 7.08644 20.0273C7.56 20.0273 7.96503 19.6763 8.02841 19.2106C8.03783 19.1551 8.03959 19.1146 8.03959 19.0741C8.03959 18.5465 7.61205 18.117 7.08644 18.117Z" />
        <path d="M18.297 20.3914C17.6558 20.3914 17.134 19.8696 17.134 19.2283C17.134 18.5871 17.6557 18.0652 18.297 18.0652C18.9385 18.0652 19.4602 18.5871 19.4602 19.2283C19.4602 19.8696 18.9385 20.3914 18.297 20.3914ZM18.297 18.7927C18.057 18.7927 17.8616 18.9881 17.8616 19.2282C17.8616 19.4684 18.057 19.6638 18.297 19.6638C18.5372 19.6638 18.7326 19.4684 18.7326 19.2282C18.7326 18.9881 18.5372 18.7927 18.297 18.7927Z" />
        <path d="M18.2971 14.0856C18.0962 14.0856 17.9333 13.9228 17.9333 13.7219V13.2776C17.9333 13.0767 18.0962 12.9138 18.2971 12.9138C18.498 12.9138 18.6609 13.0767 18.6609 13.2776V13.7219C18.6609 13.9227 18.498 14.0856 18.2971 14.0856Z" />
        <path d="M18.2971 18.7927C18.0962 18.7927 17.9333 18.6299 17.9333 18.4289V16.0309C17.9333 15.83 18.0962 15.6671 18.2971 15.6671C18.498 15.6671 18.6609 15.83 18.6609 16.0309V18.4289C18.6609 18.6299 18.498 18.7927 18.2971 18.7927Z" />
        <path d="M21.6722 16.395H18.2971C18.0962 16.395 17.9333 16.2321 17.9333 16.0312V13.7222C17.9333 13.5213 18.0962 13.3584 18.2971 13.3584H21.6722C21.8077 13.3584 21.9319 13.4337 21.9946 13.5538C22.0573 13.6737 22.0483 13.8187 21.9708 13.9298L21.3125 14.8764L21.9709 15.8235C22.0482 15.9348 22.0573 16.0797 21.9946 16.1998C21.9318 16.3197 21.8075 16.395 21.6722 16.395ZM18.6609 15.6674H20.9762L20.5705 15.084C20.4838 14.9592 20.4838 14.7934 20.5707 14.6687L20.9761 14.0859H18.661V15.6674H18.6609Z" />
      </g>
      <defs>
        <clipPath id="clip0_1485_4556">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.0358887 0.914062)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default plan;
