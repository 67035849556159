import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Action
import {
  changeDefaultPaymentMethod,
  detachPaymentMethod,
  getAllPaymentMethods,
} from "../../../../redux/actions/stripe-actions";
import { failure, success } from "../../../../redux/actions/snackbar-actions";
import { getUserData } from "../../../../redux/actions/auth-actions";

// utils
import { ROLES } from "../../../../utils/constants";
import { MESSAGES } from "../../../../utils/message";

// MUI Components
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import Stripe from "../../../Stripe/Stripe";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

// images
import Payment from "../../../../assets/images/wallet/payment.svg";
import Trash from "../../../../assets/images/wallet/payment-trash.svg";
import Visa from "../../../../assets/images/wallet/visa.svg";
import Bank from "../../../../assets/images/wallet/bank.svg";
import MasterCard from "../../../../assets/images/wallet/mastercard.svg";
import CreditCardFallback from "../../../../assets/images/wallet/credit-card-fallback.svg";

// icons
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

// styles
import "./styles.scss";

// Payment Method Modal
import PaymentMethod from "../PaymentMethod";

const paymentIcons = {
  visa: Visa,
  us_bank_account: Bank,
  mastercard: MasterCard,
};
const PaymentCard = () => {
  const dispatch = useDispatch();
  const [exportModal, setExportModal] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    type: "",
    id: "",
    loading: false,
    isConfirmDialog: false,
  });
  const [isDeleteError, setIsDeleteError] = useState(false);

  const user = useSelector((state) => state.userReducers?.user);
  const isUser = !(user?.role?.slug === ROLES["ORGANIZATION_ADMIN"]);
  const paymentMethods = useSelector((state) => state.stripeReducers?.methods);
  const isPaymentMethodLoading = useSelector(
    (state) => state.stripeReducers?.areMethodsLoading
  );

  const defaultIndex = paymentMethods.findIndex(
    (item) => user?.organization?.defaultPaymentMethodStripeId === item?.id
  );

  if (defaultIndex !== -1 && defaultIndex !== 0) {
    paymentMethods.unshift(paymentMethods.splice(defaultIndex, 1)[0]);
  }

  const handleGetAllMethods = () => {
    dispatch(getAllPaymentMethods());
  };

  const handleDelete = async (id) => {
    const data = await detachPaymentMethod(id);
    if (data.status === 200) {
      dispatch(
        success(MESSAGES.ACCOUNT.WALLET.STRIPE.METHOD_DELETE_SUCCESS, " ")
      );
      handleGetAllMethods();
    } else {
      if (paymentMethods.length === 1) {
        setIsDeleteError(true);
      } else {
        dispatch(failure(data.data.message, " "));
      }
    }
    handleCloseConfirmDialog();
  };

  const handleChangeDefaultMethod = async (id) => {
    const data = await changeDefaultPaymentMethod(id);
    if (data.status === 200) {
      dispatch(
        success(MESSAGES.ACCOUNT.WALLET.STRIPE.DEFAULT_CHANGE_SUCCESS, " ")
      );
      dispatch(getUserData());
      handleGetAllMethods();
    } else {
      dispatch(
        failure(MESSAGES.ACCOUNT.WALLET.STRIPE.DEFAULT_CHANGE_ERROR, " ")
      );
    }
    handleCloseConfirmDialog();
  };

  const handleCloseConfirmDialog = () => {
    setDialog({ open: false, message: "", type: "", id: "", loading: false });
  };

  const handleConfirmDialog = () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    if (dialog.type === "delete") {
      handleDelete(dialog.id);
    } else {
      handleChangeDefaultMethod(dialog.id);
    }
  };

  useEffect(() => {
    handleGetAllMethods();
  }, []);

  return (
    <>
      <Box className="paymentCardWrapper">
        {dialog.open &&
          (!dialog.isConfirmDialog ? (
            <DeleteDialog
              name="payment method"
              loading={dialog.loading}
              open={dialog.open}
              handleClose={handleCloseConfirmDialog}
              handleOk={handleConfirmDialog}
            />
          ) : (
            <ConfirmDialog
              successButtonName="Yes"
              message={dialog.message}
              open={dialog.open}
              loading={dialog.loading}
              handleClose={handleCloseConfirmDialog}
              handleOk={handleConfirmDialog}
            />
          ))}

        <Box className="paymentMethodWrapper">
          <Typography>
            {MESSAGES.ACCOUNT.WALLET.STRIPE.YOUR_PAYMENT_METHOD}
          </Typography>
          <Box className="paymentMethod">
            {paymentMethods.length == 0 && (
              <Box className="noPayment">
                <Typography>No payment methods added</Typography>
              </Box>
            )}
            {paymentMethods.map((item, i) => (
              <Box key={i} className="methodBar">
                <Box className="numberWrapper">
                  {user?.organization?.defaultPaymentMethodStripeId ===
                  item?.id ? (
                    <IconButton>
                      <StarIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={isUser}
                      onClick={() =>
                        handleChangeDefaultMethod(item.id)
                      }
                    >
                      <StarBorderIcon />
                    </IconButton>
                  )}
                  <Typography>
                    {item.type === "card" ? "**** **** **** " : "******"}
                    {item.details.last4}
                  </Typography>
                </Box>
                <Box className="iconsWrapper">
                  <img
                    src={
                      item.type === "card"
                        ? paymentIcons[item.details.brand] || CreditCardFallback
                        : paymentIcons[item.type]
                    }
                    alt="credit"
                  />
                  {paymentMethods.length !== 1 && (
                    <Tooltip
                      title={
                        isUser
                          ? MESSAGES.ACCOUNT.WALLET.STRIPE
                              .UNAUTHORIZED_DELETE_PAYMENT_METHOD
                          : ""
                      }
                    >
                      <IconButton
                        onClick={() => {
                          if (isUser) return;
                          setDialog({
                            open: true,
                            id: item.id,
                            message: "Are you sure you want to delete it?",
                            type: "delete",
                          });
                        }}
                      >
                        <img src={Trash} alt="Trash" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            ))}
            {isDeleteError && (
              <Typography className="paymentMethod__error">
                {MESSAGES.ACCOUNT.WALLET.STRIPE.PAYMENT_METHOD_ERROR}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className="addPaymentWrapper">
          <img src={Payment} alt="payment" />
          <Tooltip
            title={
              isUser
                ? MESSAGES.ACCOUNT.WALLET.STRIPE.UNAUTHORIZED_ADD_PAYMENT_METHOD
                : ""
            }
          >
            <Button
              onClick={() => {
                if (isUser) return;
                setExportModal(true);
              }}
              className={isUser ? "disabled" : ""}
            >
              {isPaymentMethodLoading ? (
                <CircularProgress
                  sx={{
                    width: "24px !important",
                    height: "24px !important",
                    color: "white",
                  }}
                />
              ) : (
                MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_PAYMENT_METHOD
              )}
            </Button>
          </Tooltip>
        </Box>
        <Stripe>
          <PaymentMethod
            open={exportModal}
            handleOpen={() => {
              setExportModal(true);
            }}
            handleClose={() => {
              setExportModal(false);
            }}
          />
        </Stripe>
      </Box>
    </>
  );
};

export default PaymentCard;
