import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  fetchSources,
  updateSource,
} from "../../redux/actions/sources-actions";
import { success } from "../../redux/actions/snackbar-actions";

// utils
import { MESSAGES } from "../../utils/message";
import request from "../../utils/request";

// Components
import Loader from "../General/Loader";
import IntegrationCard from "./IntegrationCard";

// MUI Components
import { Container, Box, Typography, Divider } from "@mui/material";

// images
import NoIntegrations from "../../assets/images/contacts/no-contact.svg";

// styles
import "./styles.scss";

const Integrations = () => {
  const [enabledSources, setEnabledSources] = useState([]);
  const [disabledSources, setDisabledSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const sources = useSelector((state) => state.sourcesReducers.sources || []);
  const loadingState = useSelector((state) => state.sourcesReducers.loading);

  const dispatch = useDispatch();

  const getAllApiKeys = async () => {
    try {
      const response = await request.get("api-keys", { pageSize: 500 });
      if (response.status === 200) {
        setApiKeys(response?.data?.data?.rows);
      }
      return response;
    } catch (error) {
      return error.response;
    }
  };

  const handleAction = async (title, status) => {
    setLoading(true);
    const newStatus = status === "Enabled" ? "Disabled" : "Enabled";
    try {
      const response = await dispatch(
        updateSource({ source: title, status: newStatus })
      );

      if (response && response.status === 200) {
        await getAllApiKeys();
        dispatch(success(response.data.message));
      } else {
        console.error("Failed to update source:", response);
      }
    } catch (error) {
      console.error("Error updating source:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    getAllApiKeys();
    dispatch(fetchSources());
  }, []);

  useEffect(() => {
    const enabledSet = new Set();
    const disabledSet = new Set();

    const keys = {};
    apiKeys.forEach((apikey) => {
      keys[apikey.source] = apikey;
    });

    sources.forEach((source) => {
      if (source.status === "Enabled") {
        const apikey = keys[source.key];
        if (apikey && apikey.status === "Enabled") {
          enabledSet.add({ ...source, status: apikey?.status });
        } else {
          disabledSet.add({ ...source, status: apikey?.status || "Disabled" });
        }
      }
    });

    setEnabledSources(Array.from(enabledSet));
    setDisabledSources(Array.from(disabledSet));
  }, [sources, apiKeys]);

  const NoIntegrationsinDB = () => {
    return (
      <Box className="noIntegrations">
        <img src={NoIntegrations} alt="NoIntegrations" />
        <Typography>No Integrations found</Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth="xxl">
      <Box className="integrationWrapper">
        <Typography>{MESSAGES.INTEGRATIONS.YOUR_INTEGRATIONS_LABLE}</Typography>
        <Box className="integaredCardsWrapper">
          {loadingState ? (
            <Box className="loadingDiv">
              <Loader />
            </Box>
          ) : enabledSources.length > 0 ? (
            enabledSources.map((content) => {
              return (
                <IntegrationCard
                  title={content?.name}
                  key={content?.id}
                  image={content?.image}
                  description={content?.description}
                  status={content?.status}
                  id={content?.id}
                  link={content?.documentationLink}
                  handleAction={handleAction}
                  loading={loading}
                />
              );
            })
          ) : (
            <NoIntegrationsinDB />
          )}
        </Box>
      </Box>
      {disabledSources.length > 0 ? (
        <>
          <Divider className="integrationDivider" />
          <Box className="integrationWrapper">
            <Typography>
              {MESSAGES.INTEGRATIONS.SUGGESTED_INTEGRATIONS_LABLE}
            </Typography>
            <Box
              className={`suggestedCardsWrapper ${loadingState && "loaderDiv"}`}
            >
              {loadingState ? (
                <Box className="loadingDiv">
                  <Loader />
                </Box>
              ) : (
                disabledSources.map((content) => {
                  return (
                    <IntegrationCard
                      title={content?.name}
                      key={content?.id}
                      image={content?.image}
                      description={content?.description}
                      status={content?.status}
                      id={content?.id}
                      link={content?.documentationLink}
                      handleAction={handleAction}
                      loading={loading}
                    />
                  );
                })
              )}
            </Box>
          </Box>
        </>
      ) : null}
    </Container>
  );
};

export default Integrations;
