import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Actions
import { ForgotUserPassword } from "../../redux/actions/auth-actions";
import { success, failure } from "../../redux/actions/snackbar-actions";

// Utils
import { EMAIL_REGEX } from "../../utils/constants";

// Component
import {
  validateForm,
  realTimeValidation,
} from "../../utils/formik-validation";

// Default messages
import { MESSAGES } from "../../utils/message";

// MUI Components
import {
  Box,
  Grid,
  Input,
  InputLabel,
  Typography,
  Button,
  CircularProgress,
  Container,
} from "@mui/material";

// Icons from Material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// images
import OpenLetter from "../../assets/images/login/secondary.svg";

// Styles
import "../../index.scss";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL)
      .required(MESSAGES.AUTH.EMAIL_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
  });

  const forgotPassword = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const payload = {
        email: formik.values.email,
      };
      setLoading(true);
      const request = await dispatch(ForgotUserPassword(payload));
      setLoading(false);
      if (request.message === "success") {
        navigate("/verification");
        dispatch(success(request?.data?.message || ""));
      } else {
        dispatch(
          failure(request?.response?.data?.message || MESSAGES.GENERAL_ERROR)
        );
        if (request?.response?.data?.data?.errors?.length) {
          let serverSideError = request?.response?.data?.data?.errors;
          serverSideError.forEach((error) => {
            formik.errors[error.path] = error.msg;
            formik.touched[error.path] = true;
          });
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.email = false;
  }, []);

  return (
    <Box className="layoutBg" sx={{ height: "100%", minHeight: "102vh" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box className="imageWrapper">
              <img src={OpenLetter} alt="OpenLetter" />
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className="formBG">
            <Box className="formWrapper updateForm">
              <Box className="forgotFormHeadings">
                <Box className="backArrow">
                  <ArrowBackIcon
                    onClick={() => {
                      navigate("/login");
                    }}
                  />
                </Box>
                <Typography variant="h4">
                  {MESSAGES.FORGOT_PASSWORD.TITLE}
                </Typography>
                <Typography variant="h6">
                  {MESSAGES.FORGOT_PASSWORD.HEADING}
                </Typography>
              </Box>
              <form className="updateFormWrapper" onSubmit={forgotPassword}>
                <Box className="inputWrapper updateFormInput">
                  <InputLabel className="formLabel">
                    {MESSAGES.FORGOT_PASSWORD.INPUT_LABEL_EMAIL}
                    <span style={{ position: "absolute", top: "-3px" }}>*</span>
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.FORGOT_PASSWORD.EMAIL_PLACEHOLDER}
                    autoComplete="off"
                    name="email"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.email && formik.touched.email
                        ? "invalid formInput"
                        : "formInput"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Button
                  type="submit"
                  onClick={() => {
                    validateForm(formik);
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    MESSAGES.FORGOT_PASSWORD.SUBMIT_BUTTON
                  )}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
