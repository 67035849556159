// Utils
import { chartColors } from "../../utils/constants";
//Actions
import { SET_DASHBOARD_WIDGETS_DATA, SET_DASHBOARD_CHART_DATA, SET_LIMIT_MODAL, SET_DYNAMIC_LIMIT_MODAL } from "../actions/action-types"

const initialState = {
    widgets: {
        currentSpend: 0,
        currentMailings: 0,
        delivered: 0,
        deliveredRatio: 0,
        mailed: 0,
        mailedRatio: 0,
        inTransit: 0,
        inTransitRatio: 0,
        returnedToSender: 0,
        returnedToSenderRatio: 0,
        upcomingMailings: [],
    },
    chart: {
        datasets: [],
        labels: [],
    },
    limitModal: false,
    limitModalTitle: null,
    limitModalHeading: null,
    limitModalMessage: null,
};

const dashboardReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DASHBOARD_WIDGETS_DATA:
            return {
                ...state, widgets: { ...payload }
            };
        case SET_DASHBOARD_CHART_DATA:
            return {
                ...state, chart: {
                    labels: payload.labels, datasets: payload.chartData.map((chart) => ({
                        ...chart,
                        borderColor: chartColors[chart.label],
                        backgroundColor: chartColors[chart.label],
                    }))
                },
            };
        case SET_LIMIT_MODAL:
            return { ...state, limitModal: payload, limitModalTitle: null, limitModalHeading: null, limitModalMessage: null }
        case SET_DYNAMIC_LIMIT_MODAL:
            return { ...state, limitModal: payload.status, limitModalTitle: payload.title, limitModalHeading: payload.subHeading, limitModalMessage: payload.message }
        default:
            return state;
    }
};


export { dashboardReducers };