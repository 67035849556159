import React from 'react';

const CustomTemplate = (props) => {
  const {fill} = props;
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.125 18.5625H11C11.1823 18.5625 11.3572 18.6349 11.4861 18.7639C11.6151 18.8928 11.6875 19.0677 11.6875 19.25C11.6875 19.4323 11.6151 19.6072 11.4861 19.7361C11.3572 19.8651 11.1823 19.9375 11 19.9375H4.125C3.57799 19.9375 3.05339 19.7202 2.66659 19.3334C2.2798 18.9466 2.0625 18.422 2.0625 17.875V4.125C2.0625 3.57799 2.2798 3.05339 2.66659 2.66659C3.05339 2.2798 3.57799 2.0625 4.125 2.0625H17.875C18.422 2.0625 18.9466 2.2798 19.3334 2.66659C19.7202 3.05339 19.9375 3.57799 19.9375 4.125V4.8125C19.9375 4.99484 19.8651 5.1697 19.7361 5.29864C19.6072 5.42757 19.4323 5.5 19.25 5.5C19.0677 5.5 18.8928 5.42757 18.7639 5.29864C18.6349 5.1697 18.5625 4.99484 18.5625 4.8125V4.125C18.5625 3.94266 18.4901 3.7678 18.3611 3.63886C18.2322 3.50993 18.0573 3.4375 17.875 3.4375H4.125C3.94266 3.4375 3.7678 3.50993 3.63886 3.63886C3.50993 3.7678 3.4375 3.94266 3.4375 4.125V6.875H19.25C19.4323 6.875 19.6072 6.94743 19.7361 7.07636C19.8651 7.2053 19.9375 7.38016 19.9375 7.5625V17.875C19.9375 18.422 19.7202 18.9466 19.3334 19.3334C18.9466 19.7202 18.422 19.9375 17.875 19.9375H13.75C13.5677 19.9375 13.3928 19.8651 13.2639 19.7361C13.1349 19.6072 13.0625 19.4323 13.0625 19.25V8.25H3.4375V17.875C3.4375 18.0573 3.50993 18.2322 3.63886 18.3611C3.7678 18.4901 3.94266 18.5625 4.125 18.5625ZM14.4375 18.5625H17.875C18.0573 18.5625 18.2322 18.4901 18.3611 18.3611C18.4901 18.2322 18.5625 18.0573 18.5625 17.875V8.25H14.4375V18.5625Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default CustomTemplate;
