import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';
import FormDialog from './FormDialog/FormDialog';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Field from '../../../assets/images/templates/dynamic-field.jsx';
import ContentCopyIcon from '../../../assets/images/templates/clipboard.svg';
import InfoIcon from '../../../assets/images/templates/info-icon.svg';
import TrashIcon from '../../../assets/images/templates/black-trash-icon.svg';
import PlusIcon from '../../../assets/images/templates/plus-icon.svg';
import Icon from '@mui/material/Icon/Icon';
import { getAllCustomFields, removeItemFromDynamicField } from '../../../redux/actions/template-builder';
import { copyToClipboard } from '../../../utils/clipboard';
import './styles.scss'
import CustomFieldNameModel from './CustomFieldNameModel';
import { success } from '../../../redux/actions/snackbar-actions';

import { defaultPropertyDynamicFields } from '../../../utils/constants';

export const DymanicText = {
  name: "Fields",
  Tab: (props) => (
    <SectionTab name="Fields" {...props}>
      <Icon>
        <Field fill="#000" />
      </Icon>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {
    const [isShowDialog, setIsShowDialog] = useState(false);
    const dispatch = useDispatch();
    const isPropertyAddressEnabled = useSelector(
      (state) =>
        state?.generalSettingsReducers?.generalSettings
          ?.propertyAddressStrictness === "Include" || false
    );
    const dyamicFields = useSelector(
      (state) => state.templateReducer.dynamicFields
    );
    const defaultFields = useSelector(
      (state) => state.templateReducer.defaultDynamicFields
    );
    const currentTemplateType = useSelector(
      (state) => state.templateReducer?.product?.productType
    );

    const defeultDynamicFieldsWithPropertyFields = isPropertyAddressEnabled
      ? [...defaultFields, ...defaultPropertyDynamicFields]
      : defaultFields;

    useEffect(() => {
      dispatch(getAllCustomFields());
    }, []);

    const copyCustomFieldText = (value) => {
      if (currentTemplateType === "Real Penned Letter") {
        let modifiedString = value.replace(/{{/g, "((").replace(/}}/g, "))");
        copyToClipboard(modifiedString);
        dispatch(success(`${modifiedString} Copied`));
      } else {
        copyToClipboard(value);
        dispatch(success(`${value} Copied`));
      }
    };

    const handleAddElementOnScreen = (event, value, type) => {
      event.preventDefault();

      if (currentTemplateType === "Real Penned Letter") {
        copyCustomFieldText(value);
        return;
      }

      let x, y;

      if (type === "drag") {
        const rect = event.currentTarget.getBoundingClientRect();
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      } else {
        // For click events, use nativeEvent.offsetX and nativeEvent.offsetY
        x = store.width / 2 - 200;
        y = 300;
      }
      x /= store.activePage.scale;

      y /= store.activePage.scale;

      store.activePage.addElement({
        type: "text",
        x,
        y,
        text: value,
        width: value.length > 15 ? 10 * value.length : 150,
        contentEditable: false,
      });
    };
    const handleShowDialog = () => {
      setIsShowDialog((prev) => !prev);
    };
    return (
      <div className="dynamic-content">
        <div className="dynamic-content__top">
          <div>
            <span className="title">Contact Fields</span>{" "}
            <Tooltip title="Merge fields allow you to personalize your mailer with contact information.">
              <img src={InfoIcon} className="icon" />
            </Tooltip>
          </div>
        </div>
        {defeultDynamicFieldsWithPropertyFields.map(({ key, value }, i) => (
          <Box sx={{ display: "flex", alignItems: "center" }} key={i}>
            <span
              className="contact-element"
              onClick={(event) => handleAddElementOnScreen(event, key, "click")}
            >
              {value}
            </span>

            <Tooltip title="Copy">
              <IconButton onClick={() => copyCustomFieldText(key)}>
                <img src={ContentCopyIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        <hr className="divider" />
        <div className="dynamic-content__top">
          <div>
            <span className="title">Custom Fields</span>{" "}
            <Tooltip title="You can add custom fields to your template.">
              <img src={InfoIcon} className="icon" />
            </Tooltip>
          </div>
          {/* <IconButton onClick={handleShowDialog}>
          <img src={PlusIcon} className='plus-icon' />
          </IconButton> */}
        </div>
        {Object.values(dyamicFields)?.map(({ key, value }, i) => (
          <Box sx={{ display: "flex", alignItems: "center" }} key={i}>
            <span
              className="contact-element"
              onClick={(event) => handleAddElementOnScreen(event, key, "click")}
            >
              {value}
            </span>
            <Tooltip title="Copy">
              <IconButton onClick={() => copyCustomFieldText(key)}>
                <img src={ContentCopyIcon} />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Delete">
            <IconButton onClick={()=> dispatch(removeItemFromDynamicField(value))}>
                <img src={TrashIcon} /> 
            </IconButton>
            </Tooltip> */}
          </Box>
        ))}
        {/* {isShowDialog && <FormDialog open={isShowDialog} handleClose={handleShowDialog}/> } */}
        {isShowDialog && (
          <CustomFieldNameModel
            open={isShowDialog}
            handleClose={handleShowDialog}
          />
        )}
      </div>
    );
  }),
};
