import React from "react";

const Field = (props) => {
  const { fill } = props;
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3047 5.21094H18.5078C18.0116 5.21094 17.6094 5.61317 17.6094 6.10938C17.6094 6.60558 18.0116 7.00781 18.5078 7.00781H20.3047C20.8001 7.00781 21.2031 7.41085 21.2031 7.90625V15.0938C21.2031 15.5891 20.8001 15.9922 20.3047 15.9922H18.5078C18.0116 15.9922 17.6094 16.3944 17.6094 16.8906C17.6094 17.3868 18.0116 17.7891 18.5078 17.7891H20.3047C21.7909 17.7891 23 16.5799 23 15.0938V7.90625C23 6.42005 21.7909 5.21094 20.3047 5.21094Z"
        fill={fill}
      />
      <path
        d="M4.49219 10.6016H5.39062V13.2969C5.39062 13.7931 5.79286 14.1953 6.28906 14.1953C6.78527 14.1953 7.1875 13.7931 7.1875 13.2969V10.6016H8.08594C8.58214 10.6016 8.98438 10.1993 8.98438 9.70312C8.98438 9.20692 8.58214 8.80469 8.08594 8.80469H4.49219C3.99598 8.80469 3.59375 9.20692 3.59375 9.70312C3.59375 10.1993 3.99598 10.6016 4.49219 10.6016Z"
        fill={fill}
      />
      <path
        d="M2.69531 17.7891H14.0156V19.5859H13.1172C12.621 19.5859 12.2188 19.9882 12.2188 20.4844C12.2188 20.9806 12.621 21.3828 13.1172 21.3828H16.7109C17.2071 21.3828 17.6094 20.9806 17.6094 20.4844C17.6094 19.9882 17.2071 19.5859 16.7109 19.5859H15.8125V3.41406H16.7109C17.2071 3.41406 17.6094 3.01183 17.6094 2.51562C17.6094 2.01942 17.2071 1.61719 16.7109 1.61719H13.1172C12.621 1.61719 12.2188 2.01942 12.2188 2.51562C12.2188 3.01183 12.621 3.41406 13.1172 3.41406H14.0156V5.21094H2.69531C1.20912 5.21094 0 6.42005 0 7.90625V15.0938C0 16.5799 1.20912 17.7891 2.69531 17.7891ZM1.79688 7.90625C1.79688 7.41085 2.19991 7.00781 2.69531 7.00781H14.0156V15.9922H2.69531C2.19991 15.9922 1.79688 15.5891 1.79688 15.0938V7.90625Z"
        fill={fill}
      />
    </svg>
  );
};

export default Field;
