import React from 'react';

const wallet = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.1265 10.9979V8.18698C19.1265 7.23381 18.3872 6.45864 17.4531 6.38347L14.8426 1.82389C14.6007 1.40219 14.2101 1.10079 13.7426 0.975635C13.2774 0.851333 12.791 0.916617 12.3746 1.15896L3.42575 6.36881H1.85405C0.85133 6.36881 0.0358887 7.18421 0.0358887 8.18698V19.0959C0.0358887 20.0986 0.851287 20.9141 1.85405 20.9141H17.3084C18.3111 20.9141 19.1265 20.0987 19.1265 19.0959V16.2849C19.6545 16.0967 20.0356 15.5969 20.0356 15.0051V12.2778C20.0356 11.686 19.6545 11.1862 19.1265 10.9979ZM16.3967 6.36881H12.435L15.4063 4.6389L16.3967 6.36881ZM14.9547 3.85001L10.6282 6.36881H8.83367L14.506 3.06631L14.9547 3.85001ZM12.8322 1.94461C13.0377 1.82431 13.2779 1.79236 13.5074 1.85363C13.7395 1.91576 13.9331 2.0658 14.0534 2.27576L14.0543 2.27742L7.02701 6.36881H5.23254L12.8322 1.94461ZM18.2174 19.0959C18.2174 19.597 17.8095 20.005 17.3084 20.005H1.85405C1.35293 20.005 0.944993 19.597 0.944993 19.0959V8.18698C0.944993 7.68585 1.35293 7.27792 1.85405 7.27792H17.3084C17.8095 7.27792 18.2174 7.68585 18.2174 8.18698V10.9142H15.4902C13.9863 10.9142 12.763 12.1375 12.763 13.6414C12.763 15.1453 13.9863 16.3687 15.4902 16.3687H18.2174V19.0959ZM19.1265 15.0051C19.1265 15.2558 18.9228 15.4596 18.672 15.4596H15.4902C14.4875 15.4596 13.672 14.6442 13.672 13.6414C13.672 12.6387 14.4874 11.8233 15.4902 11.8233H18.672C18.9228 11.8233 19.1265 12.027 19.1265 12.2778V15.0051Z" fill="black"/>
<path d="M15.4901 12.7324C14.989 12.7324 14.5811 13.1404 14.5811 13.6415C14.5811 14.1426 14.989 14.5505 15.4901 14.5505C15.9912 14.5505 16.3992 14.1426 16.3992 13.6415C16.3992 13.1404 15.9913 12.7324 15.4901 12.7324Z" fill="black"/>
</svg>

)

export default wallet;