//Actions
import { SET_USER_DATA, SET_TEAM_MEMBERS_DATA, SET_USER_LOADER, LOGOUT } from "../actions/action-types"

const initialState = {
  user: [],
  isSystemAdmin: null,
  teamMembers: [],
  loading: false,
};

const userReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
      return { ...state, user: payload, isSystemAdmin: payload.isSystemAdmin };
    case SET_USER_LOADER:
      return { ...state, loading: payload };
    case SET_TEAM_MEMBERS_DATA:
      return { ...state, teamMembers: payload };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export { userReducers };