import React, { useEffect, useState } from 'react';

// Import third-party libraries
import {setUploadFunc} from 'polotno/config';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel, DEFAULT_SECTIONS } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { DymanicText } from './CustomTextSection/CustomTextSection';
import { CustomAddOns } from './CustomAddOns';
import '@blueprintjs/core/lib/css/blueprint.css';
import { setGoogleFonts } from 'polotno/config';
import fonts from "../../utils/fonts.json";
import LexiRegularFont from "../../assets/Fonts/Lexi-Regular.ttf";

// Actions
import {
  clearFilter,
  getAllCustomFields,
  getOneTemplate,
  uploadFile,
} from '../../redux/actions/template-builder';
import { TEMPLATE_LOADING } from '../../redux/actions/action-types';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import {  useParams , useNavigate} from 'react-router-dom';

// Utils
import {  drawRestrictedAreaOnPage, getFileAsBlob, addElementsforRealPennedLetters } from '../../utils/template-builder';
import { DPI, multiPageLetters } from '../../utils/constants';

// Components
import DisableTemplateBuilder from './DisableTemplateBuilder/DisableTemplateBuilder';
import TopNavigation from './TopNavigation/TopNavigation';
import { CustomTemplatesSection } from './CustomTemplateSection/CustomTemplateSection';

// MUI components
import { Box, Grid } from '@mui/material';

import './styles.scss';

setUploadFunc(uploadFile)
/**
 * This code defines a React functional component called `TemplateBuilder` that is responsible for rendering a template builder interface.
 * It includes various useEffect hooks to handle component lifecycle events and state updates.
 * The component uses the `polotno` library to create a canvas workspace where users can design templates.
 * It also includes a side panel with different sections for adding and customizing elements on the canvas.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.store - The store object passed as a prop to the `TemplateBuilder` component.
 * @returns {JSX.Element} The rendered template builder interface.
 */
const TemplateBuilder = ({ store }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isStoreUpdated, setIsStoreUpdated] = useState(false);
  const [switchTabCount, setSwitchTabCount] = useState(1);
  const template = useSelector((state) => state.templateReducer.template);
  const product = useSelector((state) => state.templateReducer.product);
  const envelopeType = useSelector(
    (state) => state.templateReducer.envelopeType
  );

  const currentTemplateType = product?.productType;

  useEffect(() => {
    handleLoadTemplate();
  }, [template]);

  // Event listener for visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setSwitchTabCount((prev) => prev + 1);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setGoogleFonts(fonts);
    // remove this component from the history stack
    if (id) {
      dispatch(getOneTemplate(id));
      dispatch(getAllCustomFields());
    } else if (store.pages.length === 0) {
      createInitialPage();
    }

    const handleChange = () => {
      if (!isStoreUpdated) {
        setIsStoreUpdated(true);
      }
    };
    const off = store.on("change", handleChange);

    return () => {
      dispatch(clearFilter());
      store.history.clear();
      store.clear();
      off();
    };
  }, []);

  useEffect(() => {
    if (!id && product && store.pages.length === 0) {
      createInitialPage();
    }
  }, [product]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    const addBeforeUnloadListener = () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("beforeunload", handleBeforeUnload);
    };

    const removeBeforeUnloadListener = () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    if (isStoreUpdated) {
      addBeforeUnloadListener();
    }

    return () => {
      removeBeforeUnloadListener();
    };
  }, [isStoreUpdated]);

  const createInitialPage = async () => {
    if (product) {
      store.addPage();
      const paperSize = product.selectedSize.split("x");
      store.setUnit({
        unit: "in",
        dpi: DPI,
      });
      store.setSize(+paperSize[1] * DPI, +paperSize[0] * DPI);
      if (multiPageLetters.includes(product.productType)) {
        store.addPage();
        store.selectPage(store.pages[0].id);
        // if(product.productType===multiPageLetters[0]){
        //     checkPageNumbers();
        // }
      }
      drawRestrictedAreaOnPage(store, product, envelopeType);

      if (currentTemplateType === "Real Penned Letter") {
        handleRealPennedLetters();
      }

      store.history.clear();
    }

    setIsStoreUpdated(false);
  };

  const handleRealPennedLetters = async () => {
    try {
      // Load Lexi Regular Fonts Into Store
      store.addFont({
        fontFamily: "lexi Regular",
        url: LexiRegularFont,
      });
      addElementsforRealPennedLetters(store);

      const response = await fetch(LexiRegularFont);
      const blob = await response.blob();

      const reader = new FileReader();
      // Load Lexi Regular Base64 into JSON
      reader.onloadend = () => {
        store.addFont({
          fontFamily: "lexi Regular",
          url: reader.result,
        });
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error loading the font file:", error);
    }
  };

  const processPage = async (index, page) => {
    return new Promise((resolve, reject) => {
      let pageNumber = page.children.find(
        (el) => el.custom?.name === "page-number"
      );
      const text = index === 0 ? "Front" : "Back";

      if (pageNumber) {
        pageNumber.set({ text });
        resolve(); // Resolve the promise if the update is successful
      } else {
        page.addElement({
          type: "text",
          custom: { name: "page-number" },
          text,
          width: store.width,
          align: "center",
          fontSize: 40,
          x: -450,
          y: -50,
          selectable: false,
          alwaysOnTop: true,
        });
        resolve(); // Resolve the promise after adding the element
      }
    });
  };

  const checkPageNumbers = async () => {
    const promises = store.pages.map(async (page, index) => {
      await processPage(index, page);
    });

    await Promise.all(promises); // Wait for all promises to resolve

    setIsStoreUpdated(false);
  };

  const handleLoadTemplate = async () => {
    if (template) {
      const workspaceElement = document.querySelector(
        ".polotno-workspace-container"
      );
      if(workspaceElement) {
        workspaceElement.classList.add("show-loader");
      }
      const paperDimensions = template.product.paperSize.split('x');
      store.setUnit({
        unit: "in",
        dpi: 96,
      });
      store.setSize(+paperDimensions[1] * DPI, +paperDimensions[0] * DPI);
      let jsonData = await getFileAsBlob(template.templateUrl);
      if (template?.product?.productType === 'Real Penned Letter') {
        let clonedJson = JSON.stringify(jsonData).replace(/{{/g, "((").replace(/}}/g, "))");
        jsonData = JSON.parse(clonedJson);
      }
      store.loadJSON(jsonData);
      await store.waitLoading();
      setIsStoreUpdated(false);
      dispatch({ type: TEMPLATE_LOADING, payload: false });
      if(workspaceElement) {
        workspaceElement.classList.add("hide-loader");
      }
    }
  };

  const sections =
    currentTemplateType === "Real Penned Letter"
      ? DEFAULT_SECTIONS.filter((section) => section.name === "")
      : DEFAULT_SECTIONS.filter(
          (section) => !["photos", "size", "templates"].includes(section.name)
        );

  // Incase of Real Penned Letters hide tooltip
  const Tooltip = () => null;

  return (
    <>
      <Grid className="polotno-container">
        {switchTabCount > 0 && (
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <TopNavigation
              showViewProofButton={true}
              showBackButton={false}
              store={store}
              isStoreUpdated={isStoreUpdated}
            />

            <PolotnoContainer
              style={{
                width: "100vw",
                height: "80vh",
                position: "relative",
                top: "40px",
              }}
            >
              <SidePanelWrap>
                <SidePanel
                  store={store}
                  sections={[
                    ...(currentTemplateType !== "Real Penned Letter"
                      ? [CustomTemplatesSection]
                      : []),
                    ...sections,
                    DymanicText,
                    ...(currentTemplateType !== "Real Penned Letter" ? [CustomAddOns] : [])
                  ]}
                  defaultSection="text"
                />
              </SidePanelWrap>
              <WorkspaceWrap>
                {currentTemplateType !== "Real Penned Letter" && (
                  <Toolbar store={store} downloadButtonEnabled={false} />
                )}
                {currentTemplateType !== "Real Penned Letter" ? (
                  <Workspace store={store} pageControlsEnabled={false} />
                ) : (
                  <Workspace
                    store={store}
                    pageControlsEnabled={false}
                    components={{ Tooltip }}
                  />
                )}
                <ZoomButtons store={store} />
              </WorkspaceWrap>
            </PolotnoContainer>
          </Box>
        )}
        <DisableTemplateBuilder />
      </Grid>
    </>
  );
};

export default TemplateBuilder;
