import React, { useState, useEffect } from "react";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Hooks
import { useDispatch } from "react-redux";

// image
import crossicon from "../../../../assets/images/modal/crossIcon.svg";

// Utils
import { MESSAGES } from "../../../../utils/message";

//Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import { updateCustomField } from "../../../../redux/actions/customFields-actions";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "../../../../utils/formik-validation";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditCustomFieldModal = (props) => {
  const { openModal, handleCloseModal, selectedCustomField, pagination } =
    props;

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(MESSAGES.SETTINGS.CUSTOM_FIELDS.CUSTOM_FIELD_NAME_REQUIRED)
      .min(1, MESSAGES.SETTINGS.CUSTOM_FIELDS.CUSTOM_FIELD_NAME_REQUIRED)
      .max(25, MESSAGES.SETTINGS.CUSTOM_FIELDS.NAME_LESS_25),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
  });

  const updateExistingCustomField = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const payload = {
        customFieldName: formik.values.name,
      };
      const response = await dispatch(
        updateCustomField(selectedCustomField.id, payload, pagination)
      );
      if (
        response.data.message ==
        MESSAGES.SETTINGS.CUSTOM_FIELDS.RESPONSE_ALREADY_EXIST
      ) {
        formik.setErrors({
          name: MESSAGES.SETTINGS.CUSTOM_FIELDS.FIELD_EXISTS,
        });
        setLoading(false);
      } else {
        dispatch(success(response.data.message));
        onClose();
      }
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCustomField) {
      formik.values.name = selectedCustomField.value;
    }
  }, [selectedCustomField]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="EditCustomFieldModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={crossicon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.SETTINGS.CUSTOM_FIELDS.EDIT.TITLE}
            </Typography>
          </Box>
          <form
            className="updateFormWrapper"
            onSubmit={updateExistingCustomField}
          >
            <Box
              sx={{
                maxWidth: "564px",
                marginInline: "auto",
                textAlign: "start",
                marginTop: "16px",
              }}
            >
              <InputLabel htmlFor="Custom Field" className="inputLabel">
                {MESSAGES.SETTINGS.CUSTOM_FIELDS.EDIT.NAME}
              </InputLabel>
              <TextField
                placeholder="Type to update a Custom field"
                fullWidth
                name="name"
                className={
                  formik.errors.name && formik.touched.name
                    ? "inputField invalid"
                    : "inputField"
                }
                onChange={formik.handleChange}
                value={formik.values.name?.trimStart()}
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                sx={{
                  "& input::placeholder": {
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  },
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              />
              {formik.errors.name ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.name}
                </Typography>
              ) : null}
            </Box>
            <Box className="editNameModalBtns">
              <Button className="btnCancel" onClick={onClose}>
                {MESSAGES.ACCOUNT.CANCEL_BUTTON}
              </Button>
              <Button className="btnSave" type="submit">
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default EditCustomFieldModal;
