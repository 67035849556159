import React from "react";

// Plotno Library Imports
import { observer } from "mobx-react-lite";
import { SectionTab } from "polotno/side-panel";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { failure } from "../../../redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "../../../utils/message";

// MUI Components
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

// Dummy Image for GSV
import { GOOGLE_STREET_VIEW_IMAGE_URL } from "../../../utils/constants";

// Icons
import CustomAddOnIcon from "../../../assets/images/templates/customAddOnIcon.jsx";
import InfoIcon from "../../../assets/images/templates/info-icon.svg";
import GsvIcon from "../../../assets/images/templates/gsv-icon.svg";
import EpoIcon from "../../../assets/images/templates/epo-icon.svg";

import "./styles.scss";

// define the new custom section
export const CustomAddOns = {
  name: "CustomAddOns",
  Tab: (props) => (
    <SectionTab name="Add On's" {...props}>
      <CustomAddOnIcon fill="#000"/>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: observer(({ store }) => {

    const dispatch = useDispatch();

    const handleAddElementOnScreen = (event, value, type) => {
      event.preventDefault();
      const currentPage = store.activePage.toJSON();
      if (
        currentPage?.children?.length &&
        currentPage.children.find((item) => item.id === `gsv-image_${store.activePage.id}`)
      ) {
        dispatch(failure(MESSAGES.TEMPLATE.GSV_RESTRICT_ONE_PER_PAGE));
        return;
      }
      if (type === "gsv") {
        store.activePage?.addElement({
          id: `gsv-image_${store.activePage.id}`,
          type: "image",
          src: GOOGLE_STREET_VIEW_IMAGE_URL,
          width: 287,
          height: 188,
          contentEditable: false,
          keepRatio: true,
          opacity: 1,
          custom: {
            elementType: value,
          },
        });
      } else if (type === "epo") {
        store.activePage.addElement({
          type: "text",
          x: 100,
          y: 100,
          text: value,
          width: 150,
          contentEditable: false,
        });
      }
    };

    return (
      <div className="dynamic-content">
        <div className="dynamic-content__top">
          <div>
            <span className="title">Optional Add-On's</span>{" "}
            <Tooltip title="Google Street View is a technology featured in Google Maps and Google Earth that provides interactive panoramas from positions along many streets in the world.">
              <img src={InfoIcon} className="icon" />
            </Tooltip>
          </div>
        </div>
        <Box
          className="addonBox"
          onClick={(event) =>
            handleAddElementOnScreen(event, "GOOGLE_STREET_VIEW", "gsv")
          }
        >
           <img src={GsvIcon} className="icon" />
          <Typography>Street View Property Image</Typography>
          <Typography>+$0.02 per mail piece</Typography>
        </Box>

        <Box
          className="hidden-important addonBox"
          onClick={(event) =>
            handleAddElementOnScreen(event, "{{EST.PROP.OFFER}}", "epo")
          }
        >
          <img src={EpoIcon} className="icon" />
          <Typography>Estimated Property Offer</Typography>
          <Typography>+$0.02 per mail piece</Typography>
        </Box>
      </div>
    );
  }),
};
