import React, { useState } from "react";

// Utils
import { balanceFormatter } from "../../../utils/helperFunctions";
import {
  INVOICE_HEADERS,
  PRODUCT_PRICING_HEADERS,
} from "../../../utils/constants";
import { dateFormat } from "../../../utils/date-format";
import { MESSAGES } from "../../../utils/message";

// Components
import Loader from "../../General/Loader";

// MUI Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Typography } from "@mui/material";

// Icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Prev from "../../../assets/images/plans/prev.svg";
import Next from "../../../assets/images/plans/next.svg";

// Styles
import "./styles.scss";

const index = (props) => {
  const { tableType, data, callback, loading = false } = props;

  const [prevCount, setPrevCount] = useState(0);

  return (
    <>
      <TableContainer component={Paper} className="plansTableWrapper">
        <Table aria-label="simple table">
          <TableHead>
            {tableType === "product" ? (
              <TableRow>
                {PRODUCT_PRICING_HEADERS.map((header, index) => (
                  <TableCell align="left" key={index}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              <TableRow>
                {INVOICE_HEADERS.map((header, index) => (
                  <TableCell align="left" key={index}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {tableType === "product"
              ? data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">
                      {balanceFormatter(row.starter)}
                    </TableCell>
                    <TableCell align="left">
                      {balanceFormatter(row.growth)}
                    </TableCell>
                    <TableCell align="left">
                      {balanceFormatter(row.professional)}
                    </TableCell>
                    <TableCell align="left">{row.enterprise}</TableCell>
                  </TableRow>
                ))
              : !loading &&
                data.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {dateFormat(row.date, true)}
                    </TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="left">
                      {balanceFormatter(row.total)}
                    </TableCell>
                    <TableCell align="left">
                      <Box className="invoiceStatus">
                        {row.status}
                        <ArrowForwardIosIcon
                          onClick={() =>
                            window.open(row.hosted_invoice_url, "_blank")
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {tableType === "invoice" && !data.rows.length && !loading && (
          <div className="noData">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.INVOICE.NO_RESULT_TEXT}
            </Typography>
          </div>
        )}

        {tableType === "invoice" && loading && (
          <div className="noData">
            <Loader />
          </div>
        )}
      </TableContainer>
      {tableType === "invoice" && (
        <Box className="btnouterWrap">
          <Button
            className="btn prev"
            disabled={prevCount > 0 ? false : true}
            onClick={() => {
              callback({ endingBefore: data.endingBefore });
              setPrevCount((prevCount) => prevCount - 1);
            }}
          >
            <img src={Prev} alt="icon" />
          </Button>
          <Button
            className="btn next"
            disabled={(!data.has_more && prevCount !== 0) || !data.rows.length}
            onClick={() => {
              callback({ startingAfter: data.startingAfter });
              setPrevCount((prevCount) => prevCount + 1);
            }}
          >
            <img src={Next} alt="icon" />
          </Button>
        </Box>
      )}
    </>
  );
};

export default index;
