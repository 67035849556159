//Actions
import {
    SET_ADMIN_ORDERS_DATA, SET_ADMIN_ORDERS_FILTERS_LABELS, SET_ADMIN_ORDERS_FILTERS, SET_ADMIN_ORDERS_LOADER, SET_ADMIN_ORDER_DISPATCH_LOADER, CLEAR_ADMIN_ORDERS_FILTERS, LOGOUT
} from "../actions/action-types"

const initialState = {
    orders: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalBatchesInDB: null,
    filterslabels: [],
    loading: false,
    dispatchLoadingOrderId: null,
    applyFilters: false,
    filterSize: null,
    totalRevenue: 0,
    totalOrders: 0,
    filters: {}
};

const adminOrdersReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ADMIN_ORDERS_DATA:
            return {
                ...state, orders: payload.rows, rowsCount: payload.count, totalBatchesInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage,
                totalRevenue: payload.totalRevenue, totalOrders: payload.totalOrders
            };
        case SET_ADMIN_ORDERS_LOADER:
            return { ...state, loading: payload };
        case SET_ADMIN_ORDER_DISPATCH_LOADER:
            return { ...state, dispatchLoadingOrderId: payload, };
        case SET_ADMIN_ORDERS_FILTERS_LABELS:
            return { ...state, filterslabels: payload };
        case SET_ADMIN_ORDERS_FILTERS:
            return { ...state, applyFilters: payload.applyFilters, filterSize: payload.filterSize, filters: payload.filters, };
        case CLEAR_ADMIN_ORDERS_FILTERS:
            return { ...state, applyFilters: false, filterSize: null, filters: {} };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { adminOrdersReducers };